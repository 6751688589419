import { useMemo } from "react";
import { connect } from "react-redux";
import { removeGroupFromCourse } from "../../../actions/manageCoursesActions";
import removeIcon from "../../../assets/box/close_dark.png";
import Table from "../../../components/Table";

const GroupsByCourseTable = ({ groups, removeGroupFromCourse }) => {
  const handleRemoveGroup = async (group) => {
    removeGroupFromCourse({group});
  };
  const columns = useMemo(
      () => [
        {
          accessorKey: 'name',
          header: 'Name',
          Header: () => <div className="table-title">GROUP NAME</div>, 
          filterVariant: 'text',
          grow: true,
          size: 400,
        },
        {
          header: 'Actions',
          Header: () => <div className="table-title">ACTIONS</div>, 
          accessorFn: (row) => <img src={removeIcon} alt="Delete"  className="remove_icon" onClick={() => handleRemoveGroup(row)}/>,
          enableColumnFilter: false,
          enableSorting: false,
          grow: false,
          size: 50,
        },
      ],
      [],
  );

  return <Table data={groups} height="35vh" columns={columns}/>;
};

const mapDispatchToProps = (dispatch) => {
  return {
      removeGroupFromCourse: (params) => {
          dispatch(removeGroupFromCourse(params));
      }
  };
};

export default connect(null, mapDispatchToProps)(GroupsByCourseTable);