import moment from 'moment';
import { Link } from 'react-router-dom';
import styles from '../../index.module.css';

const TableCard = (props) => {
    const {
        color,
        data
    } = props;
    const activation = moment(data.activationDate).format('MMM Do YY');
    const lastAcdessed = moment(data.lastLogging).format('MMM Do YY');
    return (
        <div className={`row ${styles.row} ${color}`}>
            <div className={`content-box`}>
                <Link to={`Details/${data.idDevice}`} className='li-t'>{data.name}</Link>
            </div>
            <div className={`content-box`}>{data.platform.name}</div>
            <div className={`content-box`}>{data.hardwareId}</div>
            <div className={`content-box`}>{activation}</div>
            <div className={`content-box`}>{lastAcdessed}</div>
        </div>
    );
};

export default TableCard;