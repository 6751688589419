import {
    COUNT_COURSES,
    DELETE_COURSE,
    FILTER_COURSES,
    GET_COURSES,
    GET_COURSE,
    CLEAR_COURSE_INFO,
    REMOVE_GROUP_FROM_COURSE,
    ADD_GROUPS_TO_COURSE
} from "../actions/manageCoursesActions";

var initialState = {
    coursesList: [],
    coursesListCopy: [],
    coursesSelected: [],
    courseDeleted: [],
    courseData: {},
    groups: [],
    groupsCopy: [],
    groupsDeleted: [],
    counterCourses: 0,
};

const manageCoursesReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_COURSE):
            return state = {
                ...state,
                courseData: action.payload,
                groups: action.payload.groups,
                groupsCopy: action.payload.groupsCopy,
            };
        case (GET_COURSES):
            return state = {
                ...state,
                coursesList: action.payload,
                coursesListCopy: action.payload
            };
        case (DELETE_COURSE):
            return state = {
                ...state,
                courseDeleted: [...state.courseDeleted, action.payload]
            };
        case (FILTER_COURSES):
            return state = {
                ...state,
                coursesList: state.coursesListCopy.filter(course => {
                    return course.name.toLowerCase().includes(action.payload.toLowerCase())
                })
            };
        case (COUNT_COURSES):
            return state = {
                ...state,
                counterCourses: action.payload
            };
        case (REMOVE_GROUP_FROM_COURSE):
            return state = {
                ...state,
                groupsDeleted: [...state.groupsDeleted, action.payload],
                groups: state.groups.filter(group => group.idGroup !== action.payload.idGroup)
            };
        case (ADD_GROUPS_TO_COURSE):
            return state = {
                ...state,
                groups: [...state.groups, ...action.payload]
            };
        case (CLEAR_COURSE_INFO):
            return state = {
                ...state,
                groups: initialState.groups,
                courseData: initialState.courseData
            };
        default: return state;
    }
};

export default manageCoursesReducer;