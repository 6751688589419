import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { filterDevice, getDevice } from "../../../actions/devicesActions";
import devicesIcon from "../../../assets/box/Devices_Blue@2x-8.png";
import arrow from "../../../assets/icons/ArrowBlue@2x-8.png";
import DetailsTable from "./details_table/details_table";
import styles from "./devices_details.module.css";

const DevicesDetails = (props) => {
    const {
        getDevice,
        data,
        filterDevice
    } = props;
    const { idDevice } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        getDevice(idDevice);
    }, [idDevice])
    const handleSearch = (e) => {
        filterDevice(e.target.value);
    };
    return (
        <div className={`box-container-opaque`}>
            <div className={`entryPage box-container box-entry ${styles.container} `}>
                <div className={styles.firstBox}>
                    <img src={arrow} alt="back" onClick={() => navigate('/Devices')} className={styles.arrow}/>
                    <div>
                        <img src={devicesIcon} alt="Devices Icon" className={styles.icon}/>
                    </div>
                    <div className={styles.textHeaderCon}>
                        <div className={styles.title}>{data.name}</div>
                        <div className={styles.subtitle}>{data.platform.name}</div>
                        <div className={styles.subtitle2}>{data.hardwareId}</div>
                    </div>
                    <div className={styles.searchCon}>
                        <input type="text" className={`in-FLD input-search-clear`} placeholder="Search" onChange={handleSearch}/>
                    </div>
                </div>
                <DetailsTable data={data} getDevice={() => getDevice(idDevice)}/>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        data: state.devicesReducer.device
    };
};

export default connect(mapStateToProps, { getDevice, filterDevice })(DevicesDetails);