import Axios from "../config/Axios";

export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const SELECT_GROUP = 'SELECT_GROUP';
export const UN_SELECT_GROUP = 'UN_SELECT_GROUP';
export const GET_USERS_BY_GROUP = 'GET_USERS_BY_GROUP';
export const FORGET_USERS = 'FORGET_USERS';
export const REMOVE_USERS_FROM_GROUP = 'REMOVE_USERS_FROM_GROUP';
export const GET_GROUP = 'GET_GROUP';
export const SET_GROUP_DATA = 'SET_GROUP_DATA';
export const ADD_USERS_TO_GROUP = 'ADD_USERS_TO_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_PRODUCT_GROUP = 'DELETE_PRODUCT_GROUP';
export const FILTER_GROUPS = 'FILTER_GROUPS';
export const FILTER_USERS_BY_GROUP = 'FILTER_USERS_BY_GROUP';
export const COUNT_GROUPS = 'COUNT_GROUPS';
export const CLEAR_GROUPS_SELECTED = 'CLEAR_GROUPS_SELECTED';
export const GET_GROUPS = 'GET_GROUPS';

const { REACT_APP_API_URL } = process.env;

const getAllGroups = ({ order, column, atribute }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageGroups/groupDetails`, {
            params: {
                order,
                column,
                atribute
            }
        })
            .then(response => {
                dispatch({
                    type: GET_ALL_GROUPS,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const getGroups = (arg) => {
    const {
        productIdProduct
    } = arg;
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageGroups/getGroups`, { params: { productIdProduct } })
            .then(response => {
                dispatch({
                    type: GET_GROUPS,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const addGroup = (data) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/manageGroups/addGroup`, data)
            .then(response => {
                dispatch({
                    type: ADD_GROUP,
                    payload: response.data
                });
                return response.data;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const selectGroup = (idGroup) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_GROUP,
            payload: idGroup
        });
    };
};

const unSelectGroup = (idGroup) => {
    return (dispatch) => {
        dispatch({
            type: UN_SELECT_GROUP,
            payload: idGroup
        });
    };
};

const clearGroupsSelected = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_GROUPS_SELECTED,
            payload: []
        });
    };
};

const deleteGroup = (idGroup) => {
    return (dispatch) => {
        const promises = [];
        if (!Array.isArray(idGroup)) idGroup = [idGroup];
        idGroup.forEach(groupId => {
            promises.push(Axios.delete(`${REACT_APP_API_URL}/manageGroups/deleteGroup/${groupId}`));
        });
        return Promise.all(promises)
            .then(response => {
                dispatch({
                    type: DELETE_GROUP,
                    payload: idGroup
                });
                return true;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
}; 

const getUsersByGroup = (idGroup, { order, column, atribute }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageGroups/usersGroup/${idGroup}`, {
            params: {
                order,
                column,
                atribute
            }
        })
            .then(response => {
                dispatch({
                    type: GET_USERS_BY_GROUP,
                    payload: response.data.response
                });
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const forgetUsers = () => {
    return (dispatch) => {  
        dispatch({
            type: FORGET_USERS,
            payload: []
        });
    };
};

const removeUserFromGroup = ({idGroup, user}) => {
    return (dispatch) => {
        if (!idGroup) {
            dispatch({
                type: REMOVE_USERS_FROM_GROUP,
                payload: user
            });
        } else {
            return Axios.put(`${REACT_APP_API_URL}/manageGroups/deleteUserGroup`, {idGroup, idUser: user.idUser})
                .then(response => {
                    dispatch({
                        type: REMOVE_USERS_FROM_GROUP,
                        payload: user
                    });
                    return true;
                })
                .catch(error => {
                    throw new Error(error.response.data.message);
                });
        };
    };
};

const getGroup = (idGroup) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageGroups/getGroup/${idGroup}`)
            .then(response => {
                dispatch({
                    type: GET_GROUP,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const setGroupData = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_GROUP_DATA,
            payload: data
        });
    };
};

const addUsersToGroup = ({idGroup, users}) => {
    return (dispatch) => {
        if (!idGroup) {
            dispatch({
                type: ADD_USERS_TO_GROUP,
                payload: users
            });
        } else {
            let promises = [];
            users.forEach(user => {
                promises.push(Axios.post(`${REACT_APP_API_URL}/manageGroups/addUserGroup`, {idGroup, idUser: user.idUser}));
            });
            return Promise.all(promises)
                .then(response => {
                    // const users = response.map(res => res.data.response);
                    // para utilizar el response de la api es necesario añadir el role y evitar informacion sensible
                    dispatch({
                        type: ADD_USERS_TO_GROUP,
                        payload: users
                    });
                    return true;
                })
                .catch(error => {
                    throw new Error(error.response.data.message);
                });
        }
    };
};

const updateGroup = (data) => {
    return (dispatch) => {
        return Axios.put(`${REACT_APP_API_URL}/manageGroups/updateGroup`, {data})
            .then(response => {
                dispatch({
                    type: UPDATE_GROUP,
                    payload: response.data
                });
                return true;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};



const filterGroups = (str) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_GROUPS,
            payload: str
        });
    };
};

const filterUsersByGroup = (str) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_USERS_BY_GROUP,
            payload: str
        });
    };
};

const countGroups = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageGroups/countGroups`)
            .then(response => {
                dispatch({
                    type: COUNT_GROUPS,
                    payload: response.data.data
                });
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};


export {
    getAllGroups,
    selectGroup,
    unSelectGroup,
    clearGroupsSelected,
    deleteGroup,
    getUsersByGroup,
    forgetUsers,
    removeUserFromGroup,
    getGroup,
    setGroupData,
    addUsersToGroup,
    updateGroup,
    addGroup,
    filterGroups,
    filterUsersByGroup,
    countGroups,
    getGroups
}