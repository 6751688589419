import { useState } from "react";
import ModalPage from "../modalPage-base/ModalPage";
import './InputTextModal.css';


const InputTextModal = (props) => {
    const {
        placeholder,
        onSave,
        closeModal,
        isOpen
    } = props;
    const [state, setState] = useState({inputText: ''})
    const handleChangeInput = (e) => { setState({...state, inputText: e.target.value}) };
    const handleCancel = (e) => {
        setState({...state, inputText: ''})
        closeModal()
    };
    return (
        <ModalPage isOpen={isOpen}>
            <div className="confirm-modal-C">
                <div className="inputConTAM">
                    <input className="inputTextModal" type="text" value={state.inputText} onChange={handleChangeInput} placeholder={placeholder} />
                </div>
                <div className="btn-confirmC">
                    <button className="btn-modalPages rigth" onClick={() => onSave({inputText: state.inputText})}>SAVE</button>
                    <button className="btn-modalPages left" onClick={handleCancel}>CANCEL</button>
                </div>
            </div>
        </ModalPage>
    );
};

export default InputTextModal;