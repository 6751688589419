import { useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import Table from "../../../components/Table";

const DetailsTable = (props) => {
  const { reportUser } = props;

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: () => <div className="table-title">ATTEMPT DATE</div>,
        accessorFn: (row) =>
          row.attemptDate
            ? moment(row.attemptDate).format("MMM DD, YYYY HH:mma")
            : "",
        header: "Name",
        sortingFn: (rowA, rowB) => {
          // Obtener las fechas originales de cada fila
          const dateA = rowA.original.attemptDate
            ? new Date(rowA.original.attemptDate)
            : new Date(0);
          const dateB = rowB.original.attemptDate
            ? new Date(rowB.original.attemptDate)
            : new Date(0);
          // Comparar las fechas
          return dateA - dateB;
        },
        enableColumnFilter: false,
      },
      {
        header: "module",
        Header: () => <div className="table-title">MODULE</div>,
        accessorKey: "procedure",
        enableColumnFilter: false,
      },
      {
        id: "mode",
        header: "Mode",
        Header: () => <div className="table-title">MODE</div>,
        accessorFn: (row) => (
          <Link className="li-t-clear" to={`./${row.idSession}`}>
            {row.nameModule}
          </Link>
        ),
        enableColumnFilter: false,
      },
      {
        id: "timeSpend",
        header: "Time Spend",
        Header: () => <div className="table-title">TIME SPENT (MM:SS)</div>,
        accessorFn: (row) =>
          row.duration ? moment(row.duration * 1000).format("m:ss") : "00:00",
        enableColumnFilter: false,
      },
      {
        header: "results",
        Header: () => <div className="table-title">RESULTS</div>,
        accessorKey: "score",
        enableColumnFilter: false,
      },
    ],
    []
  );

  return (
    <Table
      data={reportUser}
      height="54vh"
      columns={columns}
      textColor="#409ce4"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    reportUser: state.metricIndividualReducer.reportUser.reportUser,
  };
};

export default connect(mapStateToProps, null)(DetailsTable);
