import { Outlet, useNavigate } from "react-router-dom";
import BoardHeader from "../components/BoardHeader/BoardHeader";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import AlertModal from "../components/AlertModal/AlertModal.jsx";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import { generateToken, setTimeSessionToken } from "../actions/manageSessionsAction";
import Loading from "../components/loadingModal/LoadingModal";
import "./Dashboard.css";
import BoardHeaderMobile from "../components/BoardHeaderMobile/BoardHeaderMobile";
import { isAuth } from "../actions/usersActions.js";
import io from 'socket.io-client';

const { REACT_APP_API_URL } = process.env;

const Dashboard = (props) => {
    const {
        generateToken,
        setTime,
    } = props;
    const [timeLeft, setTimeLeft] = useState(0);
    const navigate = useNavigate();
    const mediaQuery = window.matchMedia('(max-width: 427px)');
    const jwt = localStorage.getItem('token');

    
    const refreshToken = async () => {
        try {
            await generateToken();
            setTimeLeft(300);
        } catch (error) {
            console.log(error);
        };
    };
    useEffect(() => {
        const socket = io(REACT_APP_API_URL, {
            transportOptions: {
                polling: {
                    extraHeaders: {
                        authorization: `Bearer ${jwt}`
                    }
                }
            }
        });
        socket.emit('web:connection');
        socket.on('web:connection', (data) => {
          localStorage.removeItem('sessionExpired');
        });
        // desconection event
        socket.on('web:sessionexpired', (data) => {
            // remove token from local storage
            localStorage.removeItem('token');
            // add item to local storage to know that the session expired
            localStorage.setItem('sessionExpired', 'true');
            navigate('login');
        });
        socket.on('web:tokenSessiUsed', (data) => {
            refreshToken();
        });
        return () => {
            socket.off('web:connection');
            socket.disconnect();
        };
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
            setTime(timeLeft);
            if (timeLeft <= 0) {
                refreshToken();
            };
        }, 1000);
        return () => clearInterval(interval);
    }, [setTime, timeLeft]);

    return (
        <div className="general-content">
            <Loading/>
            <ConfirmModal />
            <AlertModal />
            <div className="H-and-middle">
                <div className="B-H">
                    {
                        !mediaQuery.matches ? <BoardHeader /> : <BoardHeaderMobile />
                    }
                </div>
                <div className="structure-center">
                    {
                        !mediaQuery.matches ? 
                        <div className="navbar-c">
                            <Navbar />
                        </div>
                        : null
                    }
                    <div className="dash-content">
                        <Outlet />
                    </div>
                </div>
            </div>
            {
                !mediaQuery.matches ?
                    <div className="con-foo">
                        <Footer classN="fooInDash" />
                    </div>
                    :
                    null
            }
        </div>
    );
};



const mapDispatchToProps = (dispatch) => {
    return {
        generateToken: () => dispatch(generateToken()),
        setTime: (time) => dispatch(setTimeSessionToken(time)),
    };
};

const mapStateToProps = (state) => {
    return {
        token: state.manageSessionReducer.tokenSessions.Token
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);