import { Outlet, useNavigate } from "react-router-dom";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import styles from "./index.module.css"
import { connect } from "react-redux";
import { getLoginDeviceLifetime } from "../../actions/clientAction";
import { useEffect } from "react";
import { closeLoading, openLoading } from "../../components/loadingModal/loading";
import alert from "../../components/AlertModal/alertModal";
import licensesImg from "../../assets/icons/settings_dark.png";


const Template = (props) => {
    const { time, getLoginDeviceLifetime } = props;
    const navigate = useNavigate();
    const getInitialData = async () => {
        openLoading();
        try {
            await getLoginDeviceLifetime();
        } catch (error) {
            alert('ERROR', error.message);
        } finally {
            closeLoading();
        };
    };
    useEffect(() => {
        getInitialData();
    }, []);
    const hour = Math.floor(time / 3600);
    const minute = Math.floor((time % 3600) / 60);
    return (
        <div className={`box-container ${styles.container}`}>
            <ArrowGoBack/>
            <div className={styles.infoContainer}>
                <img src={licensesImg} alt="Licenses logo" className={styles.logo}/>
            </div>
            <div className={styles.content}>
                <div className="card" onClick={() => navigate('login-lifetime')}>
                    <div className="header">LOGIN DURATION ON DEVICE</div>
                    <div className="content">
                        <span>Duration: {hour} hours {minute ? `${minute} minutes` : ''}</span>
                        <span>Default Duration: 3 hours</span>
                    </div>
                </div>
            </div>
            <Outlet/>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        time: state.client.deviceLoginDuration
    };
};

export default connect(mapStateToProps, { getLoginDeviceLifetime })(Template);