import {
    GET_PRODUCT_METRIC, GET_REPORT_USER, GET_ASSESSMENT, FILTER_METRIC_USER, SORT_REPORT_BY_USERS, SORT_REPORT_USER, GET_METRIC_INITIAL_DATA, GET_REPORTS_BY_GROUP_PRODUCT
} from "../actions/reportsActions";

var initialState = {
    reports: [],
    reportsCopy: [],
    reportsInfo: { totalRate: 0 },
    productMetric: [],
    reportUser: { reportUser: [], user: {} },
    assessment: [],
    metricInitialData: [],
    groupsByProduct: []
};

const metricIndividualReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_REPORTS_BY_GROUP_PRODUCT):
            return state = {
                ...state,
                reports: action.payload.reports,
                reportsCopy: action.payload.reports,
                reportsInfo: action.payload.generalInfo
            };
        case (GET_PRODUCT_METRIC):
            return state = {
                ...state,
                productMetric: action.payload
            };
        case (GET_REPORT_USER):
            return state = {
                ...state,
                reportUser: action.payload
            };
        case (GET_ASSESSMENT):
            return state = {
                ...state,
                assessment: action.payload
            };
        case (FILTER_METRIC_USER):
            return state = {
                ...state,
                reports: state.reportsCopy.filter(user => {
                    return user.firstname.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.lastname.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.username.toLowerCase().includes(action.payload.toLowerCase())
                })
            };
        case (SORT_REPORT_BY_USERS):
            let array = [];
            if (action.payload.field === "progress" || action.payload.field === "lastAccess") {
                array = state.reports.sort((a, b) => {
                    if (a.modulesCompleted < b.modulesCompleted) return -1;
                    if (a.modulesCompleted > b.modulesCompleted) return 1;
                    return 0;
                });
            } else {
                array = state.reports.sort((a, b) => {
                    if (a[action.payload.field].toLowerCase() < b[action.payload.field].toLowerCase()) return -1;
                    if (a[action.payload.field].toLowerCase() > b[action.payload.field].toLowerCase()) return 1;
                    return 0;
                });
            };
            if (action.payload.order === "desc") array.reverse();
            return state = {
                ...state,
                reports: array
            };
        case (SORT_REPORT_USER):
            let array2 = [];
            if (action.payload.field === "duration" ) {
                array2 = state.reportUser.reportUser.sort((a, b) => {
                    if (a[action.payload.field] < b[action.payload.field]) return -1;
                    if (a[action.payload.field] > b[action.payload.field]) return 1;
                    return 0;
                });
            } else {
                array2 = state.reportUser.reportUser.sort((a, b) => {
                    if (a[action.payload.field].toLowerCase() < b[action.payload.field].toLowerCase()) return -1;
                    if (a[action.payload.field].toLowerCase() > b[action.payload.field].toLowerCase()) return 1;
                    return 0;
                });
            }
            if (action.payload.order === "desc") array2.reverse();
            return state = {
                ...state,
                reportUser: {
                    ...state.reportUser,
                    reportUser: array2
                }
            };
        case (GET_METRIC_INITIAL_DATA):
            return state = {
                ...state,
                metricInitialData: action.payload
            };
        default: return state;
    }

}

export default metricIndividualReducer;