import RolesTable from "./roles_table/roles_table";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./roles_permissions.css";
import ArrowGoBack from "../../../components/ArrowGoBack/ArrowGoBack";

const RolesAndPermissions = ({ setPath }) => {
    return (
        <div className="TUcontainer box-container">
            <ArrowGoBack />
            <div className="btn-cont">
                <Link to="../create-role">
                    <button className="bas-button" >Create Role</button>
                </Link>
            </div>
            <div className="btn-table-role">
                <RolesTable />
            </div>
        </div>
    );
};

export default connect(null, null)(RolesAndPermissions);