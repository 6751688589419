import moment from "moment";
import styles from "../index.module.css";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

const BoxLicenses = (props) => {
  const { licenses } = props;
  const mediaQuery = window.matchMedia("(max-width: 427px)");
  const navigate = useNavigate();
  return (
    <div className={styles.boxContainer} onClick={() => navigate("/licenses")}>
      <span className={styles.titleBox}>LICENSES</span>
      <div
        className={`${!mediaQuery ? styles.contentBox : styles.contentBoxTwoL}`}
      >
        <div className={styles.numberBiggest}>{licenses}</div>
        <div className={styles.subTitle3}>ACTIVE</div>
      </div>
    </div>
  );
};

const BoxSessions = (props) => {
  const { sessions } = props;
  const navigate = useNavigate();
  return (
    <div
      className={styles.boxContainer}
      onClick={() => navigate("/manage-sessions")}
    >
      <span className={styles.titleBox}>SESSIONS</span>
      <div className={styles.contentBox}>
        <div className={styles.sessionCon}>
          <div className={styles.deviceBox}>
            <div className={styles.subTitle}>ONGOING</div>
            <div className={styles.number}>{sessions.ongoing ?? 0}</div>
          </div>
          <div className={styles.deviceBox}>
            <div className={styles.subTitle4}>SCHEDULED</div>
            <div className={styles.number4}>{sessions.scheduled ?? 0}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BoxGroups = (props) => {
  const { groups } = props;
  const mediaQuery = window.matchMedia("(max-width: 427px)");
  const navigate = useNavigate();
  return (
    <div
      className={styles.boxContainer}
      onClick={() => navigate("/manage-groups")}
    >
      <span className={styles.titleBox}>GROUPS</span>
      {groups ? (
        <div
          className={`${
            !mediaQuery ? styles.contentBox : styles.contentBoxTwo
          }`}
        >
          <div className={styles.numberBig}>{groups}</div>
          <div className={styles.subTitle3}>AVAILABLE</div>
        </div>
      ) : (
        <div
          className={`${
            !mediaQuery ? styles.contentBox : styles.contentBoxTwo
          }`}
        >
          <div className={styles.subTitle5}>NO GROUPS CREATED</div>
        </div>
      )}
    </div>
  );
};

const BoxCourses = (props) => {
  const { courses } = props;
  const mediaQuery = window.matchMedia("(max-width: 427px)");
  const navigate = useNavigate();
  return (
    <div
      className={styles.boxContainer}
      onClick={() => navigate("/manage-courses")}
    >
      <span className={styles.titleBox}>COURSES</span>
      {courses ? (
        <div
          className={`${
            !mediaQuery ? styles.contentBox : styles.contentBoxTwo
          }`}
        >
          <div className={styles.numberBig}>{courses}</div>
          <div className={styles.subTitle3}>AVAILABLE</div>
        </div>
      ) : (
        <div
          className={`${
            !mediaQuery ? styles.contentBox : styles.contentBoxTwo
          }`}
        >
          <div className={styles.subTitle5}>NO COURSES CREATED</div>
        </div>
      )}
    </div>
  );
};

const BoxUsers = (props) => {
  const { totalUsers, inactiveUsers, activeUsers } = props;
  const navigate = useNavigate();
  return (
    <div
      className={styles.boxContainer}
      onClick={() => navigate("/manage-users")}
    >
      <span className={styles.titleBox}>USERS</span>
      <div className={styles.contentBox}>
        <div className={styles.sessionCon}>
          <div className={styles.deviceBox}>
            <div className={styles.subTitle}>TOTAL</div>
            <div className={styles.number}>{totalUsers}</div>
          </div>
          <div className={styles.deviceBox}>
            <div className={styles.subTitle4}>INACTIVE</div>
            <div className={styles.number4}>{inactiveUsers}</div>
          </div>
          <div className={styles.deviceBox}>
            <div className={styles.subTitle4}>ACTIVE</div>
            <div className={styles.number4}>{activeUsers}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BoxReports = (props) => {
  const { reports = [] } = props;
  const navigate = useNavigate();
  const fiveReports = reports.slice(0, 5);
  const data = {
    labels: fiveReports.map((report) => report.productName),
    datasets: [
      {
        label: "Reports",
        data: fiveReports.map((report) => report.seconds / 60),
        backgroundColor: [
          "rgb(64,156,228)",
          "rgb(240,116,36)",
          "rgb(64,156,228)",
          "rgb(240,116,36)",
          "rgb(64,156,228)",
        ],
        barPercentage: 0.7,
        // hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value + " min";
          },
          font: {
            size: 9,
            family: "ProximaNova-Regular",
            weight: "bold",
          },
        },
      },
    },
  };
  return (
    <div className={styles.boxContainer} onClick={() => navigate("/reports")}>
      <span className={styles.titleBox}>REPORTS</span>
      <div className={styles.contentBox}>
        {reports.length === 0 ? (
          <div className={styles.subTitle5}>NO REPORTS AVAILABLE</div>
        ) : (
          <div className={styles.reportTable}>
            <Bar data={data} options={options} />
          </div>
        )}
      </div>
    </div>
  );
};

const BoxAssets = (props) => {
  const { storage, mediaQuery, smally, has_storage } = props;
  const navigate = useNavigate();
  const unit = storage.total > 1020 * 1020 * 1020 ? "GB" : "MB";
  const used = (
    storage.used / (unit === "GB" ? 1020 * 1020 * 1020 : 1020 * 1020)
  ).toFixed(1);
  const free = (
    storage.free / (unit === "GB" ? 1020 * 1020 * 1020 : 1020 * 1020)
  ).toFixed(1);
  const total = (
    storage.total / (unit === "GB" ? 1020 * 1020 * 1020 : 1020 * 1020)
  ).toFixed(1);
  const data = {
    labels: ["Free", "Used"],
    datasets: [
      {
        label: "Storage",
        data: [free, used],
        backgroundColor: ["rgb(64,156,228)", "rgb(240,116,36)"],
        // hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        // align: 'center',
        labels: {
          boxWidth: 25,
          boxHeight: 25,
          padding: 20,
          font: {
            size: 18,
            family: "ProximaNova-Regular",
            weight: "bold",
          },
        },
      },
    },
  };
  const optionsMobile = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        // align: 'center',
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          padding: 20,
          font: {
            size: 10,
            family: "ProximaNova-Regular",
            weight: "bold",
          },
        },
      },
    },
  };
  return (
    <div
      className={`${styles.boxContainer} ${!has_storage && styles.disabled}`}
      onClick={() => {
        has_storage && navigate("/Assets");
      }}
    >
      <span className={styles.titleBox}>ASSETS</span>
      <div className={styles.contentBox}>
        {has_storage ? (
          <div className={styles.pieContainer}>
            <div className={`${styles.pie} ${smally && styles.smally}`}>
              <Pie data={data} options={mediaQuery ? optionsMobile : options} />
            </div>
            <div
              className={styles.textStorage}
            >{`STORAGE    ${used} ${unit} / ${total} ${unit}`}</div>
          </div>
        ) : (
          <div className={styles.subTitle5}>ASSETS STORAGE NOT AVAILABLE</div>
        )}
      </div>
    </div>
  );
};

const BoxToken = (props) => {
  const { token, timer, noProducts } = props;

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className={styles.boxContainer}>
      <span className={styles.titleBox}>TOKEN</span>
      <div className={styles.contentBox}>
        {noProducts ? (
          <div className={styles.noTokenContainer}>
            <div className={styles.noToken}>
              You do not have access to any VR Experiences
            </div>
          </div>
        ) : (
          <div className={styles.tokenContainer}>
            <div className={styles.loopContainer}>
              <div className={styles.back} />
              <div className={styles.counter}>{formatTime(timer)}</div>
            </div>
            <div className={styles.token}>{token}</div>
          </div>
        )}

        {!noProducts && (
          <div className={styles.textContainer}>
            <div className={styles.subTitle2}>USE THIS CODE TO ACCESS</div>
            <div className={styles.subTitle2}>YOUR VR EXPERIENCE</div>
          </div>
        )}
      </div>
    </div>
  );
};

const BoxSessions2 = (props) => {
  const { sessions } = props;
  const navigate = useNavigate();
  const firstDate = sessions[0] ? moment(sessions[0].dateSession) : null;
  const secondDate = sessions[1] ? moment(sessions[1].dateSession) : null;
  const thirdDate = sessions[2] ? moment(sessions[2].dateSession) : null;
  const firstSession = sessions[0];
  const secondSession = sessions[1];
  const thirdSession = sessions[2];
  return (
    <div
      className={styles.boxContainer}
      onClick={() => navigate("/manage-sessions")}
    >
      <span className={styles.titleBox}>SESSIONS</span>
      <div className={styles.contentBox}>
        {sessions.length === 0 ? (
          <div className={styles.subTitle5}>NO SESSIONS SCHEDULED</div>
        ) : (
          <div className={styles.column}>
            <div className={styles.dateOut}>
              {firstDate?.format("MMM yyyy")}
            </div>
            {firstSession && (
              <div className={styles.row}>
                <div className={styles.chartContainer}>
                  <div className={styles.chartDate}>
                    <div className={styles.dayDate}>
                      {firstDate.format("ddd").toUpperCase()}
                    </div>
                    <div className={styles.numberDate}>
                      {firstDate.format("DD")}
                    </div>
                  </div>
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.text1}>
                      {firstSession && firstSession.title}
                    </div>
                    <div className={styles.text2}>
                      {firstSession && firstSession.product.name}
                    </div>
                  </div>
                </div>
                <div className={styles.hourDate}>
                  <div>
                    <div className={styles.hourDate}>
                      {firstDate.format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.dateOut}>
              {secondDate?.format("MMM yyyy") !==
                firstDate?.format("MMM yyyy") && secondDate?.format("MMM yyyy")}
            </div>
            {secondSession && (
              <div className={styles.row2}>
                <div className={styles.chartContainer}>
                  <div className={styles.chartDate}>
                    <div className={styles.dayDate}>
                      {secondDate.format("ddd").toUpperCase()}
                    </div>
                    <div className={styles.numberDate}>
                      {secondDate.format("DD")}
                    </div>
                  </div>
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.text1}>
                      {secondSession && secondSession.title}
                    </div>
                    <div className={styles.text2}>
                      {secondSession && secondSession.product.name}
                    </div>
                  </div>
                </div>
                <div className={styles.hourDate}>
                  <div>
                    <div className={styles.hourDate}>
                      {secondDate.format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.dateOut}>
              {thirdDate?.format("MMM yyyy") !==
                secondDate?.format("MMM yyyy") && thirdDate?.format("MMM yyyy")}
            </div>
            {thirdSession && (
              <div className={styles.row}>
                <div className={styles.chartContainer}>
                  <div className={styles.chartDate}>
                    <div className={styles.dayDate}>
                      {thirdDate.format("ddd").toUpperCase()}
                    </div>
                    <div className={styles.numberDate}>
                      {thirdDate.format("DD")}
                    </div>
                  </div>
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.titleContainer}>
                    <div className={styles.text1}>
                      {thirdSession && thirdSession.title}
                    </div>
                    <div className={styles.text2}>
                      {thirdSession && thirdSession.product.name}
                    </div>
                  </div>
                </div>
                <div className={styles.hourDate}>
                  <div>
                    <div className={styles.hourDate}>
                      {thirdDate.format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const BoxSessionsMobile = (props) => {
  const { sessions } = props;
  const navigate = useNavigate();
  const firstDate = sessions[0] ? moment(sessions[0].dateSession) : null;
  const secondDate = sessions[1] ? moment(sessions[1].dateSession) : null;
  const thirdDate = sessions[2] ? moment(sessions[2].dateSession) : null;
  const firstSession = sessions[0];
  const secondSession = sessions[1];
  const thirdSession = sessions[2];
  return (
    <div
      className={styles.boxContainer2}
      onClick={() => navigate("/manage-sessions")}
    >
      <span className={styles.titleBox}>SESSIONS</span>
      <div className={styles.contentBox}>
        {sessions.length === 0 ? (
          <div className={styles.subTitle5}>NO SESSIONS SCHEDULED</div>
        ) : (
          <div className={styles.column2}>
            {firstSession && (
              <div className={styles.row}>
                <div className={styles.dateOut}>
                  {firstDate?.format("MMM yyyy")}
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.chartDate}>
                    <div className={styles.dayDate}>
                      {firstDate.format("ddd").toUpperCase()}
                    </div>
                    <div className={styles.numberDate}>
                      {firstDate.format("DD")}
                    </div>
                  </div>
                </div>
                <div className={styles.chartContainer2}>
                  <div className={styles.titleContainer}>
                    <div className={styles.text1}>
                      {firstSession && firstSession.title}
                    </div>
                    <div className={styles.text2}>
                      {firstSession && firstSession.product.name}
                    </div>
                    <div className={styles.hourDate}>
                      {firstDate.format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {secondSession && (
              <div className={styles.row2}>
                <div className={styles.dateOut}>
                  {secondDate?.format("MMM yyyy") !==
                    firstDate?.format("MMM yyyy") &&
                    secondDate?.format("MMM yyyy")}
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.chartDate}>
                    <div className={styles.dayDate}>
                      {secondDate.format("ddd").toUpperCase()}
                    </div>
                    <div className={styles.numberDate}>
                      {secondDate.format("DD")}
                    </div>
                  </div>
                </div>
                <div className={styles.chartContainer2}>
                  <div className={styles.titleContainer}>
                    <div className={styles.text1}>
                      {secondSession && secondSession.title}
                    </div>
                    <div className={styles.text2}>
                      {secondSession && secondSession.product.name}
                    </div>
                    <div className={styles.hourDate}>
                      {secondDate.format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {thirdSession && (
              <div className={styles.row}>
                <div className={styles.dateOut}>
                  {thirdDate?.format("MMM yyyy") !==
                    secondDate?.format("MMM yyyy") &&
                    thirdDate?.format("MMM yyyy")}
                </div>
                <div className={styles.chartContainer}>
                  <div className={styles.chartDate}>
                    <div className={styles.dayDate}>
                      {thirdDate.format("ddd").toUpperCase()}
                    </div>
                    <div className={styles.numberDate}>
                      {thirdDate.format("DD")}
                    </div>
                  </div>
                </div>
                <div className={styles.chartContainer2}>
                  <div className={styles.titleContainer}>
                    <div className={styles.text1}>
                      {thirdSession && thirdSession.title}
                    </div>
                    <div className={styles.text2}>
                      {thirdSession && thirdSession.product.name}
                    </div>
                    <div className={styles.hourDate}>
                      {thirdDate.format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export {
  BoxLicenses,
  BoxSessions,
  BoxGroups,
  BoxCourses,
  BoxUsers,
  BoxReports,
  BoxAssets,
  BoxToken,
  BoxSessions2,
  BoxSessionsMobile,
};
