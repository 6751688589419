import { connect } from "react-redux";
import RoleTableCard from "./table_cards/role_table_card";
import alert from '../../../../components/AlertModal/alertModal';
import { getAllRoles } from "../../../../actions/manageUsersActions";
import { useEffect, useState } from "react";
import "./roles_table.css";

const RolesTable = ({ roles, getAllRoles }) => {
    const [order, setOrder] = useState({
        column: "name",
        order: "asc",
    });
    useEffect(() => {
        const getRoles = async () => {
            try {
                getAllRoles(order);
            } catch (error) {
                alert("ERROR", error.message);
            }
        }
        return getRoles();
    })
    const handleSort = (column) => {
        try {
            if (order.order === "asc") {
                getAllRoles({ column, order: "desc" });
                setOrder({ column, order: "desc" });
            } else {
                getAllRoles({ column, order: "asc" });
                setOrder({ column, order: "asc" });
            }
        } catch (error) {
            alert("ERROR", error.message);
        }
    };
    return (
        <div className="ContTable table-container">
            <div className="table-header row-header-role">
                <div className="header-box clb" onClick={() => handleSort('name')}>ROLE ⇵</div>
                <div></div>
                <div></div>
            </div>
            <div className="table-rows rowsRAP">
                {
                    roles.map(role => {
                        return (
                            <RoleTableCard
                                key={role.idRole}
                                role={role}
                            />)
                        })
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        roles: state.manageUsers.roles
    };
};

export default connect(mapStateToProps, { getAllRoles })(RolesTable);