import ModalPage from "../modalPage-base/ModalPage";
import './ConfirmModal.css';




const ConfirmModal = (props) => {
    const {
        isOpen,
        title,
        text,
        closeModal,
        importance,
    } = props;
    return (
        <ModalPage isOpen={isOpen} importance={importance}>
            <div className="confirm-modal-C">
                <div className="confirm-content">
                    <h3 id="title-confirm"> {title}</h3>
                    <span id="text-confirm">{text}</span>
                </div>
                <div className="btn-confirmC">
                    <button id="acp-btn" className="btn-modalPages rigth" onClick={closeModal}>ACCEPT</button>
                    <button id="cnl-btn" className="btn-modalPages left" onClick={closeModal}>CANCEL</button>
                </div>
            </div>
        </ModalPage>
    );
};

export default ConfirmModal