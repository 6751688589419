
import { useLocation, useNavigate } from "react-router-dom";
import "./ArrowGoBack.css"

const ArrowGoBack = (props) => {
    const {
        max,
    } = props;
    const navigate = useNavigate();
    const url = useLocation();
    const array = url.pathname.split("/");
    let word = array.pop();
    Number(word) && array.pop();
    const goBack = (x) => {
        if (x === "") return;
        navigate(`/${x}`);
    };
    var href = max ? url.pathname.split("/").slice(1, max + 1) : url.pathname.split("/");
    var migas = href.filter(x => {
        const isNumero = Number(x);
        return (
            x !== "dashboard" &&
            x !== "" &&
            Number.isInteger(isNumero) === false
        );
    });
    return (
        <div className="arrow-container">
            {
                migas.map((x, i, h) => {
                    const ruta = x.replace(/_/g, " ").toUpperCase();
                    let rutaReal = x;
                    if (x === migas[migas.length - 1]) rutaReal = ""
                    if (migas.length === 1) {
                        return (
                            <div className="path-con firstPathC" key={i}>
                                <div className="arrow-path PathSel" onClick={() => goBack(rutaReal)}>{ ruta }</div>
                            </div>
                        );
                    } else if (i === 0) {
                        return (
                            <div className="path-con firstPathC" key={i}>
                                <div className="arrow-path" onClick={() => goBack(rutaReal)}>{ ruta }</div>
                            </div>
                        );
                    } else if (i === migas.length - 1) {
                        return (
                            <div className="path-con" key={i}>
                                <span className="barra-paht" onClick={() => goBack(rutaReal)}>/</span>
                                <div className="arrow-path PathSel" onClick={() => goBack(rutaReal)}>{ ruta }</div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="path-con" key={i}>
                                <span className="barra-paht">/</span>
                                <div className="arrow-path" onClick={() => goBack(rutaReal)}>{ ruta }</div>
                            </div>
                        );
                    }
                })
            }
        </div>
    )
};


export default ArrowGoBack;

