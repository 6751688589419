import './index.css';

const Button = (props) => {
    const {
        type,
        buttonClass,
        label,
        onClick,
        image,
        size, // small, medium, big 
        styleButton, //main, normal, light
        disabled,
    } = props;

    let styleClass = ''
    switch (styleButton) {
        case 'main':
            styleClass = 'main_button'
            break;
        case 'normal':
            styleClass = 'normal_button'
            break;
        case 'light':
            styleClass = 'light_button'
            break;
        case 'ultralight':
            styleClass = 'ultra_light_button'
            break;
        default:
            styleClass = ''
            break;
    }
    
    return (
        <div className='button_wrapper'>
            <button
                type={type}
                disabled={disabled}
                className={`simple_button 
                    ${size === 'small' ? 'small_button' : 
                        (size === 'big' ? 'big_button' : 
                        (size === 'medium' ? 'medium_button' : ''))} 
                    ${styleClass} 
                    ${buttonClass} 
                    ${image ? 'button_image' : ''}
                    ${disabled ? 'disabled' : ''}
                `}
                onClick={onClick}>
                {image ? image : ''}
                {label}
            </button>
        </div>
      
    );
};

export default Button;