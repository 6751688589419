import { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { getUsersByClient } from "../../../../actions/usersActions";
import { addUsersToGroup, getUsersByGroup } from "../../../../actions/manageGroupsActions";
import { closeLoading, openLoading } from "../../../../components/loadingModal/loading";

import addUserIcon from "../../../../assets/icons/AddUsersIcon.png";
import addUserIconBlue from "../../../../assets/icons/add_user_darkBlue.png"
import alert from "../../../../components/AlertModal/alertModal";
import Button from '../../../../components/Button'
import Table from "../../../../components/Table";

import styles from "./assign_users.module.css";

const AssignUsers = ({ 
    closeModal, 
    usersList,
    currentUsers,
    getUsers,
    addUsersToGroup,
    getUsersByGroup,
    newGroup
}) => {
    const [usersSelected, setUsersSelected] = useState({})
    const [availableUsers, setAvailableUsers] = useState(usersList)
    const { groupId } = useParams();
    
    useEffect(() => {
        const order ={
            column: "firstname",
            order: "asc"
        };
        getUsers(order);
    }, [groupId]);

    useEffect(() => {
        let users = [];
        usersList.forEach(u => {
            const index = currentUsers.findIndex(cu => cu.idUser === u.idUser);
            if (index < 0) {
                users.push(u)
            }
        });
        setAvailableUsers(users)
    }, [currentUsers, usersList, groupId])

    const handleCancel = (e) => {
        e.preventDefault();
        setUsersSelected([]);
        closeModal();
    };

    const onSave = async () => {
        openLoading();
        const ids = Object.keys(usersSelected);
        const users = ids.map(id => usersList.find(us => `${us.idUser}` === id))

        try {
            if (groupId) {
                await addUsersToGroup({idGroup: groupId, users})
                await getUsersByGroup(groupId ? groupId : newGroup, { column: "firstname", order: "asc", atribute: "users" }) &&
                setUsersSelected([]);
                closeModal();
            } else {
                await addUsersToGroup({users});
                setUsersSelected([]);
                closeModal();
            };
        } catch (error) {
            alert("Error", error.message);
        } finally {
            closeLoading();
        };
    };

    const columns = useMemo(
        () => [
          {
            accessorKey: 'username',
            header: 'username',
            Header: () => <div className="table-title">USERNAME</div>, 
            size: 200,
          },
          {
            accessorKey: 'firstname',
            header: 'firstname',
            Header: () => <div className="table-title">FIRSTNAME</div>, 
            filterVariant: 'text',
            size: 200,
          },
          {
            accessorKey: 'lastname',
            header: 'lastname',
            Header: () => <div className="table-title">LASTNAME</div>, 
            filterVariant: 'text',
            size: 200,
          },
        ],
        [],
    );

    return (
        <div className={`box-container-opaque ${styles.opaqueContainer}`}>
            <div className={`box-container entryPage group-box-entry ${styles.container}`}>
                <div className={`page-wrapper ${styles.wrapper}`}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={handleCancel}/>
                    </div>
                    <div className={styles.subHeaderContainer}>
                        <div className={`button_dark_1 ${styles.subHeader}`} onClick={handleCancel}>
                            <img src={addUserIconBlue} alt="icon" className={styles.icon}/>
                            <div className="text_btn_dark_1">ADD USERS</div>
                        </div>
                    </div>
                    <div className='table-container'>
                        { availableUsers && availableUsers.length > 0 &&
                            <Table 
                                rowId="idUser"
                                data={availableUsers} 
                                columns={columns}
                                height="55vh" 
                                enableRowSelection
                                setSelectedRows={setUsersSelected}
                            />
                        }
                    </div>
                    <div className={styles.footerActionsContainer}>
                        <div/>
                        <Button 
                            disabled={Object.keys(usersSelected).length === 0}
                            onClick={onSave}
                            label="ADD USERS"
                            size="small"
                            styleButton="main"
                            image={<img src={addUserIcon} alt="Add Users" className={styles.btnIcon}/>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: async (order) => {
            await dispatch(getUsersByClient(order));
        },
        addUsersToGroup: async (params) => {
            await dispatch(addUsersToGroup(params));
        },
        getUsersByGroup: async (groupId, params) => {
            await dispatch(getUsersByGroup(groupId, params));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        usersList: state.usersReducer.usersList,
        currentUsers: state.manageGroups.users
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignUsers);