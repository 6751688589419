import { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getReportByUser, getSession } from "../../../actions/reportsActions";
import iconDetails from "../../../assets/icons/Profile_Default.png";
import iconX from "../../../assets/box/close_dark.png";
import csvIcon from "../../../assets/icons/ExportCSV.png";
import moment from "moment/moment";
import DetailsTable from "./details_table";
import * as XLSX from "xlsx";
import * as cheerio from "cheerio";
import styles from "./details.module.css";
import {
  closeLoading,
  openLoading,
} from "../../../components/loadingModal/loading";

const Details = (props) => {
  const { reportUser, getReportByUser, getSession } = props;
  const params = useParams();
  const navigate = useNavigate();
  const getInitialData = async () => {
    openLoading();
    try {
      await getReportByUser(params);
    } catch (error) {
      alert("ERROR", error);
    } finally {
      closeLoading();
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);
  const name = reportUser.user.firstname + " " + reportUser.user.lastname;
  const timeSpent = moment(reportUser.timeSpent * 1000).format("m:ss");
  const averageTime = moment(
    (reportUser.timeSpent * 1000) / reportUser.reportUser.length
  ).format("m:ss");
  const lastAccess = moment(reportUser.lastAccess).format("MMM DD, YYYY");

  const updateLoadingProgress = (progress) => {
    let progressDiv = document.getElementById("loading-progress");
    if (!progressDiv) {
      // Si el div no existe, lo creamos y le asignamos estilos
      const loadingDiv = document.getElementById("loading-loop");
      if (loadingDiv) {
        progressDiv = document.createElement("div");
        progressDiv.id = "loading-progress";
        // Agregamos los estilos directamente en el elemento
        progressDiv.style.marginTop = "1rem";
        progressDiv.style.color = "white";
        loadingDiv.appendChild(progressDiv);
      }
    }
    // Actualizamos el contenido del div de progreso con el porcentaje
    if (progressDiv) {
      progressDiv.innerHTML = `${progress}%`;
    }
  };
  const removeLoadingProgress = () => {
    const progressDiv = document.getElementById("loading-progress");
    if (progressDiv) {
      progressDiv.remove(); // Elimina el div del DOM
    }
  };

  const exportExcel = async () => {
    let metricExcel = [];
    let baseIndex = 1; // Contador para el índice de registro base
    openLoading();
    const totalUsers = reportUser.reportUser.length;

    for (let i = 0; i < totalUsers; i++) {
      const user = reportUser.reportUser[i];

      // Obtener el HTML del segundo nivel (como ya lo tienes)
      const third_level = await getSession(user.idSession);
      const procedureHtml = third_level.data.html;

      // Parsear el HTML usando Cheerio
      const $ = cheerio.load(procedureHtml);

      // Obtener los encabezados de las columnas de la tabla (los <th>)
      let headers = [];
      $("table thead th").each((index, element) => {
        const headerText = $(element).text().trim();
        headers.push(headerText);
      });

      // Recorrer cada fila de la tabla en el HTML
      $("table tbody tr").each((index, element) => {
        // Crear un registro base duplicado para cada fila
        let dynamicData = {
          record_index: baseIndex, // Columna inicial que indica el índice
          User: reportUser.user.firstname,
          AttemptDate: moment(user.attemptDate.substring(0, 16)).format(
            "MMMM Do YYYY HH:mm:ss"
          ),
          "Time Spent": `${Math.abs(user.duration / 60).toFixed(2)} min`,
          Module: user.nameModule,
          Score: user.score,
        };

        // Iterar sobre las celdas de la fila (td) y agregar la información al registro
        $(element)
          .find("td")
          .each((i, tdElement) => {
            // Usar los encabezados como claves de las columnas
            const cellText = $(tdElement).text().trim();
            const columnName = headers[i] || `Column ${i + 1}`; // Si no hay encabezado, usar nombre genérico
            dynamicData[columnName] = cellText;
          });

        // Agregar el registro al array del Excel
        metricExcel.push(dynamicData);
      });

      baseIndex++; // Incrementar el índice para el siguiente registro base

      // Actualizar progreso
      const progress = Math.round(((i + 1) / totalUsers) * 100);
      updateLoadingProgress(progress);
    }

    // Crear el Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(metricExcel);
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(
      wb,
      `Report_${reportUser.user.firstname}-${reportUser.user.lastname}.xlsx`
    );

    closeLoading();
    updateLoadingProgress(100); // Progreso al 100% al finalizar

    // Retirar el div de progreso después de finalizar la exportación
    removeLoadingProgress();
  };

  return (
    <div className={`box-container-opaque`}>
      <div className={`entryPage box-entry ${styles.container}`}>
        <div className={`page-wrapper ${styles.pageWrapper}`}>
          <div className={styles.header}>
            <img
              src={iconX}
              alt="Close Details Page"
              className={styles.x}
              onClick={() => navigate("/reports")}
            />
            <img src={iconDetails} alt="Icon Details" className="icon_head" />
            <div className={styles.nameCon}>
              <div className={styles.name}>{name.toUpperCase()}</div>
              <div>{reportUser.user.username}</div>
            </div>
            <div className={styles.textGroup}>
              <div className={styles.title}>TOTAL SESSIONS</div>{" "}
              <div className={styles.subtitle}>
                {reportUser.reportUser.length}
              </div>
              <div className={styles.title}>TOTAL TIME SPENT (MINS)</div>{" "}
              <div className={styles.subtitle}>{timeSpent}</div>
              <div className={styles.title}>
                AVERAGE TIME PER SESSION (MINS)
              </div>{" "}
              <div className={styles.subtitle}>{averageTime}</div>
            </div>
            <div className={styles.textGroup2}>
              <div className={styles.title}>LAST ACCESS DATE</div>{" "}
              <div className={styles.subtitle}>{lastAccess}</div>
              <div className={styles.title}>GUIDED ATTEMPTS</div>{" "}
              <div className={styles.subtitle}>100%</div>
            </div>
          </div>
          <div className={styles.barCon}>
            <div
              className={styles.bar}
            >{`Product: ${reportUser.productName} | Group: ${reportUser.groupName}`}</div>
            <button
              className={`button_dark_1 ${styles.button}`}
              onClick={exportExcel}
            >
              <img
                src={csvIcon}
                alt="Export csv"
                className={`icon_btn_1 ${styles.iconButton}`}
              />
              <div className="text_btn_dark_1">EXPORT CSV</div>
            </button>
          </div>
          <div className={styles.tableContainer}>
            <DetailsTable />
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reportUser: state.metricIndividualReducer.reportUser,
  };
};

export default connect(mapStateToProps, { getReportByUser, getSession })(
  Details
);
