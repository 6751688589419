
import Axios from "../config/Axios";

export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const DELETE_ROLE = 'DELETE_ROLE';
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS';
export const GET_ROLE_PERMISSIONS = 'GET_ROLE_PERMISSIONS';
export const CLEAR_ROLE_PERMISSIONS = 'CLEAR_ROLE_PERMISSIONS';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const SELECT_PERMISSION = 'SELECT_PERMISSION';
export const UN_SELECT_PERMISSION = 'UN_SELECT_PERMISSION';
export const CLEAR_SELECTED_PERMISSIONS = 'CLEAR_SELECTED_PERMISSIONS';
export const COUNT_USERS = 'COUNT_USERS';
export const DELETE_USER = 'DELETE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';

const { REACT_APP_API_URL } = process.env;

const getAllRoles = ({ order, column }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageUsers/getAllRoles`, {
            params: {
                order,
                column
            }
        })
            .then(response => {
                dispatch({
                    type: GET_ALL_ROLES,
                    payload: response.data
                });
            })
            .catch(error => {
                throw new Error(error);
            });
    };
};

const deleteRole = (idRole) => {
    return (dispatch) => {
        return Axios.delete(`${REACT_APP_API_URL}/manageUsers/deleteRole/${idRole}`)
            .then(response => {
                dispatch({
                    type: DELETE_ROLE,
                    payload: response.data
                });
                return true;
            })
            .catch(error => {
                error.response.status === 409 ? alert("This role is used by a user") : alert("Error deleting role");
                throw new Error(error);
            });
    };
};

const getAllPermissions = ({ order, column }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageUsers/getAllPermissions`, {
            params: {
                // order,
                // column
            }
        })
            .then(response => {
                dispatch({
                    type: GET_ALL_PERMISSIONS,
                    payload: response.data
                });
            })
            .catch(error => {
                throw new Error(error);
            });
    };
};

const getRolePermissions = (idRole, { column, atribute, order }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageUsers/getRolePermissions/${idRole}`, {
            params: {
                // column,
                // atribute,
                // order
            }
        })
            .then(response => {
                dispatch({
                    type: GET_ROLE_PERMISSIONS,
                    payload: response.data
                });
            })
            .catch(error => {
                throw new Error(error);
            });
    };
};

const clearRolePermissions = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ROLE_PERMISSIONS
        });
    };
};

const createRole = (role) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/manageUsers/createRole`, role)
            .then(response => {
                dispatch({
                    type: CREATE_ROLE,
                    payload: response.data
                });
                return true;
            })
            .catch(error => {
                error.response.status === 409 ? alert("This role already exists") : alert("Error creating role");
                throw new Error(error);
            });
    };
};

const updateRole = (role) => {
    return (dispatch) => {
        return Axios.put(`${REACT_APP_API_URL}/manageUsers/updateRole`, role)
            .then(response => {
                dispatch({
                    type: UPDATE_ROLE,
                    payload: response.data
                });
                return true;
            })
            .catch(error => {
                throw new Error(error);
            });
    };
};

const selectPermission = (idPermission) => {
    return (dispatch) => {
        dispatch({
            type: SELECT_PERMISSION,
            payload: idPermission
        })
    };
};

const unSelectPermission = (idPermission) => {
    return (dispatch) => {
        dispatch({
            type: UN_SELECT_PERMISSION,
            payload: idPermission
        })
    };
};

const clearSelectedPermissions = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SELECTED_PERMISSIONS,
            payload: null
        })
    };
};

const countUsers = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageUsers/countUsers`)
            .then(response => {
                dispatch({
                    type: COUNT_USERS,
                    payload: response.data.data
                });
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const deleteUser = (idUser) => {
    return (dispatch) => {
        return Axios.delete(`${REACT_APP_API_URL}/manageUsers/deleteUser/${idUser}`)
            .then(response => {
                dispatch({
                    type: DELETE_USER,
                    payload: response.data
                });
                return true;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const resetPassword = (idUser, password) => {
    return (dispatch) => {
        return Axios.put(`${REACT_APP_API_URL}/manageUsers/resetPassword`, { idUser, password })
            .then(response => {
                dispatch({
                    type: RESET_PASSWORD,
                    payload: response.data
                });
                return response.data;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

export {
    getAllRoles,
    deleteRole,
    getAllPermissions,
    getRolePermissions,
    clearRolePermissions,
    createRole,
    updateRole,
    selectPermission,
    unSelectPermission,
    clearSelectedPermissions,
    countUsers,
    deleteUser,
    resetPassword
};