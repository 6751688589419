import moment from "moment";
import { useState } from "react";
import infoIcon from "../../../../assets/box/Info_3@2x-8.png";
import DeviceDetailsTable from "../../device_details_table/device_details_table";
import styles from "./table_card.module.css";

const TableCard = (props) => {
    const { data, color } = props;
    const [openInfo, setOpenInfo] = useState(false);
    const activationDate = moment(data.activationDate).format('MMM DD, YYYY');
    const handleOpen = async (e) => {
        if (document.getElementById(`iconOpenDD${data.hardwareId}`).classList.contains('iconOpenDD')) return handleCloseInfo();
        let opens = [];
        opens = document.getElementsByClassName('iconOpenDD');
        for (let i = 0; i < opens.length; i++) {
            opens[i].click();
        };
        document.getElementById(`iconOpenDD${data.hardwareId}`).className += " iconOpenDD";
        openInfo ? setOpenInfo(false) : setOpenInfo(true);
    };
    const handleOpen2 = async (e) => {
        setOpenInfo(true);
    };
    const handleCloseInfo = () => {
        setOpenInfo(false);
        document.getElementById(`iconOpenDD${data.hardwareId}`).classList.remove('iconOpenDD');
    };
    return (
        <div className={`row ${styles.row} ${color}`}>
            <div className={`content-box clear ${styles.detailsCon}`}>
                <div >{data.name}</div>
                <img src={infoIcon} alt="Device details info icon" className={styles.infoIcon} onClick={handleOpen} id={`iconOpenDD${data.hardwareId}`}/>
                {/* <img src={infoIcon} alt="Device details info icon" className={styles.infoIcon} onClick={handleOpen} id={`iconOpenDD${data.hardwareId}`} onMouseEnter={handleOpen2} onMouseLeave={handleCloseInfo}/> */}
                <DeviceDetailsTable deviceId={data.idDevice} open={openInfo} close={handleCloseInfo}/>
            </div>
            <div className="content-box clear">{data.platform.name}</div>
            <div className="content-box clear">{data.hardwareId}</div>
            <div className="content-box clear ultimo">{activationDate}</div>
        </div>
    );
};

export default TableCard;