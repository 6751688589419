import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getProductLicenses } from "../../../actions/productsActions";
import styles from "../index.module.css"
import Table from "../../../components/Table";
import moment from "moment";

const statusList = [
    'Expired',
    'Active',
];

const Template = (props) => {
    const {
        products, 
        getProductLicenses,
    } = props;
    useEffect(() => {
        const order = {
            order: 'asc',
            column: 'name'
        }
        getProductLicenses(order);
    }, []);

    const columns = useMemo(
        () => [
          {
            id: 'licenses',
            header: 'Licenses',
            Header: () => <div className="table-title">LICENSES</div>, 
            accessorFn: (row) => row.product ? row.product.name : '',
            // filterFn: (row, id, filterValue) => {
            //     return row.original.product.toLowerCase().includes(filterValue.toLowerCase())
            // },
            size: 150,
            enableColumnFilter: false,
          },
          {
            id: 'status',
            header: 'Status',
            Header: () => <div className="table-title">STATUS</div>, 
            accessorFn: (row) => {
                const isExpired = moment(row.dateEnd).isBefore(moment());
                return (
                <div>{isExpired ? 
                    <div className={styles.license_expired_text}>Expired</div> :
                    <div className={styles.license_active_text}>Active</div>
                }</div>
            )},
            // filterVariant: 'select',
            // filterSelectOptions: statusList, //custom options list 
            enableColumnFilter: false,
            size: 30,
          },
          {
            id: 'startDate',
            header: 'Start Date',
            Header: () => <div className="table-title">START DATE</div>,
            accessorFn: (row) => row.dateStart ? moment(row.dateStart).format('MMM DD, YYYY') : '',
            enableColumnFilter: false,
            enableSorting: false,
            size: 20,
          },
          {
            id: 'endDate',
            header: 'End Date',
            Header: () => <div className="table-title">END DATE</div>,
            accessorFn: (row) => row.dateEnd ? moment(row.dateEnd).format('MMM DD, YYYY') : '',
            enableColumnFilter: false,
            enableSorting: false,
            size: 20,
          },
          // {
          //   id: 'installations',
          //   header: 'instalations',
          //   Header: () => <div className="table-title">INSTALLATIONS</div>,
          //   accessorFn: (row) => `${row.asignateDevices}/${row.numDevices}`,
          //   enableColumnFilter: false,
          //   enableSorting: false,
          //   size: 20,
          // }
        ],
        [],
    );
  
    return <Table data={products} height="63vh" columns={columns}/>;
};

const mapStateToProps = state => {
    return {
        products: state.productsReducer.productsLicenses,
        nextExpirations: state.productsReducer.nextExpirations
    }
};

export default connect(mapStateToProps, { getProductLicenses })(Template);