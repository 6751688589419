import { useState } from 'react';
import { connect } from 'react-redux';
import { sortReportUser } from '../../../actions/reportsActions';
import styles from './reports_table_learner.module.css';
import TableCard from './table_card/table_card';

const ReportsTableLearner = (props) => {
    const {
        reportUser,
        sortReportUser
    } = props;
    const [sort, setSort] = useState("desc");
    const handleSort = (e) => {
        sort === "asc" ? setSort("desc") : setSort("asc");
        sortReportUser({ order: sort, field: e });
    };
    return (
        <div className={styles.container}>
            <div className={`table-header ${styles.tableHeader}`}>
                <div className={`header-box sort`} onClick={() => handleSort('attemptDate')}>ATTEMPT DATE <div className="arrow_sort"/></div>
                <div className={`header-box sort`} onClick={() => handleSort('attemptDate')}>MODULE <div className="arrow_sort"/></div>
                <div className={`header-box sort`} onClick={() => handleSort('attemptDate')}>TIME SPENT (MINS) <div className="arrow_sort"/></div>
                <div className={`header-box sort`} onClick={() => handleSort('attemptDate')}>RESULTS <div className="arrow_sort"/></div>
            </div>
            <div className={`table-rows ${styles.rows}`}>
                {
                    reportUser.map((report, i) => {
                        return (
                            <TableCard key={i} report={report} color={i % 2 === 0 ? 'dark' : ''}/>
                        )
                    })
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        reportUser: state.metricIndividualReducer.reportUser.reportUser
    };
};

export default connect(mapStateToProps, { sortReportUser })(ReportsTableLearner);