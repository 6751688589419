import {
    CLEAR_SELECTED_PERMISSIONS,
    DELETE_ROLE,
    GET_ALL_PERMISSIONS,
    GET_ALL_ROLES, GET_ROLE_PERMISSIONS,
    CLEAR_ROLE_PERMISSIONS,
    SELECT_PERMISSION,
    UN_SELECT_PERMISSION,
    COUNT_USERS
} from "../actions/manageUsersActions";


var initialState = {
    roles : [],
    permissions : [],
    rolePermissions : {permissions: []},
    permissionsSelected : [],
    rolesDeleted : [],
    counterUsers : { total: 0, active: 0, inactive: 0 }
};

const manageUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_ALL_ROLES):
            return state = {
                ...state,
                roles: action.payload
            };
        case (DELETE_ROLE):
            return state = {
                ...state,
                rolesDeleted: [...state.rolesDeleted, action.payload]
            };
        case (GET_ALL_PERMISSIONS):
            return state = {
                ...state,
                permissions: action.payload
            };
        case (GET_ROLE_PERMISSIONS):
            return state = {
                ...state,
                rolePermissions: action.payload
            };
        case (CLEAR_ROLE_PERMISSIONS):
            return state = {
                ...state,
                rolePermissions: {permissions: []}
            };
        case (SELECT_PERMISSION):
            return state = {
                ...state,
                permissionsSelected: [...state.permissionsSelected, action.payload]
            };
        case (UN_SELECT_PERMISSION):
            return state = {
                ...state,
                permissionsSelected: state.permissionsSelected.filter(permissionId => permissionId !== action.payload)
            };
        case (CLEAR_SELECTED_PERMISSIONS):
            return state = {
                ...state,
                permissionsSelected: []
            };
        case (COUNT_USERS):
            return state = {
                ...state,
                counterUsers: action.payload
            };
        default: return state;
    }
};
export default manageUsersReducer;