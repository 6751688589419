import axios from "axios";

function credentialAxios() {
    const token = localStorage.getItem('token');
    let auth;
    if (token) auth = {'authorization': `Bearer ${token}`};
    const Axios = axios.create({
        withCredentials: true,
    });
    return Axios;
};
const Axios = credentialAxios();
export default Axios;