import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import reportsIcon from "../../assets/icons/reports-icon.png";
import styles from "./index.module.css";
import { connect } from "react-redux";
import { listProducts } from "../../actions/productsActions";
import { useEffect, useState } from "react";
import {
  getGeneralReport,
  getReportsByGroupProduct,
  getReportsByProduct,
} from "../../actions/reportsActions";
import ReportsTable from "./reports_table/reports_table";
import { Outlet } from "react-router-dom";
import IndexLearner from "./learnerView";
import {
  closeLoading,
  openLoading,
} from "../../components/loadingModal/loading";
import alert from "../../components/AlertModal/alertModal";
import { getCourses } from "../../actions/manageCoursesActions";
import MaterialSelect from "../../components/MaterialSelect";

const AdminView = (props) => {
  const {
    getCourses,
    getReportsByGroupProduct,
    listProducts,
    userPermissions,
    getGeneralReport,
    getReportsByProduct,
  } = props;
  const initialState = {
    products: [],
    courses: [],
    reports: [],
    reportsInfo: {},
  };
  const [state, setState] = useState(initialState);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [text, setText] = useState("Select a product");
  const getInitialData = async () => {
    openLoading();
    try {
      const response = await listProducts({ userProducts: true });
      if (userPermissions.includes(17)) {
        const all_ = await getGeneralReport();
        setState({
          ...state,
          products: [...response, { idProduct: "0", name: "All" }],
          courses: [{ idCourse: "0", name: "All" }],
          reportsInfo: all_,
          reports: [],
        });
        setSelectedCourse("0");
        setSelectedProduct("0");
        setText("Select a product");
      } else {
        setState({
          ...state,
          products: response,
          courses: [],
          reports: [],
        });
        setSelectedProduct("");
        setText("Select a product");
      }
    } catch (error) {
      alert("ERROR", error);
    } finally {
      closeLoading();
    }
  };
  useEffect(() => {
    getInitialData();
    return () => {
      setState(initialState);
    };
  }, []);
  const totalTime = () => {
    let minutes = state.reportsInfo.totalTimeSpent / 60;
    if (state.reportsInfo.totalTimeSpent > 0) return `${minutes.toFixed()}`;
    else return 0;
  };
  const handleSelectProduct = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedProduct(value);
    openLoading();
    try {
      if (value === "0") {
        const response = await getGeneralReport();
        setState({
          ...state,
          reportsInfo: response,
          reports: [],
        });
        setSelectedCourse("0");
        setText("Please select a course to view reports");
      } else {
        const courses = await getCourses({ productIdProduct: value });
        setState({
          ...state,
          courses: [...courses, { idCourse: "0", name: "All" }],
          reportsInfo: {},
          reports: [],
        });
        setSelectedCourse("");
        setText("Please select a course to view reports");
      }
    } catch (error) {
      alert("ERROR", error);
    } finally {
      closeLoading();
    }
  };
  const handleSelectCourse = async (e) => {
    const {
      target: { value },
    } = e;
    setSelectedCourse(value);
    openLoading();
    try {
      if (value === "0") {
        const response = await getReportsByProduct({
          idProduct: selectedProduct,
        });
        setState({
          ...state,
          reports: response.reports,
          reportsInfo: response.generalInfo,
        });
        setText("There are no reports for this course");
      } else {
        const response = await getReportsByGroupProduct({
          idCourse: value,
          idProduct: selectedProduct,
        });
        setState({
          ...state,
          reports: response.reports,
          reportsInfo: response.generalInfo,
        });
        setText("There are no reports for this course");
      }
    } catch (error) {
      alert("ERROR", error);
    } finally {
      closeLoading();
    }
  };

  if (userPermissions.includes(15)) {
    return (
      <div className={`box-container ${styles.container}`}>
        <ArrowGoBack max={2} />
        <div className={styles.headerContainer}>
          <img src={reportsIcon} alt="Reports Icon" className="icon_head" />
          <div className={styles.select}>
            <MaterialSelect
              label="Product"
              formField="product"
              value={selectedProduct}
              onChange={handleSelectProduct}
              options={
                state.products &&
                state.products.map((product) => ({
                  id: product.idProduct,
                  label: product.name,
                }))
              }
            />
          </div>
          <div className={styles.select}>
            <MaterialSelect
              label="Course"
              formField="course"
              disabled={selectedProduct === "0" || state.courses.length === 0}
              value={selectedCourse}
              onChange={handleSelectCourse}
              options={
                state.courses &&
                state.courses.map((course) => ({
                  id: course.idCourse,
                  label: course.name,
                }))
              }
            />
          </div>
        </div>
        <div className={styles.circlesContainer}>
          <div className={styles.circleCon}>
            <div className="circle_info">
              <div className="circle_info_number">
                {state.reportsInfo.totalModules}
              </div>
            </div>
            <div className={styles.textCircle}>TOTAL MODULES</div>
          </div>
          <div className={styles.circleCon}>
            <div className="circle_info">
              <div className="circle_info_number">
                {state.reportsInfo.totalUsers}
              </div>
            </div>
            <div className={styles.textCircle}>TOTAL STUDENTS</div>
          </div>
          <div className={styles.circleCon}>
            <div className="circle_info">
              <div className="circle_info_number">
                {state.reportsInfo.totalTimeSpent && totalTime()}
              </div>
            </div>
            <div className={styles.textCircle}>TOTAL TIME SPENT (MINS)</div>
          </div>
          <div className={styles.circleCon}>
            <div className="circle_info">
              <div className="circle_info_number">
                {state.reportsInfo.studentsFullCompletion}
              </div>
            </div>
            <div className={styles.textCircle}>STUDENTS FULL COMPLETION</div>
          </div>
          <div className={styles.circleCon}>
            <div className="circle_info">
              <div className="circle_info_number">
                {state.reportsInfo.totalRate &&
                  `${Number(state.reportsInfo.totalRate).toFixed()} %`}
              </div>
            </div>
            <div className={styles.textCircle}>COMPLETION RATE</div>
          </div>
        </div>
        {state.reports && state.reports.length > 0 ? (
          <div className={styles.tableContainer}>
            <ReportsTable reports={state.reports} />
          </div>
        ) : (
          <div className="text-title-slow">{text}</div>
        )}
        <Outlet />
      </div>
    );
  } else {
    return <IndexLearner />;
  }
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.usersReducer.userLogged.permissions,
    products: state.productsReducer.allProducts,
    groups: state.metricIndividualReducer.groupsByProduct,
  };
};

export default connect(mapStateToProps, {
  getCourses,
  getReportsByGroupProduct,
  listProducts,
  getGeneralReport,
  getReportsByProduct,
})(AdminView);
