import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getLicensesDetails } from "../../../actions/licenseAction";
import { filterLicensesDetails } from "../../../actions/licenseAction";
import TableCard from "./table_card/table_card";
import expirationIcon from "../../../assets/icons/Expiration_ClearBlue.png";
import licenseIcon from "../../../assets/icons/licenseDetailsEx.png";
import styles from "./table.module.css"
import PorcentageChart from "./porcentage_chart/porcentage_chart";
import closeIcon from "../../../assets/box/close_dark.png";

const LicenseDetailsTable = (props) => {
    const licenseId = useParams().licenseId;
    let expireDate = props.data.dateEnd ? new Date(props.data.dateEnd).toDateString() : "";
    let remainingDays = props.data.dateEnd ? Math.ceil((new Date(props.data.dateEnd).getTime() - new Date().getTime()) / (1000 * 3600 * 24)) : "";
    let devicesUsed = props.data.devices ? props.data.asignateDevices : 0;
    let devices = props.data.numDevices ? props.data.numDevices : 0;
    let name = props.data.product ? props.data.product.name : "";
    const [order, setOrder] = useState({
        order: 'asc',
        column: 'name',
        deep: 'devices',
    });
    const navigate = useNavigate();
    useEffect(() => {
        props.getData(licenseId, order);
        expireDate = new Date(props.data.dateEnd);
    }, [licenseId])
    const handleSort = (column, deep) => {
        if (order.order === 'asc') {
            setOrder({ order: 'desc', column, deep });
            props.getData(licenseId, { order: 'desc', column: column, deep: deep });
        } else {
            setOrder({ order: 'asc', column, deep });
            props.getData(licenseId, { order: 'asc', column: column, deep: deep });
        };
    };
    const handleFilter = (e) => {
        props.filterLicenses(e.target.value);
    };
    return (
        <div className={`box-container-opaque`}>
            <div className={`entryPage box-container box-entry ${styles.mayorContainer}`}>
                <div className={`${styles.firstBox}`}>
                    <img src={closeIcon} className={styles.close} onClick={() => navigate('/licenses')}/>
                    <div className={styles.generalHeader}>
                        <div className={styles.boxExpiration}>
                            <img src={licenseIcon} alt="License logo" className={styles.logoLicense}/>
                            <h1 className={styles.headTitle}>{name.toUpperCase()}</h1>
                        </div>
                        <div className={styles.progresBarCon}>
                            <PorcentageChart quantity={devicesUsed} total={devices} />
                            <div className={styles.titleProgres}>INSTALLATIONS</div>
                        </div>
                        <div className={styles.boxExpiration}>
                            <img src={expirationIcon} alt="Expiration Icon" className={styles.expIcon}/>
                            <div className={styles.textGroup}>
                                <div className={styles.headTitle}>EXPIRATION</div>
                                <div className={styles.expDate}>{expireDate}</div>
                                <div className={styles.expDate}>{remainingDays} days left</div>
                            </div>
                        </div>
                        <div className={styles.searchCon}>
                            <input type="text" className={`in-FLD ${styles.search}`} onChange={handleFilter} placeholder="Search"/>
                        </div>
                    </div>
                </div>
                <div className={`contTable ${styles.tableContainer}`}>
                    <div className={`table-header table-header-clear ${styles.tableHeader}`}>
                        <div className="header-box sort" onClick={() => handleSort('name', 'devices')}>DEVICE NAME <div className="arrow_sort"/></div>
                        <div className="header-box sort" onClick={() => handleSort('name', 'platforms')}>DEVICE TYPE <div className="arrow_sort"/></div>
                        <div className="header-box">SERIAL NUMBER</div>
                        <div className="header-box">ACTIVATION DATE</div>
                    </div>
                    <div className={`table-rows ${styles.rows}`}>
                        {
                            props.data.devices.map((x, i) => {
                                return (
                                    <TableCard key={x.idDevice} data={x} color={i % 2 === 0 && 'dark'}/>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        data: state.licenseReducer.licensesDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getData: (licenseId, order) => dispatch(getLicensesDetails(licenseId, order)),
        filterLicenses: (str) => dispatch(filterLicensesDetails(str)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseDetailsTable);