import accountIcon from "../../assets/icons/boardHeader/Profile@2x-8.png";
import helpIcon from "../../assets/icons/boardHeader/Help@2x-8.png";
import logo from '../../assets/Xennial_Logo_White@2x-8.png';
import whiteLine from '../../assets/icons/navbar/White_Line-8.png';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./BoardHeader.module.css";

const BoardHeader = ({ urlLogo, firtsname, lastname, clientLogo, userId, role }) => {
    const logOut = (e) => {
        localStorage.removeItem("token");
        window.location.reload();
    };
    const navigate = useNavigate();
    return (
        <div className={styles.boardHeader}>
            <div className={styles.logCon}>
                <div className={styles.logoC}>
                    <img className={styles.logo} src={logo} alt="logo" />
                </div>
                <div className="white-line-c">
                    <img className={styles.line} src={whiteLine} alt="white line" />
                </div>
                <div className={styles.logoC2}>
                    <img className={styles.logo} src={clientLogo} alt="header"/>
                </div>
            </div>
            <div className={styles.containerTwo}>
                <div className={styles.nameCon}>
                    <span className={styles.name}>
                        {`${firtsname} ${lastname}`}
                    </span>
                    <span className={styles.roleName}>{role}</span>
                </div>
                <div className={styles.lineCon}>
                    <img className={styles.line} src={whiteLine} alt="white line" />
                </div>
                <div className={styles.iconGroup}>
                    <div className={styles.iconContainer}>
                        <img src={accountIcon} alt="icon" className={styles.icon} />
                        <div className={styles.iconText}>Profile</div>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.miniBox} onClick={() => navigate(`./profile`)}>EDIT</div>
                        <div className={styles.line2}></div>
                        <div className={styles.miniBox2} onClick={logOut}>LOGOUT</div>
                    </div>
                </div>
                {/* <div className={styles.iconGroup}>
                    <div className={styles.iconContainer}>
                        <img src={helpIcon} alt="icon" className={styles.iconHelp} />
                        <div className={styles.iconText} >Help</div>
                    </div>
                    <div className={styles.boxx}>
                        <div className={styles.miniBox}>FAQ</div>
                        <div className={styles.line2}></div>
                        <div className={styles.miniBox2}>CREATE TICKET</div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        urlLogo: state.client.logo,
        firtsname: state.usersReducer.userLogged.firstname,
        lastname: state.usersReducer.userLogged.lastname,
        userId: state.usersReducer.userLogged.idUser,
        clientLogo: state.usersReducer.userLogged.url_logo,
        role: state.usersReducer.userLogged.role,
    };
};

export default connect(mapStateToProps, null)(BoardHeader);