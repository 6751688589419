import { connect } from "react-redux";
import styles from "./index.module.css";
import {
  BoxAssets,
  BoxCourses,
  BoxGroups,
  BoxLicenses,
  BoxReports,
  BoxSessions,
  BoxSessions2,
  BoxSessionsMobile,
  BoxToken,
  BoxUsers,
} from "./boxes/boxes.jsx";
import { useEffect } from "react";
import { totalLicensesActive } from "../../actions/licenseAction.js";
import alert from "../../components/AlertModal/alertModal.js";
import {
  closeLoading,
  openLoading,
} from "../../components/loadingModal/loading.js";
import {
  getOngoingScheduled,
  getSessions,
} from "../../actions/manageSessionsAction.js";
import { countGroups } from "../../actions/manageGroupsActions.js";
import { countUsers } from "../../actions/manageUsersActions.js";
import { getStorageInfo } from "../../actions/assetsAction.js";
import { getMetricInitialData } from "../../actions/reportsActions.js";
import { countCourses } from "../../actions/manageCoursesActions.js";
import { getProductIdsByUser } from "../../actions/productsActions.js";

const Home = (props) => {
  const {
    user,
    totalLicensesActive,
    getOngoingScheduled,
    getSessions,
    sessionsNumber,
    licenses,
    token,
    timer,
    sessions,
    counterGroups,
    counterCourses,
    countGroups,
    counterUsers,
    countUsers,
    countCourses,
    getStorageInfo,
    storageInfo,
    reports,
    productsIds,
    has_storage,
    ongoingScheduled,
    getProductIdsByUser,
    getMetricInitialData,
  } = props;
  const mediaQuery = window.matchMedia("(max-width: 427px)");
  const permissions = user.permissions ? user.permissions : [];
  const getInitialData = async () => {
    openLoading();
    try {
      await totalLicensesActive();
      await getOngoingScheduled();
      await getSessions();
      await countGroups();
      await getProductIdsByUser(user.idUser);
      await countUsers();
      (permissions.includes(22) ||
        permissions.includes(23) ||
        permissions.includes(24)) &&
        (await countCourses());
      has_storage && (await getStorageInfo());
      await getMetricInitialData();
    } catch (error) {
      // alert('ERROR', error);
    } finally {
      closeLoading();
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);
  const role = user.role ? user.role : "";
  if (role.toUpperCase() === "ADMIN") {
    if (mediaQuery.matches) {
      return (
        <div className={styles.container}>
          <BoxLicenses licenses={licenses} />
          <BoxGroups groups={counterGroups} />
          <BoxSessionsMobile sessions={sessions} />
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <div className={styles.containerRow1}>
            <BoxLicenses licenses={licenses} />
            <BoxSessions sessions={ongoingScheduled} />
            <div className={styles.container1Row2Column}>
              <BoxGroups groups={counterGroups} />
              <BoxCourses courses={counterCourses} />
            </div>
          </div>
          <div className={styles.containerRow2}>
            <BoxUsers
              totalUsers={counterUsers.total}
              inactiveUsers={counterUsers.inactive}
              activeUsers={counterUsers.active}
            />
            <BoxReports reports={reports} />
            <BoxAssets
              has_storage={has_storage}
              storage={storageInfo}
              mediaQuery={mediaQuery}
              smally={true}
            />
          </div>
        </div>
      );
    }
  } else if (role.toUpperCase() === "FACULTY") {
    if (mediaQuery.matches) {
      return (
        <div className={styles.containerTwo}>
          <BoxToken token={token} timer={timer} />
          <BoxSessionsMobile sessions={sessions} />
        </div>
      );
    } else {
      return (
        <div className={styles.containerTwo}>
          <div className={styles.containerTwoRow1}>
            <BoxToken token={token} timer={timer} />
            <BoxSessions2 sessions={sessions} />
          </div>
          <div className={styles.containerTwoRow2}>
            <div className={styles.container1Row2Column}>
              <BoxGroups groups={counterGroups} />
              <BoxCourses courses={counterCourses} />
            </div>
            <BoxReports reports={reports} />
            <BoxAssets has_storage={has_storage} storage={storageInfo} />
          </div>
        </div>
      );
    }
  } else if (role.toUpperCase() === "LEARNER") {
    return (
      <div className={styles.containerThree}>
        <BoxToken
          token={token}
          timer={timer}
          noProducts={productsIds && productsIds.length === 0 ? true : false}
        />
        {!mediaQuery.matches ? (
          <BoxSessions2 sessions={sessions} />
        ) : (
          <BoxSessionsMobile sessions={sessions} />
        )}
        {!mediaQuery.matches && <BoxReports reports={reports} />}
        {!mediaQuery.matches && (
          <BoxAssets has_storage={has_storage} storage={storageInfo} />
        )}
      </div>
    );
  } else if (role.toUpperCase() === "SCHEDULER") {
    return (
      <div className={styles.containerThree}>
        <BoxToken token={token} timer={timer} />
        {!mediaQuery.matches ? (
          <BoxSessions2 sessions={sessions} />
        ) : (
          <BoxSessionsMobile sessions={sessions} />
        )}
        {/* { !mediaQuery.matches && <BoxReports reports={reports} /> } */}
        {!mediaQuery.matches && <BoxReports reports={reports} />}
        {!mediaQuery.matches && (
          <BoxAssets has_storage={has_storage} storage={storageInfo} />
        )}
      </div>
    );
  } else if (role.toUpperCase() === "TEACHER ASSISTANT") {
    return (
      <div className={styles.containerThree}>
        <BoxToken token={token} timer={timer} />
        {!mediaQuery.matches ? (
          <BoxSessions2 sessions={sessions} />
        ) : (
          <BoxSessionsMobile sessions={sessions} />
        )}
        {!mediaQuery.matches && <BoxReports reports={reports} />}
        {!mediaQuery.matches && (
          <BoxAssets has_storage={has_storage} storage={storageInfo} />
        )}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.usersReducer.userLogged,
    // devices: state.devicesReducer.devices,
    productsIds: state.productsReducer.productsIds,
    licenses: state.licenseReducer.totalLicensesActive,
    sessionsNumber: state.manageSessionReducer.ongoingScheduled,
    token: state.manageSessionReducer.tokenSessions.Token,
    timer: state.manageSessionReducer.tokenSessionsTime,
    sessions: state.manageSessionReducer.sessions.upcomingSessions,
    ongoingScheduled: state.manageSessionReducer.ongoingScheduled,
    counterGroups: state.manageGroups.countGroups,
    counterCourses: state.manageCourses.counterCourses,
    counterUsers: state.manageUsers.counterUsers,
    storageInfo: state.assets.storageInfo,
    reports: state.metricIndividualReducer.metricInitialData,
    has_storage: state.usersReducer.userLogged.has_storage,
  };
};

export default connect(mapStateToProps, {
  totalLicensesActive,
  getOngoingScheduled,
  getSessions,
  countGroups,
  countUsers,
  countCourses,
  getStorageInfo,
  getProductIdsByUser,
  getMetricInitialData,
})(Home);
