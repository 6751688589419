import { connect } from "react-redux";
import styles from './index.module.css';
import icon from '../../../assets/icons/session_icon.png';
import { closeLoading, openLoading } from "../../../components/loadingModal/loading";
import alert from "../../../components/AlertModal/alertModal";
import { useEffect, useState } from "react";
import { getSession } from "../../../actions/manageSessionsAction";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Table from "./users_table";


const PastSessionPage = (props) => {
    const {
        getSession
    } = props;
    const { idSession } = useParams();
    const navigate = useNavigate();
    const initialState = {
        title: '',
        product: {},
        course: {},
        date: '',
        time: '',
        users: []
    }
    const [state, setState] = useState(initialState);
    const getInitialData = async () => {
        openLoading();
        try {
            const response = await getSession(idSession);
            const date = moment(response.dateSession.split("T")[0]).format("MMM DD, YYYY");
            const time = moment(response.dateSession).format("h:mm A");
            setState({...response, date, time});
        } catch (error) {
            alert('error', error.message);
        } finally {
            closeLoading();
        };
    };
    useEffect(() => {
        getInitialData();
        return () => {
            setState(initialState);
        };
    }, []);
    return (
        <div className="box-container-opaque">
            <div className={`entryPage ${styles.container}`}>
                <div className={`page-wrapper ${styles.wrapper}`}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={() => navigate('../')}/>
                        <img src={icon} alt="session icon" />
                        <span >{state.title}</span>
                        <div className={styles.textGroup}>
                            <div>
                                <div>PRODUCT</div>
                                <div>{state.product.name}</div>
                            </div>
                            <div>
                                <div>COURSE</div>
                                <div>{state.course? state.course.name : "there not associated courses" }</div>
                            </div>
                            <div>
                                <div>ROOM NUMBER</div>
                                <div>{state.sessionCode}</div>
                            </div>
                        </div>
                        <div className={styles.textGroup}>
                            <div >
                                <div>DATE</div>
                                <div>{state.date}</div>
                            </div>
                            <div>
                                <div>START TIME</div>
                                <div>{state.time}</div>
                            </div>
                        </div>
                    </div>
                    <div className='table-container'>
                        <Table users={state.users}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { getSession })(PastSessionPage);