import Axios from "../config/Axios";

export const GET_ASSETS = 'GET_ASSETS';
export const UPLOAD_ASSET = 'UPLOAD_ASSET';
export const DELETE_ASSETS = 'DELETE_ASSETS';
export const GET_STORAGE = 'GET_STORAGE';
export const FILTER_ASSETS = 'FILTER_ASSETS';
export const SEARCH_ASSETS = 'SEARCH_ASSETS';
export const SAVE_LINK = 'SAVE_LINK';
export const GET_STORAGE_INFO = 'GET_STORAGE_INFO';

const { REACT_APP_API_URL } = process.env;

const getAssets = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/storage/getStorageFiles`)
            .then(response => {
                dispatch({
                    type: GET_ASSETS,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const uploadAsset = (formData) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/storage/uploadFile`, formData)
            .then(response => {
                dispatch({
                    type: UPLOAD_ASSET,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const deleteAssets = (asset) => {
    return (dispatch) => {
        return Axios.delete(`${REACT_APP_API_URL}/storage/deleteFile`, {
            data: { file: asset }
        })
            .then(response => {
                dispatch({
                    type: DELETE_ASSETS,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const getStorage = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/storage/getUserStorage`)
            .then(response => {
                dispatch({
                    type: GET_STORAGE,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const filterAssets = (filter) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_ASSETS,
            payload: filter
        });
    };
};

const searchAssets = (search) => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_ASSETS,
            payload: search
        });
    };
};

const saveLink = (url) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/storage/uploadLink`, { url })
            .then(response => {
                dispatch({
                    type: SAVE_LINK,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const getStorageInfo = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/storage/getStorageInfo`)
            .then(response => {
                dispatch({
                    type: GET_STORAGE_INFO,
                    payload: response.data.response
                });
                return response.data.response;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

export {
    getAssets,
    uploadAsset,
    deleteAssets,
    getStorage,
    filterAssets,
    searchAssets,
    saveLink,
    getStorageInfo
};