import Axios from "../config/Axios";

export const GET_PRODUCT_METRIC = "GET_PRODUCT_METRIC";
export const GET_REPORT_USER = "GET_REPORT_USER";
export const GET_ASSESSMENT = "GET_ASSESSMENT";
export const FILTER_METRIC_USER = "FILTER_METRIC_USER";
export const SORT_REPORT_BY_USERS = "SORT_REPORT_BY_USERS";
export const SORT_REPORT_USER = "SORT_REPORT_USER";
export const GET_METRIC_INITIAL_DATA = "GET_METRIC_INITIAL_DATA";
export const GET_REPORTS_BY_GROUP_PRODUCT = "GET_REPORTS_BY_GROUP_PRODUCT";
export const GET_SESSION = "GET SESSION";
export const GET_GENERAL_REPORT = "GET_GENERAL_REPORT";
export const GET_REPORTS_BY_PRODUCT = "GET_REPORTS_BY_PRODUCT";

const { REACT_APP_API_URL } = process.env;

const getReportsByGroupProduct = (data) => {
  return (dispatch) => {
    return Axios.get(
      `${REACT_APP_API_URL}/metrics/reportsByGroupProduct/${data.idProduct}/${data.idCourse}}`
    )
      .then((response) => {
        dispatch({
          type: GET_REPORTS_BY_GROUP_PRODUCT,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error);
      });
  };
};

const getProductMetric = () => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/metrics/listProducts`)
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_METRIC,
          payload: response.data,
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const getReportByUser = (data) => {
  return (dispatch) => {
    return Axios.get(
      `${REACT_APP_API_URL}/metrics/reportByUsername/${data.username}/${data.idProduct}`
    ).then((response) => {
      dispatch({
        type: GET_REPORT_USER,
        payload: response.data.response,
      });
    });
  };
};

const getAssessment = (idSession) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/metrics/assessments/${idSession}`)
      .then((response) => {
        dispatch({
          type: GET_ASSESSMENT,
          payload: response.data,
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const filterUsersMetric = (str) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_METRIC_USER,
      payload: str,
    });
  };
};

const sortReportsByUsers = (sort) => {
  return (dispatch) => {
    dispatch({
      type: SORT_REPORT_BY_USERS,
      payload: sort,
    });
  };
};

const sortReportUser = (sort) => {
  return (dispatch) => {
    dispatch({
      type: SORT_REPORT_USER,
      payload: sort,
    });
  };
};

const getMetricInitialData = () => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/metrics/getInitialData`)
      .then((response) => {
        dispatch({
          type: GET_METRIC_INITIAL_DATA,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const getSession = (idSession) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/metrics/getSession/${idSession}`)
      .then((response) => {
        dispatch({
          type: GET_SESSION,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const getGeneralReport = (data) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/metrics/generalInformation`)
      .then((response) => {
        dispatch({
          type: GET_GENERAL_REPORT,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };
};

const getReportsByProduct = (data) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/metrics/reportsByProduct`, {
      params: data,
    })
      .then((response) => {
        dispatch({
          type: GET_REPORTS_BY_PRODUCT,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error);
      });
  };
};

export {
  getReportsByGroupProduct,
  getProductMetric,
  getReportByUser,
  getAssessment,
  filterUsersMetric,
  sortReportsByUsers,
  sortReportUser,
  getMetricInitialData,
  getSession,
  getGeneralReport,
  getReportsByProduct,
};
