import { useState } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import UpcomingSessionsTable from "./session_tables/upcoming_table";
import PastSessionsTable from "./session_tables/past_sessions_table";
import UpcomingTableMobile from "./session_tables_mobile/upcoming_sessions_table/upcoming_table_mobile";
import PastTableMobile from "./session_tables_mobile/past_sessions_table/past_table_mobile";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import btnIcon from "../../assets/icons/Session_Button_Icon.png";
import icon from "../../assets/icons/Sessions_Icon.png";
import Button from "../../components/Button";
import styles from "./index.module.css";

const Sessions = (prop) => {
  const { permissions, token, productsIds, user } = prop;
  const [showRightTab, setShowRightTab] = useState(false);
  const navigate = useNavigate();

  const mediaQuery = window.matchMedia("(max-width: 427px)");
  const showButton = permissions.includes(4) ? "" : "ocultar";
  return (
    <div className="box-container">
      <ArrowGoBack />
      <div className="page-wrapper">
        <div className={styles.headerContainer}>
          <img src={icon} alt="Sessions Logo" className="icon_head" />
          {user.role.toUpperCase() === "LEARNER" && productsIds.length === 0 ? (
            <div className={styles.title}>
              You do not have access to any VR Experiences
            </div>
          ) : (
            !mediaQuery.matches && (
              <div className={styles.title}>
                USE THIS CODE TO <br /> ACTIVATE YOUR VR SESSION
              </div>
            )
          )}
          <div className={styles.token}>
            {user.role.toUpperCase() === "LEARNER" && productsIds.length === 0
              ? "-----"
              : token}
          </div>

          <div className={styles.buttonFrame}>
            {(permissions.includes(5) || permissions.includes(6)) && (
              <Button
                size="medium"
                styleButton="main"
                label="CREATE GROUP SESSION"
                className={showButton}
                onClick={() => navigate("create-session")}
                image={
                  <img
                    src={btnIcon}
                    alt="create group session"
                    className={`icon_btn_1 ${styles.btnIcon}`}
                  />
                }
              />
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={showRightTab ? styles.closeTab : styles.openTab}>
            <div
              className={`${styles.buttonContainer} ${
                showRightTab && styles.notOpen
              }`}
            >
              <button
                className={`bigButton_dark ${styles.button} ${
                  showRightTab && styles.showButton
                }`}
                id="btn_upcomingSessions"
                onClick={() => setShowRightTab(false)}
              >
                UPCOMING
              </button>
            </div>
            <div className={styles.tableContainer}>
              {mediaQuery.matches ? (
                <UpcomingTableMobile />
              ) : (
                <div className="table-container">
                  {!showRightTab && <UpcomingSessionsTable />}
                </div>
              )}
            </div>
          </div>
          <div className={showRightTab ? styles.openTab : styles.closeTab}>
            <div
              className={`${styles.buttonContainer2} ${
                !showRightTab && styles.notOpen
              }`}
            >
              <button
                className={`bigButton_dark ${styles.button2} ${
                  !showRightTab && styles.showButton
                }`}
                id="btn_previousSessions"
                onClick={() => setShowRightTab(true)}
              >
                PREVIOUS
              </button>
            </div>
            <div
              className={`${styles.tableContainerRight} ${
                showRightTab && styles.right
              }`}
            >
              {mediaQuery.matches ? (
                <PastTableMobile />
              ) : (
                <div className="table-container">
                  {showRightTab && <PastSessionsTable />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.usersReducer.userLogged,
    permissions: state.usersReducer.permissions,
    token: state.manageSessionReducer.tokenSessions.Token,
    productsIds: state.productsReducer.productsIds,
  };
};

export default connect(mapStateToProps)(Sessions);
