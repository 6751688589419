import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UsersTable from './users_by_group_table.jsx';

import {
    addGroup,
    forgetUsers, getAllGroups, getGroup,
    removeUserFromGroup, updateGroup,
} from "../../../actions/manageGroupsActions.js";
import { connect } from "react-redux";
import AssignUsers from "./assign_users/assign_users.jsx";
import { useModalSelectUsers } from "../../../hooks/useModalSelectUsers.js";
import confirm from "../../../components/ConfirmModal/confirm.js";
import addUsersIcon from "../../../assets/icons/AddUsersIcon.png";
import styles from "./group_form.module.css";
import alert from "../../../components/AlertModal/alertModal.js";
import { openLoading, closeLoading } from "../../../components/loadingModal/loading.js";
import verifyText from "../../../util/validations/text.js";
import Button from "../../../components/Button/index.jsx";
import InputText from "../../../components/InputText/index.jsx";
import editIcon from "../../../assets/icons/pencil_edit_blue.png";

const CreateOrEditGroup = (props) => {
    const {
        users,
        group,
        forgetCurrentData,
        getAllGroups,
        addGroup,
        getGroup,
        updateGroup
    } = props;
    const { groupId } = useParams();
    const navigate = useNavigate();
    const [isOpen, openModal, closeModal] = useModalSelectUsers(false)
    const [groupName, setGroupName] = useState('');
    const [editTitle, setEditTitle] = useState(true);
    const [errors, setErrors] = useState([])
    const [userError, setUserError] = useState(false)

    useEffect(() => {
        forgetCurrentData();
        if (groupId) {
            getGroup(groupId).then((res) => {
                setGroupName(res.name);
            });
            setEditTitle(false);
        };
        return () => {
            getAllGroups({ column: "name", order: "asc", atribute: "groups" })
        };
    }, [groupId])

    const handleInput = (e) => {
        e.preventDefault();
        setGroupName(e.target.value);
    }
    const handleCreate = async (e) => {
        e.preventDefault();
        const formErrors = verifyText({groupName: groupName});
        if (formErrors.length > 0 || !users || (users && users.length === 0)) {
            if (!users || (users && users.length === 0)) {
                setUserError(true)
            };
            if (formErrors.length > 0) setErrors(formErrors)
            return;
        }
        try {
            const confirmation = await confirm('CONFIRM', `Are you sure you want to create the group <strong> ${groupName}? </strong>`);
            if (!confirmation) return;
            openLoading();
            await addGroup({ name: groupName, usersGroup: users, id: groupId });
            navigate('/manage-groups');
            alert('SUCCESS', 'Group has been created');
        } catch (error) {
            alert("ERROR", error.message);
        } finally {
            closeLoading();
        };
    };
    const handleEdit = async (e) => {
        e.preventDefault();
        const formErrors = verifyText({groupName: groupName});
        if (formErrors.length > 0) {
            setErrors(formErrors)
            return;
        }
        try {
            const confirmation = await confirm('CONFIRM', `Are you sure you want to edit the group <strong> ${groupName}? </strong>`);
            if (!confirmation) return;
            openLoading();
            await updateGroup({ name: groupName, idGroup: groupId });
            navigate('/manage-groups');
            alert('SUCCESS', 'Group has been edited');
        } catch (error) {
            alert("ERROR", error.message);
        } finally {
            closeLoading();
        };
    };
    const handleOpenAddUsers = (e) => {
        e.preventDefault();
        openModal();
    };
    const handleEditName = (e) => {
        e.preventDefault();
        setEditTitle(true);
    };

    const isCreating = !groupId;

    return (
        <div className={`box-container-opaque`}>
            <div className={`box-container entryPage group-box-entry ${styles.container}`}>
                <div className={`page-wrapper ${styles.wrapper}`}>
                        <div className={styles.headerContainer}>
                            <div className={styles.titleContainer}>
                                <div className={styles.close} onClick={() => navigate('/manage-groups')}/>
                                {
                                    editTitle ? (
                                        <div className={styles.inputContainer}>
                                            <InputText 
                                                type="text" 
                                                placeholder="Group Name"
                                                name="groupName"
                                                onChange={handleInput}
                                                value={groupName}
                                                errors={errors.filter(error => error.label === "groupName")}
                                            />
                                        </div>
                                    ) : (
                                        <div className={styles.title}>
                                            <div>{group.name}</div>
                                            <img src={editIcon} alt="edit name" className={styles.editIcon} onClick={handleEditName}/>
                                        </div>
                                    )
                                }
                            </div>
                            { group && group.courses && group.courses.length > 0 &&
                                <div className={`${styles.coursesContainer} `}>
                                    <span className={styles.curseLabel} >CURRENT ASSOCIATED COURSES</span>
                                    <div>
                                        {
                                            group && group.courses && group.courses.map((course, i) => {
                                                return (
                                                    <div key={i} className={styles.curseLabel}>
                                                        <Button
                                                            size="medium"
                                                            styleButton='ultralight'
                                                            label={course.name}
                                                            // disabled={true}
                                                        />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    <div className={styles.actionsContainer}>
                        <Button 
                            size="medium"
                            styleButton="main"
                            label="ADD USERS"
                            onClick={handleOpenAddUsers} 
                            image={<img src={addUsersIcon} alt="Add Users" className={styles.btnIcon}/>}
                        />
                    </div>
                    { users && users.length > 0 ?
                         <div className='table-container'>
                            <UsersTable users={users} />
                        </div>
                        : userError ?
                            <div className={`${styles.error} ${styles.emptyContainer}`}>You must include users to the group.</div>
                        :
                            <div className={styles.emptyContainer}/>
                    }
                    <div className={styles.footerActionsContainer}>
                        <Button
                            label="SAVE"
                            size="medium"
                            styleButton="light"
                            onClick={isCreating ? handleCreate : handleEdit}
                        />
                    </div>
                </div>
                { isOpen &&
                    <AssignUsers closeModal={closeModal}/>
                }
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGroup: async (groupId) => {
            return (await dispatch(getGroup(groupId)));
        },
        removeUser: async (groupId, idUser) => {
            return (await dispatch(removeUserFromGroup(groupId, idUser)));
        },
        forgetCurrentData: () => {
            dispatch(forgetUsers());
        },
        getAllGroups: async (data) => {
            dispatch(await getAllGroups(data));
        },
        addGroup: async (data) => {
            return dispatch(await addGroup(data));
        },
        updateGroup: async (data) => {
            return dispatch(await updateGroup(data));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        group: state.manageGroups.groupData,
        users: state.manageGroups.users,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditGroup);