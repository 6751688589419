import { connect } from "react-redux";
import { selectPermission, unSelectPermission } from "../../../../../actions/manageUsersActions";
import "./permission_card_table.css";

const PermissionCardTable = ({ permission, checked, selectPermission, unSelectPermission }) => {

    const handleChange = (e) => {
        if (e.target.checked === true) {
            selectPermission(permission.idPermission);
        } else if (e.target.checked === false) {
            unSelectPermission(permission.idPermission);
        }
    };
    return (
        <div className="row-per row">
            <div className="content-box box-per">
                <input type="checkbox" className={`check-per ${permission.idPermission}`} onChange={x => handleChange(x)}/>
            </div>
            <div className="content-box ultimo">
                { permission.description }
            </div>
        </div>
    );
};

export default connect(null, { selectPermission, unSelectPermission })(PermissionCardTable);