const verifyText = (obj) => {
    const errors = [];
    const regex = /^[a-zA-Z0-9]+$/;
    //regex with spaces
    const regexWithSpaces = /^[a-zA-Z0-9\s]+$/;
    // const regexUsername = /^[a-zA-Z0-9.\-+_]+$/;
    const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    // regex for text with spaces
    // const regexText = /^[a-zA-Z\s]+$/;
    if (obj.firstname || obj.firstname === "") {
        if (obj.firstname === "") {
            errors.push({label: "firstname", error: "First name is required."});
        }
        if (obj.firstname !== "" && !regex.test(obj.firstname)) {
            errors.push({label: "firstname", error: "First name must contain only letters and numbers."})
        };
        if (obj.firstname !== "" && (obj.firstname.length < 3 || obj.firstname.length > 20)) {
            errors.push({label: "firstname", error: "First name must be between 3 and 20 characters long."});
        };
    } if (obj.lastname || obj.lastname === "") {
        if (obj.lastname === "") {
            errors.push({label: "lastname", error: "Last name is required."});
        }
        if (obj.lastname !== "" && !regex.test(obj.lastname)) {
            errors.push({label: "lastname", error: "Last name must contain only letters and numbers."});
        };
        if (obj.lastname !== "" && (obj.lastname.length < 3 || obj.lastname.length > 20)) {
            errors.push({label: "lastname", error: "Last name must be between 3 and 20 characters long."});
        };
    } if (obj.birthday || obj.birthday === "") {
    } if (obj.username || obj.username === "") {
        if (obj.username === "") {
            errors.push({label: "username", error: "Username is required."});
        }
        if (obj.username !== "" && (obj.username.length < 3 || obj.username.length > 45)) {
            errors.push({label: "username", error: "Username must be between 3 and 45 characters long."});
        };
    } if (obj.roleIdRole || obj.roleIdRole === "") {
        if (obj.roleIdRole === "") {
            errors.push({label: "roleIdRole", error: "Role is required."});
        };
    } if (obj.email || obj.email === "") {
        if (obj.email === "") {
            errors.push({label: "email", error: "Email is required."});
        }
        if (obj.email !== "" && !regexEmail.test(obj.email)) {
            errors.push({label: "email", error: "Email must be a valid email address."});
        };
        if (obj.email !== "" && (obj.email.length < 3 || obj.email.length > 50)) {
            errors.push({label: "email", error: "Email must be between 3 and 50 characters long."});
        };
    } if (obj.groupName || obj.groupName === "") {
        if (obj.groupName === "") {
            errors.push({label: "groupName", error: "Group name is required."});
        }
        if (obj.groupName !== "" && !regexWithSpaces.test(obj.groupName)) {
            errors.push({label: "groupName", error: "Group name must contain only letters and numbers."});
        };
        if (obj.groupName !== "" && (obj.groupName.length < 3 || obj.groupName.length > 25)) {
            errors.push({label: "groupName", error: "Group name must be between 3 and 25 characters long."});
        };
    } if (obj.name || obj.name === "") {
        if (obj.name === "") {
            errors.push({label: "name", error: "Course name is required."});
        }
        if (obj.name !== "" && (obj.name.length < 3 || obj.name.length > 25)) {
            errors.push({label: "name", error: "Course name must be between 3 and 25 characters long."});
        };
        if (obj.name !== "" && !regexWithSpaces.test(obj.name)) {
            errors.push({label: "name", error: "Course name must contain only letters and numbers."});
        };
    } if (obj.title && obj.title === "") {
        errors.push({label: "title", error: "Session title is required."});
    } if (obj.productIdProduct && obj.productIdProduct === "") {
        errors.push({label: "productIdProduct", error: "Product is required."});
    } if (obj.courseIdCourse && obj.courseIdCourse === "") {
        errors.push({label: "courseIdCourse", error: "Course is required."});
    } if (obj.userIdUser && obj.userIdUser === "") {
        errors.push({label: "userIdUser", error: "Host is required."});
    } if (obj.mode && obj.mode === "") {
        errors.push({label: "mode", error: "Mode is required."});
    } if (obj.dateSession && obj.dateSession === "") {
        errors.push({label: "dateSession", error: "Date is required."});
    } if (obj.faculties && obj.faculties.length === 0) {
        errors.push({label: "faculties", error: "At least one faculty is required"});
    }

    return errors
};

export default verifyText;