import { useState } from 'react';
import { connect } from 'react-redux';
import { sortDevices } from '../../../actions/devicesActions';
import styles from '../index.module.css';
import TableCard from './table_card/table_card';

const DevicesTable = (props) => {
    const {
        data,
        sortDevices
    } = props;
    const [sort, setSort] = useState('desc');
    const handleSort = (e) => {
        sort === 'asc' ? setSort('desc') : setSort('asc');
        sortDevices({ order: sort, field: e});
    };
    return (
        <div className={styles.tableContainer}>
            <div className={`table-header ${styles.tableHeader}`}>
                <div className="header-box" onClick={() => handleSort("name")}>DEVICE NAME</div>
                <div className="header-box" onClick={() => handleSort("type")}>DEVICE TYPE</div>
                <div className="header-box" onClick={() => handleSort("hardwareId")}>SERIAL NUMBER</div>
                <div className="header-box" onClick={() => handleSort("activationDate")}>ACTIVATION DATE</div>
                <div className="header-box" onClick={() => handleSort("lastLogin")}>LAST ACCESSED</div>
            </div>
            <div className={`table-rows ${styles.rows}`}>
                {
                    data.map((x, i) => {
                        return (
                            <TableCard key={x} data={x} color={i % 2 === 0 && 'dark'} />
                        )
                    })
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        data: state.devicesReducer.devices
    };
};

export default connect(mapStateToProps, { sortDevices })(DevicesTable);