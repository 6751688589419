import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GroupsByCourseTable from "./groups_by_course_table.jsx";
import { listProducts } from "../../../actions/productsActions.js";
import {
  createCourse,
  getCourse,
  getCourses,
  updateCourse,
  clearCourseInfo,
} from "../../../actions/manageCoursesActions.js";
import { connect } from "react-redux";
import confirm from "../../../components/ConfirmModal/confirm.js";
import AssignGroups from "./assign_groups/assign_groups.jsx";
import { useModalSelectGroups } from "../../../hooks/useModalSelectGroups.js";
import addGroupsIcon from "../../../assets/icons/btnIconGroups.png";
import styles from "./course_form.module.css";
import alert from "../../../components/AlertModal/alertModal.js";
import { getUsersByClient } from "../../../actions/usersActions.js";
import {
  closeLoading,
  openLoading,
} from "../../../components/loadingModal/loading.js";
import verifyText from "../../../util/validations/text.js";
import InputText from "../../../components/InputText/index.jsx";
import Button from "../../../components/Button/index.jsx";
import DatePicker from "../../../components/DatePicker/index.jsx";
import { Grid } from "@mui/material";
import MaterialSelect from "../../../components/MaterialSelect/index.jsx";

const CreateOrEditCourse = (props) => {
  const {
    getCourse,
    products,
    users,
    teacherAssistants,
    groups,
    getCourses,
    listProducts,
    getUsersByClient,
    createCourse,
    updateCourse,
    courseData,
    clearCourseInfo,
  } = props;
  let initialData = {
    name: "",
    productIdProduct: "",
    faculties: [],
    teacherAssistants: [],
    startDate: null,
    endDate: null,
    groups: [],
    groupsOrder: "asc",
  };

  const [formData, setFormData] = useState(courseData || initialData);
  const [errors, setErrors] = useState([]);
  const { courseId } = useParams();
  const [isOpen, openModal, closeModal] = useModalSelectGroups(false);
  const navigate = useNavigate();
  const getInitialData = async () => {
    openLoading();
    try {
      if (courseId) {
        await getCourse({ idCourse: courseId });
      } else {
        setFormData(initialData);
      }
      await listProducts({ active: true });
      await getUsersByClient({ courseOwner: true });
      await getUsersByClient({ courseTA: true });
    } catch (error) {
      alert("ERROR", error.message);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (courseData && courseData.name) {
      setFormData({
        ...courseData,
        startDate: courseData.startDate ? new Date(courseData.startDate) : "",
        endDate: courseData.endDate ? new Date(courseData.endDate) : "",
        faculties: courseData.faculties.map((user) => user.idUser),
        teacherAssistants: courseData.teacherAssistants.map(
          (user) => user.idUser
        ),
      });
    }
  }, [courseData]);

  const handleOpenAddGroups = (e) => {
    e.preventDefault();
    openModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelect = (e) => {
    const {
      target: { value },
    } = e;

    setFormData({
      ...formData,
      productIdProduct: value,
    });
  };

  const handleSelectFaculties = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      faculties: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleSelectTAs = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      teacherAssistants: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleSave = async () => {
    let data = {
      ...formData,
      groupsToAdd: groups,
      startDate: formData.startDate || null,
      endDate: formData.endDate || null,
    };

    // verify if the data is correct in this case the text name
    const formErrors = verifyText(data);
    if (formErrors.length > 0) {
      setErrors(formErrors);
      return;
    }
    const confirmation = await confirm(
      "CONFIRM",
      "Are you sure you want to save the changes?"
    );
    if (!confirmation) return;
    openLoading();
    try {
      if (courseId) {
        await updateCourse(data);
      } else {
        await createCourse(data);
      }
      clearCourseInfo();
      getCourses({
        column: "name",
        order: "asc",
        atribute: "courses",
      });
      navigate("/manage-courses");
      alert("SUCCESS", `Course has been ${courseId ? "updated" : "created"}`);
    } catch (error) {
      alert("ERROR", error.message);
    } finally {
      closeLoading();
    }
  };

  let owners = users.map((user) => {
    return {
      id: user.idUser,
      label: `${user.firstname} ${user.lastname}`,
    };
  });

  let tAs = teacherAssistants
    ? teacherAssistants.map((user) => {
        return {
          id: user.idUser,
          label: `${user.firstname} ${user.lastname}`,
        };
      })
    : [];

  let formattedProducts = products.map((product) => {
    return {
      id: product.idProduct,
      label: product.name,
    };
  });

  return (
    <div className={`box-container-opaque`}>
      <div className={`entryPage box-entry ${styles.container}`}>
        <div className={`page-wrapper ${styles.wrapper}`}>
          <div className={styles.headerContainer}>
            <div
              className={styles.close}
              onClick={() => {
                clearCourseInfo();
                navigate("/manage-courses");
              }}
            />
          </div>
          <form className={styles.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputText
                  type="text"
                  name="name"
                  placeholder="Course Name"
                  value={formData.name}
                  onChange={handleChange}
                  errors={errors.filter((error) => error.label === "name")}
                />
              </Grid>
              <Grid item xs={4}>
                <MaterialSelect
                  formField="productIdProduct"
                  label="Product"
                  value={formData.productIdProduct || ""}
                  onChange={handleSelect}
                  options={formattedProducts}
                />
              </Grid>
              <Grid item xs={4}>
                <MaterialSelect
                  multiple
                  label="Course Owner(s)"
                  formField="faculties"
                  value={formData.faculties || []}
                  onChange={handleSelectFaculties}
                  options={owners}
                  errors={errors.filter((error) => error.label === "faculties")}
                />
              </Grid>
              <Grid item xs={4}>
                <MaterialSelect
                  multiple
                  formField="teacherAssistants"
                  label="Course TA(s)"
                  value={formData.teacherAssistants || []}
                  onChange={handleSelectTAs}
                  options={tAs}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  name="startDate"
                  selected={formData.startDate}
                  onChange={handleChange}
                  placeholder="Start Date"
                  format="MMM DD, YYYY"
                  hideTime={true}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  name="endDate"
                  selected={formData.endDate}
                  onChange={handleChange}
                  placeholder="End Date"
                  format="MMM DD, YYYY"
                  hideTime={true}
                />
              </Grid>
            </Grid>
          </form>
          <div className={styles.actionsContainer}>
            <div className={styles.buttons}>
              <Button
                size="medium"
                styleButton="main"
                label="ADD GROUPS"
                onClick={handleOpenAddGroups}
                image={
                  <img
                    src={addGroupsIcon}
                    alt="Add Groups"
                    className={styles.btnIcon}
                  />
                }
              />
            </div>
          </div>
          {groups && groups.length > 0 ? (
            <div className="table-container">
              <GroupsByCourseTable groups={groups} />
            </div>
          ) : (
            <div className={styles.emptyContainer} />
          )}
          <div className={styles.footerActionsContainer}>
            <Button
              onClick={handleSave}
              size="medium"
              styleButton="light"
              label="SAVE"
            />
          </div>
        </div>
        {isOpen && <AssignGroups closeModal={closeModal} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    courseData: state.manageCourses.courseData,
    products: state.productsReducer.allProducts,
    users: state.usersReducer.usersList,
    teacherAssistants: state.usersReducer.teacherAssistantList,
    groups: state.manageCourses.groups,
  };
};

export default connect(mapStateToProps, {
  listProducts,
  getCourse,
  getUsersByClient,
  createCourse,
  updateCourse,
  clearCourseInfo,
  getCourses,
})(CreateOrEditCourse);
