import { useState } from "react";
import { connect } from "react-redux";
import { getDeviceDetails } from "../../../actions/devicesActions";
import TableCard from "./table_card/table_card";
import { useEffect } from "react";
import closeIcon from "../../../assets/icons/X.png";
import styles from "./device_details_table.module.css";

const DeviceDetailsTable = (props) => {
    const {
        getData,
        data,
        open,
        close,
        deviceId
    } = props
    const [order, setOrder] = useState({
        order: 'asc',
        column: 'name',
        deep: 'products'
    });
    const handleSort = (column, deep) => {
        if (order.order === "asc") {
            getData(deviceId, { order: "desc", column: column, deep: deep });
            setOrder({ order: "desc", column: column, deep: deep });
        } else {
            getData(deviceId, { order: "asc", column: column, deep: deep });
            setOrder({ order: "asc", column: column, deep: deep });
        };
    };
    useEffect(() => {
        getData(deviceId, { order: "asc", column: order.column, deep: order.deep });
    }, []);
    return (
            <div className={`${styles.contenedor} ${open && styles.open}`}>
                <div className={styles.closeCon}>
                    <img src={closeIcon} alt="Close" className={styles.closeIcon} onClick={close}/>
                </div>
                <div className={`table-header ${styles.tableHeader}`}>
                    <div className={`header-box ${styles.header}`} onClick={() => handleSort('name', 'products')}>NAME</div>
                    <div className={`header-box ${styles.header}`}>EXPIRATION DATE</div>
                </div>
                <div className={`table-rows ${styles.tableRows}`}>
                    {
                        data.licenses.map((x, i) => {
                            return (
                                <TableCard key={x.idExecutable} data={x} color={i % 2 === 0 && 'dark'}/>
                            );
                        })
                    }
                </div>
            </div>
    );
};

const mapStateToProps = state => {
    return {
        data: state.devicesReducer.deviceDetails
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getData: (deviceId, order) => dispatch(getDeviceDetails(deviceId, order))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsTable);