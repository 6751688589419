import { useEffect } from 'react';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';


const MaintenanceView = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!props.user.outOfService) {
            navigate('/');
        };
    }, [props.user.outOfService]);
  return (
    <div className={styles.container}>
        <div className={styles.content}>
            <div className >
                <h1>404!!!</h1>
                <h2>Sorry for the inconvenience! We're sprucing things up to make your experience even better. Stay tuned, we'll be back shortly!</h2>
            </div>
            <div className={styles.image} />                
        </div>

    </div>
  );
};

const mapStateToProps = (state) => {
    return {
        user: state.usersReducer.userLogged
    };
};

export default connect(mapStateToProps)(MaintenanceView);
