import Axios from "../config/Axios";

export const ADD_DEVICE = 'ADD_DEVICE';
export const GET_USER_DEVICES = 'GET_USER_DEVICES';
export const GET_DEVICE_DETAILS = 'GET_DEVICE_DETAILS';
export const GET_DEVICES = 'GET_DEVICES';
export const GET_PLATFORMS = 'GET_PLATFORMS';
export const GET_DEVICE = 'GET_DEVICE';
export const SORT_DEVICES = 'SORT_DEVICES';
export const FILTER_DEVICES = 'FILTER_DEVICES';
export const SORT_DEVICE = 'SORT_DEVICE';
export const FILTER_DEVICE = 'FILTER_DEVICE';

// url
const { REACT_APP_API_URL } = process.env;

const addDevice = (dataDevice) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/devices/addDevice`, dataDevice)
            .then(response => {
                if (response.data.newDevice) {
                    dispatch({
                        type: ADD_DEVICE,
                        payload: response.data.newDevice
                    });
                };
            })
            .catch(error => {
                throw new Error(error);
            })
    };
};

const getUserDevices = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/devices/listDevicesByUser`)
        .then(response => {
                if (response.data.userDevices) {

                    dispatch({
                        type: GET_USER_DEVICES,
                        payload: response.data.userDevices
                    });
                };
            })
            .catch(error => {
                throw new Error(error);
            })
    };
};

const getDeviceDetails = (deviceId, { order, column, deep }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/productLicenses/deviceDetails/${deviceId}`, {
            params: {
                order,
                column,
                deep
            }
        })
            .then(response => {
                dispatch({
                    type: GET_DEVICE_DETAILS,
                    payload: response.data.deviceExecutable
                });
            })
            .catch(error => {
                throw new Error(error);
            })
    };
};

const getDevices = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/devices/listDevices`)
        .then(response => {
            dispatch({
                type: GET_DEVICES,
                payload: response.data
            });
        })
        .catch(error => {
            throw new Error(error);
        });
    };
};

const getPlatforms = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/platforms/listPlatforms`)
        .then(response => {
            dispatch({
                type: GET_PLATFORMS,
                payload: response.data
            });
        })
        .catch(error => {
            throw new Error(error);
        });
    };
};

const getDevice = (idDevice) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/devices/getDevice/${idDevice}`)
        .then(response => {
            dispatch({
                type: GET_DEVICE,
                payload: response.data
            });
        })
        .catch(error => {
            throw new Error(error);
        });
    };
};

const sortDevices = (field) => {
    return (dispatch) => {
        dispatch({
            type: SORT_DEVICES,
            payload: field
        });
    }
};

const filterDevices = (str) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_DEVICES,
            payload: str
        });
    };
};

const sortDevice = (field) => {
    return (dispatch) => {
        dispatch({
            type: SORT_DEVICE,
            payload: field
        });
    };
};

const filterDevice = (str) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_DEVICE,
            payload: str
        });
    };
};

export {
    addDevice,
    getUserDevices,
    getDeviceDetails,
    getDevices,
    getPlatforms,
    getDevice,
    sortDevices,
    filterDevices,
    sortDevice,
    filterDevice
}