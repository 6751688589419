import Axios from "../config/Axios";

// URL API
const { REACT_APP_API_URL } = process.env;

const logUser = async (credentials) => {
    try {
        const result = await Axios.post(`${REACT_APP_API_URL}/users/logUser`, {
            password: credentials.password,
            username: credentials.username
        });
        if (result) {
            const token = result.data.response.jwt;
            if (token) {
                localStorage.setItem("token", token);
            };
            return result.data.response
        }
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

const stepTwo = async (credentials) => {
    try {
        const result = await Axios.post(`${REACT_APP_API_URL}/users/logUserStepTwo`, {
            password: credentials.password,
            username: credentials.username,
            code: credentials.code.toUpperCase()
        })
        const token = result.data.response.jwt;
        if (token) {
            localStorage.setItem("token", token);
        };
        return result.data.response
    } catch (error) {
        throw new Error(error.response.data.message);
    };
};


export {
    logUser,
    stepTwo
};         