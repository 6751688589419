import styles from "../index.module.css";

const PlatformsData = (props) => {
    const {
        text,
        num
    } = props
    return (
        <div className={styles.platformDataBox}>
            <div className={styles.platform}>{text}</div>
            <div className={styles.numPlatform}>{num}</div>
        </div>
    );
};

export default PlatformsData;