import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import devicesIcon from "../../assets/box/devicesIconBlue.png";
import DevicesTable from "./device_table/device_table";
import { connect } from "react-redux";
import { filterDevices, getDevices, getPlatforms } from "../../actions/devicesActions";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import PlatformsData from "./platforms_data/platforms_data";
import styles from "./index.module.css";


const Template = (props) => {
    const {
        getDevices,
        getPlatforms,
        devices,
        platforms,
        filterDevices
    } = props;
    useEffect(() => {
        getDevices();
        getPlatforms();
    }, [])
    const handleSearch = (e) => {
        filterDevices(e.target.value);
    };
    return (
        <div className={`box-container ${styles.containerMayor}`}>
            <ArrowGoBack/>
            <div className={styles.infoContainer}>
                <img src={devicesIcon} alt="Devices Icon" className="icon_head"/>
                <div className="total_circle_1">
                    <div className="total_circle_1_number">
                        {devices.length}
                    </div>
                    <span className="total_circle_1_title">TOTAL</span>
                </div>
                <div className={styles.platformsCon}>
                    {
                        platforms.map((p, i) => {
                            return (
                                <PlatformsData key={i} text={p.name} num={p.devices.length}/>
                            )
                        })
                    }
                </div>
                <div className={styles.inputConDev}>
                    <input type="text" className='in-FLD input-search-clear' placeholder='Search' onChange={handleSearch}/>
                </div>
            </div>
            <DevicesTable />
            <Outlet/>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        devices: state.devicesReducer.devices,
        platforms: state.devicesReducer.platforms
    };
};

export default connect(mapStateToProps, { getDevices, getPlatforms, filterDevices })(Template);