
import moment from 'moment';
import styles from '../reports_table_learner.module.css';

const TableCard = (props) => {
    const {
        report,
        color
    } = props;
    const AttemptDate = moment(report.attemptDate).format("MMM DD, YYYY HH:mma");
    const timeSpent = Math.abs(report.duration / 60).toFixed(2);
    return (
        <div className={`row ${color} ${styles.row}`}>
            <div className='content-box'>{report.nameModule}</div>
            <div className='content-box'>{AttemptDate}</div>
            <div className='content-box'>{timeSpent}</div>
            <div className='content-box'>{report.score}</div>
        </div>
    );
};

export default TableCard;