import { ADD_LICENSE, FILTER_LICENSES_DETAIlS, GET_LICENSES_DETAILS, TOTAL_LICENSES_ACTIVE } from "../actions/licenseAction";

var initialState = {
    licenseRegister: {},
    licensesDetails: {devices: []},
    licensesDetailsCopy: {devices: []},
    totalLicensesActive: 0
}

const licenseReducer = (state = initialState, action) => {
    switch (action.type) {
        case (ADD_LICENSE):
            return state = {
                ...state,
                licenseRegister: action.payload
            };
        case (GET_LICENSES_DETAILS):
            return state = {
                ...state,
                licensesDetails: action.payload,
                licensesDetailsCopy: action.payload
            };
        case (FILTER_LICENSES_DETAIlS):
            return state = {
                ...state,
                licensesDetails: {...state.licensesDetailsCopy, devices: state.licensesDetailsCopy.devices.filter(device => {
                    return device.name.toLowerCase().includes(action.payload.toLowerCase()) ||
                    device.platform.name.toLowerCase().includes(action.payload.toLowerCase())
                })}
            };
        case (TOTAL_LICENSES_ACTIVE):
            return state = {
                ...state,
                totalLicensesActive: action.payload
            };
        default: return state;
    }
}

export default licenseReducer;