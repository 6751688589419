
import { useState } from "react";
import styles from "./reset_password_modal.module.css";
import InputText from "../../../../components/InputText";
import ModalPage from "../../../../components/modalPage-base/ModalPage";
import alert from "../../../../components/AlertModal/alertModal";
import { connect } from "react-redux";
import { resetPassword } from "../../../../actions/manageUsersActions";


const ResetPasswordModal = ({ show, handleClose, user, resetPassword }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setError('');
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        } else {
            setConfirmPassword(e.target.value);
        }
    };
    
    const handleCloseModal = () => {
        setPassword('');
        setConfirmPassword('');
        setError('');
        handleClose();
    };

    const handleResetPassword = async () => {
        if (password === '' || confirmPassword === '') {
            setError('All fields are required');
            return;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        setLoading(true);
        try {
            await resetPassword(user.idUser, password);
            alert('SUCCESS', 'Password reset successfully', 'highImportance');
            handleCloseModal();
        } catch (error) {
            setError(error.message);
        }
        setLoading(false);
    };

    return (
        <ModalPage isOpen={show} handleClose={handleClose}>
            <div className={styles.container}>
                <form action="submit" className={styles.form}>
                    <InputText
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="input_two"
                        value={password}
                        onChange={handleChange}
                    />
                    <InputText
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        className="input_two"
                        value={confirmPassword}
                        onChange={handleChange}
                    />
                </form>
                <div>
                    {error && <p className={styles.error}>{error}</p>}
                </div>
                <div className={styles.buttonContainer}>
                    <button className={styles.button} onClick={handleResetPassword} disabled={loading}>
                        {loading ? 'Loading...' : 'Update Password'}
                    </button>
                    <button className={styles.button} onClick={handleCloseModal} disabled={loading}>
                        Cancel
                    </button>
                </div>
            </div>
        </ModalPage>
    );
};

export default connect(null, { resetPassword })(ResetPasswordModal);