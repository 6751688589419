import styles from './porcentage_chart.module.css';

const PorcentageChart = (props) => {
    const {
        quantity,
        total
    } = props;
    const porcentage = Math.round((quantity * 100) / total);
    return (
        <div className={`${styles.progresBar} ${styles[`porcent--${porcentage}`]}`}>
            <div className={styles.intoProgresBar}>
                <div className={styles.txtT}>{quantity}</div> <div className={styles.txtB}>/</div> <div className={styles.txt}>{total}</div>
            </div>
        </div>
    );
};

export default PorcentageChart;