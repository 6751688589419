import { connect } from 'react-redux';
import styles from '../index.module.css';
import { getProductLicenses } from '../../../actions/productsActions';
import { useEffect } from 'react';
import arrowIcon from '../../../assets/icons/ArrowOrange.png';
import moment from 'moment';


const TableMobile = (props) => {
    const {
        products, 
        getProductLicenses,
        nextExpirations
    } = props;
    useEffect(() => {
        getProductLicenses({ order: 'asc', column: 'name' });
    }, []);
    return (
        <div className={styles.table}>
            {
                products.map((license, i) => {
                    const startDate = moment(license.dateStart);
                    const endDate = moment(license.dateEnd);
                    const handleOpenClose = () => {
                        let currentIsOpen = false;
                        const arrowIcon = document.getElementById(`arrowIcon_licenses_${license.idLicense}`);
                        // document.querySelectorAll(`.${styles.boxContent}`).forEach(x => {
                        //     if (x.id === `license_${license.idLicense}` && x.classList.contains(styles.active)) currentIsOpen = true;
                        //     x.classList.remove(styles.active);
                        // });
                        // document.querySelectorAll(`.${styles.arrow}`).forEach(x => x.classList.remove(styles.active));
                        document.getElementById(`license_${license.idLicense}`).classList.toggle(styles.active);
                        arrowIcon.classList.toggle(styles.active);
                    };
                    return (
                        <div key={license.idLicense} className={`${styles.box} ${i % 2 === 0 && styles.dark}`}>
                            <div className={styles.boxHeader} onClick={handleOpenClose}>
                                <div className={styles.left}>
                                    <div className={styles.content}>
                                        <div>{license.product.name}</div>
                                        <span>Installations:</span>
                                        <span>{`${license.asignateDevices}/${license.numDevices}`}</span>
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.datesContainer}>
                                        <div className={styles.dateCon}>
                                            <span>Start Date:</span>
                                            <div className={styles.dateBox}>
                                                <div className={styles.topBox}>{startDate.format('YYYY')}<div>/{startDate.format('MMM').toUpperCase()}</div></div>
                                                <div className={styles.day} >{startDate.format('DD')}</div>
                                                <div className={styles.bottomBox}>{startDate.format('ddd').toUpperCase()}</div>
                                            </div>
                                        </div>
                                        -
                                        <div className={styles.dateCon}>
                                            <span>End Date:</span>
                                            <div className={styles.dateBox}>
                                                <div className={styles.topBox}>{endDate.format('YYYY')}<div>/{endDate.format('MMM').toUpperCase()}</div></div>
                                                <div className={styles.day} >{endDate.format('DD')}</div>
                                                <div className={styles.bottomBox}>{endDate.format('ddd').toUpperCase()}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src={arrowIcon} alt="show" className={styles.arrow} id={`arrowIcon_licenses_${license.idLicense}`}/>
                                </div>
                            </div>
                            <div className={styles.boxContent} id={`license_${license.idLicense}`}>
                                <div className={styles.left}>
                                    <div>
                                        <span className={styles.subtitle}>Device Name: </span> <span>{license.deviceName}</span>
                                    </div>
                                    <div>
                                        <span className={styles.subtitle}>Device Type: </span> <span></span>
                                    </div>
                                    <div>
                                        <span className={styles.subtitle}>Serial Number: </span> <span></span>
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.activationDate}>
                                        <span>Activation Date:</span>
                                        <div className={`${styles.dateBox} ${styles.clear}`}>
                                            <div className={styles.topBox}>{endDate.format('YYYY')}<div>/{endDate.format('MMM').toUpperCase()}</div></div>
                                            <div className={styles.day} >{endDate.format('DD')}</div>
                                            <div className={styles.bottomBox}>{endDate.format('ddd').toUpperCase()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        products: state.productsReducer.productsLicenses,
        nextExpirations: state.productsReducer.nextExpirations
    }
};

export default connect(mapStateToProps, { getProductLicenses } )(TableMobile);