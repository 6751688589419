import {
    GET_TOKEN,
    GET_TOKEN_STATUS,
    GET_SESSIONS,
    SET_SESSION_INFO,
    NEW_SESSION,
    GET_USERS_GROUP,
    GET_UPCOMING_SESSIONS,
    GET_ROOMNUMBER,
    SORT_SESSIONS,
    GET_SESSION,
    SET_SESSION_DATA,
    SET_TIME_SESSION_TOKEN,
    GET_ONGOING_SCHEDULED,
} from "../actions/manageSessionsAction";

var initialState = {
    tokenSessions: [],
    tokenSessionsTime: 0,
    tokenStatus: [],
    sessions: {
        sessionAccess: [],
        upcomingSessions: [],
        pastSessions: [],
    },
    sessionsCopy: {
        sessionAccess: [],
        upcomingSessions: [],
        pastSessions: [],
    },
    roomNumber: '',
    sessionsInfo: [],
    newSession: [],
    getUsersGroup: [],
    upcomingSessions: [],
    session: {
        users: [],
        product: {},
    },
    ongoingScheduled: { ongoin: 0, scheduled: 0}
}

const manageSessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_TOKEN):
            return state = {
                ...state,
                tokenSessions: action.payload
            };
        case (SET_TIME_SESSION_TOKEN):
            return state = {
                ...state,
                tokenSessionsTime: action.payload
            };
        case (GET_TOKEN_STATUS):
            return state = {
                ...state,
                tokenSessions: {...state.tokenSessions, Token: action.payload}
            }
        case (GET_SESSIONS):
            return state = {
                ...state,
                sessions: action.payload,
                sessionsCopy: action.payload
            };
        case (SORT_SESSIONS):
            let upcomingSessions = [];
            let pastSessions = [];
            if (action.payload.field === 'product') {
                upcomingSessions = state.sessions.upcomingSessions.sort((a, b) => {
                    if (a.product.name.toLowerCase() < b.product.name.toLowerCase()) return -1;
                    if (a.product.name.toLowerCase() > b.product.name.toLowerCase()) return 1;
                    return 0;
                });
                pastSessions = state.sessions.pastSessions.sort((a, b) => {
                    if (a.product.name.toLowerCase() < b.product.name.toLowerCase()) return -1;
                    if (a.product.name.toLowerCase() > b.product.name.toLowerCase()) return 1;
                    return 0;
                });
            } else {
                upcomingSessions = state.sessions.upcomingSessions.sort((a, b) => {
                    if (a[action.payload.field].toLowerCase() < b[action.payload.field].toLowerCase()) return -1;
                    if (a[action.payload.field].toLowerCase() > b[action.payload.field].toLowerCase()) return 1;
                    return 0;
                });
                pastSessions = state.sessions.pastSessions.sort((a, b) => {
                    if (a[action.payload.field].toLowerCase() < b[action.payload.field].toLowerCase()) return -1;
                    if (a[action.payload.field].toLowerCase() > b[action.payload.field].toLowerCase()) return 1;
                    return 0;
                });
            };
            if (action.payload.order === "desc") { 
                upcomingSessions.reverse()
                pastSessions.reverse()
            };
            return state = {
                ...state,
                sessions: {
                    ...state.sessions,
                    upcomingSessions: upcomingSessions,
                    pastSessions: pastSessions
                }
            };
        case (SET_SESSION_INFO):
            return state = {
                ...state,
                sessionsInfo: action.payload
            };
        case (NEW_SESSION):
            return state = {
                ...state,
                newSession: action.payload
            }
        case (GET_USERS_GROUP):
            return state = {
                ...state,
                getUsersGroup: action.payload
            };
        case (GET_UPCOMING_SESSIONS):
            return state = {
                ...state,
                upcomingSessions: action.payload
            };
        case (GET_ROOMNUMBER):
            return state = {
                ...state,
                roomNumber: action.payload
            };
        case (GET_SESSION):
        case (SET_SESSION_DATA):
            return state = {
                ...state,
                session: action.payload
            };
        case (GET_ONGOING_SCHEDULED):
            return state = {
                ...state,
                ongoingScheduled: action.payload
            };
        default:
            return state;
    }
}

export default manageSessionReducer;
