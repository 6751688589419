import Axios from "../config/Axios";

export const ADD_LICENSE = 'ADD_LICENSE';
export const GET_LICENSES_DETAILS = 'GET_LICENSES_DETAILS';
export const FILTER_LICENSES_DETAIlS = 'FILTER_LICENSES_DETAIlS';
export const TOTAL_LICENSES_ACTIVE = 'TOTAL_LICENSES_ACTIVE';

// API URL
const { REACT_APP_API_URL } = process.env;

const addLicense = (dataLicense) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/license/addLicense`, dataLicense)
            .then(response => {
                if (response.data.newLicense) {
                    dispatch({
                        type: ADD_LICENSE,
                        payload: response.data.newLicense
                    });
                };
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const getLicensesDetails = (licenseId, { order, column, deep }) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/productLicenses/licenseDetails/${licenseId}`, {
            params: {
                order,
                column,
                deep
            }
        })
            .then(response => {
                dispatch({
                    type: GET_LICENSES_DETAILS,
                    payload: response.data.licenseDevices
                });
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};

const filterLicensesDetails = (str) => {
    return (dispatch) => {
        dispatch({
            type: FILTER_LICENSES_DETAIlS,
            payload: str
        });
    };
};

const totalLicensesActive = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/licenses/totalLicensesActive`)
            .then(response => {
                dispatch({
                    type: TOTAL_LICENSES_ACTIVE,
                    payload: response.data.data
                });
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            });
    };
};


export {
    addLicense,
    getLicensesDetails,
    filterLicensesDetails,
    totalLicensesActive
}