import moment from "moment";
import Axios from "../config/Axios";

export const GET_TOKEN = "GET_TOKEN";
export const GET_TOKEN_STATUS = "GET_TOKEN_STATUS";
export const GET_SESSIONS = "GET_SESSIONS";
export const SORT_SESSIONS = "SORT_SESSIONS";
export const SET_SESSION_INFO = "SET_SESSION_INFO";
export const NEW_SESSION = "NEW_SESSION";
export const GET_USERS_GROUP = "GET_USERS_GROUP";
export const DELETE_SESSION = "DELETE_SESSION";
export const GET_UPCOMING_SESSIONS = "GET_UPCOMING_SESSIONS";
export const GET_ROOMNUMBER = "GET_ROOMNUMBER";
export const GET_SESSION = "GET_SESSION";
export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const SET_TIME_SESSION_TOKEN = "SET_TIME_SESSION_TOKEN";
export const GET_ONGOING_SCHEDULED = "GET_ONGOING_SCHEDULED";
export const CREATE_SESSION = "CREATE_SESSION";


const { REACT_APP_API_URL } = process.env;

const generateToken = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/experiences/generateToken`)
            .then((response) => {
                dispatch({
                    type: GET_TOKEN,
                    payload: response.data
                });
            })
            .catch((error) => {
                throw new Error(error)
            });
    }
};

const setTimeSessionToken = (time) => {
    return (dispatch) => {
        dispatch({
            type: SET_TIME_SESSION_TOKEN,
            payload: time
        });
    };
};

const getSessions = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageSession/sessions`)
            .then((response) => {
                dispatch({
                    type: GET_SESSIONS,
                    payload: response.data.data
                });
            })
            .catch((error) => {
                throw new Error(error.response.data.message)
            });
    };
};

const sortSessions = (sort) => {
    return (dispatch) => {
        dispatch({
            type: SORT_SESSIONS,
            payload: sort
        });
    };
};

const setSessionInfo = (dataSession) => {
    return (dispatch) => {
        dispatch({
            type: SET_SESSION_INFO,
            payload: dataSession
        });
    }
};

const newSession = (dataSession) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/manageSession/newSession`, dataSession)
            .then((response) => {
                dispatch({
                    type: NEW_SESSION,
                    payload: response.data
                });
                return true;
            }).catch(error => {
                throw new Error(error.response.data.message)
            });
    };
};

const createSession = (arg) => {
    return (dispatch) => {
        return Axios.post(`${REACT_APP_API_URL}/manageSession/createSession`, arg)
            .then((response) => {
                dispatch({
                    type: CREATE_SESSION,
                    payload: response.data.response
                });
                return response.data.response;
            }).catch(error => {
                throw new Error(error.response.data.message)
            });
    };
};

const getUsersGroup = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageSession/getUsersGroup`)
            .then((response) => {
                dispatch({
                    type: GET_USERS_GROUP,
                    payload: response.data
                });
            });
    }
}
const deleteSessions = (idMultiplayer) => {
    return (dispatch) => {
        return Axios.delete(`${REACT_APP_API_URL}/manageSession/deleteSession/${idMultiplayer}`)
            .then((response) => {
                dispatch({
                    type: DELETE_SESSION,
                    payload: response.data
                });
                return true;
            })
            .catch((error) => {
                throw new Error(error.response.data.message)
            });
    };
};
const getUpcomingSessions = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageSession/upcomingSessions`)
            .then((response) => {
                dispatch({
                    type: GET_UPCOMING_SESSIONS,
                    payload: response.data.data
                });
            })
            .catch((error) => {
                throw new Error(error.response.data.message)
            });
    };
};
const getRoomNumber = (e) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageSession/createSessionCode/${e}`)
            .then((response) => {
                dispatch({
                    type: GET_ROOMNUMBER,
                    payload: response.data.data
                });
                return response.data.data;
            })
            .catch((error) => {
                throw new Error(error.response.data.message)
            });
    };
};

const getSession = (idMultiplayer) => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageSession/getSession/${idMultiplayer}`)
            .then((response) => {
                const date = response.data.response.dateSession.split("T")[0];
                const time = moment(response.data.response.dateSession).format("HH:mm");
                dispatch({
                    type: GET_SESSION,
                    payload: { ...response.data.response, date, time}
                });
                return response.data.response;
            })
            .catch((error) => {
                throw new Error(error.response.data.message)
            });
    };
};

const setSessionData = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_SESSION_DATA,
            payload: data
        });
    };
};

const getOngoingScheduled = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/manageSession/ongoingScheduled`)
            .then((response) => {
                dispatch({
                    type: GET_ONGOING_SCHEDULED,
                    payload: response.data.data
                });
            })
            .catch((error) => {
                throw new Error(error.response.data.message)
            });
    };
};

export {
    generateToken,
    setTimeSessionToken,
    getSessions,
    sortSessions,
    setSessionInfo,
    newSession,
    createSession,
    getUsersGroup,
    deleteSessions,
    getUpcomingSessions,
    getRoomNumber,
    getSession,
    setSessionData,
    getOngoingScheduled
}