import Axios from "../config/Axios";

export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";
export const UPDATE_USER = "UPDATE_USER";
export const IS_USER_AUTH = "IS_USER_AUTH";
export const GET_ALL_USERS_INFO = "GET_ALL_USERS_INFO";
export const SELECT_USER = "SELECT_USER";
export const UN_SELECT_USER = "UN_SELECT_USER";
export const CLEAR_USERS_SELECTED = "CLEAR_USERS_SELECTED";
export const GET_USERS_BY_CLIENT = "GET_USERS_BY_CLIENT";
export const GET_TA_BY_CLIENT = "GET_TA_BY_CLIENT";
export const FILTER_USERS = "FILTER_USERS";
export const ASSIGN_ROLE = "ASSIGN_ROLE";
export const BULK_LOAD = "BULK_LOAD";
export const RECOVERPASSWORD = "RECOVERPASSWORD";
export const GET_USERS = "GET_USERS";

// API URL
const { REACT_APP_API_URL } = process.env;

const createUser = (dataUser) => {
  return (dispatch) => {
    return Axios.post(`${REACT_APP_API_URL}/manageUsers/createUser`, dataUser)
      .then((response) => {
        dispatch({
          type: ADD_USER,
          payload: response.data,
        });
        return true;
      })
      .catch((error) => {
        console.log(error);
        throw new Error(error.response.data.message);
      });
  };
};

const getUser = (idUser) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/users/getUser/${idUser}`)
      .then((response) => {
        let dataUser = {
          ...response.data,
          birthday:
            response.data.birthday && response.data.birthday.split("T")[0],
        };
        dispatch({
          type: GET_USER,
          payload: dataUser,
        });
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const setUserInfo = (dataUser) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_INFO,
      payload: dataUser,
    });
  };
};

const clearUserInfo = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USER_INFO,
      payload: {},
    });
  };
};

const updateUser = (dataUser) => {
  return (dispatch) => {
    return Axios.put(`${REACT_APP_API_URL}/manageUsers/updateUser`, dataUser)
      .then((response) => {
        dispatch({
          type: UPDATE_USER,
          payload: response.data,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const isAuth = () => {
  return (dispatch) => {
    // when function loguser is called the token is stored in the localstorage but the header in axios is not set
    // Axios config is set init with a configuration before the function is called
    Axios.defaults.headers.common["authorization"] = `Bearer ${
      localStorage.getItem("token") ?? ""
    }`;
    return Axios.get(`${REACT_APP_API_URL}/users/whoAmI`)
      .then((response) => {
        if (response.data.response) {
          response.data.response.isLogged = true;
          dispatch({
            type: IS_USER_AUTH,
            payload: response.data.response,
          });
          return response.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 503) {
          dispatch({
            type: IS_USER_AUTH,
            payload: { isLogged: false, outOfService: true },
          });
          throw new Error(503);
        }
        dispatch({
          type: IS_USER_AUTH,
          payload: { isLogged: false },
        });
        throw new Error(error.response);
      });
  };
};

const getAllUsersInfo = ({ order, column, atribute }) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/manageUsers/getAllUsersInfo`, {
      params: {
        order,
        column,
        atribute,
      },
    })
      .then((response) => {
        dispatch({
          type: GET_ALL_USERS_INFO,
          payload: response.data.usersInfo,
        });
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const selectUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SELECT_USER,
      payload: user,
    });
  };
};

const unSelectUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: UN_SELECT_USER,
      payload: user,
    });
  };
};

const desactivateUsers = (users) => {
  return (dispatch) => {
    let promises = [];
    for (var i = 0; i < users.length; i++) {
      promises.push(
        Axios.put(`${REACT_APP_API_URL}/manageUsers/setUserActive`, {
          idUser: users[i],
          active: false,
        })
      );
    }
    return Promise.all(promises)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const clearUsersSelected = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_USERS_SELECTED,
      payload: [],
    });
  };
};

const getUsers = (arg) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/users/getUsers`, { params: arg })
      .then((response) => {
        dispatch({
          type: GET_USERS,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const getUsersByClient = (params = {}) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/users/usersByClient`, { params })
      .then((response) => {
        dispatch({
          type: params.courseTA ? GET_TA_BY_CLIENT : GET_USERS_BY_CLIENT,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        if (error && error.response)
          throw new Error(error.response.data.message);
      });
  };
};

const filterUsers = (str) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_USERS,
      payload: str,
    });
  };
};

const assignRole = (roleIdRole, users) => {
  return (dispatch) => {
    let promises = [];
    for (var i = 0; i < users.length; i++) {
      promises.push(
        Axios.put(`${REACT_APP_API_URL}/users/assignRole`, {
          idUser: users[i],
          roleIdRole: roleIdRole,
        })
      );
    }
    return Promise.all(promises)
      .then((e) => {
        dispatch({
          type: ASSIGN_ROLE,
          payload: roleIdRole,
        });
        return true;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};
const bulkLoad = (data) => {
  return (dispatch) => {
    return Axios.post(`${REACT_APP_API_URL}/manageUsers/bulkLoad`, data)
      .then((response) => {
        dispatch({
          type: BULK_LOAD,
          payload: response.data,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const recoverPassword = (email) => {
  return (dispatch) => {
    return Axios.post(`${REACT_APP_API_URL}/users/forgotPassword`, { email })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  };
};

const updateAvatar = (urlAvatar) => {
  return (dispatch) => {
    return Axios.put(`${REACT_APP_API_URL}/users/updateAvatar`, { urlAvatar })
      .then((response) => {
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export {
  createUser,
  getUser,
  setUserInfo,
  clearUserInfo,
  updateUser,
  isAuth,
  getAllUsersInfo,
  selectUser,
  unSelectUser,
  desactivateUsers,
  clearUsersSelected,
  getUsersByClient,
  filterUsers,
  assignRole,
  bulkLoad,
  recoverPassword,
  updateAvatar,
  getUsers,
};
