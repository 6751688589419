import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { useModalBase } from "../../hooks/useModalBase";
import UsersTable from "./user_table";
import Axios from "../../config/Axios";
import {
  desactivateUsers,
  clearUserInfo,
  clearUsersSelected,
  getUsersByClient,
} from "../../actions/usersActions";
import {
  closeLoading,
  openLoading,
} from "../../components/loadingModal/loading";
import AssignRole from "./assign_role/assign_role";
import LoadFile from "./bulk_load/load_file";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import userIcon from "../../assets/box/Users_Icon.png";
import createIcon from "../../assets/icons/Create_User_Icon.png";
import bulkIcon from "../../assets/icons/Bulk_Icon.png";
import styles from "./index.module.css";
import Button from "../../components/Button";

const { REACT_APP_API_URL } = process.env;

const TemplateUsers = (props) => {
  const { getUsersByClient, clearUserInfo, totalUsers, userList } = props;
  const navigate = useNavigate();
  const [isOpen, closeModal] = useModalBase(false);
  const [isOpenBulk, openModalBulk, closeModalBulk] = useModalBase(false);
  const openBulkModal = (e) => {
    e.preventDefault();
    openModalBulk();
  };

  const params = useParams();
  useEffect(() => {
    async function getUsers() {
      try {
        openLoading();
        await getUsersByClient();
        closeLoading();
      } catch (error) {
        alert("ERROR", error.message);
      }
    }
    getUsers();
  }, [params]);

  const activeUser = async (row) => {
    openLoading();
    try {
        await Axios.put(`${REACT_APP_API_URL}/manageUsers/setUserActive`, {active: !row.active, idUser: row.idUser});
        await getUsersByClient();
    } catch (error) {
        alert('ERROR', error.response.data.message)
    } finally {
        closeLoading();
    };
};

  return (
    <div className={`box-container ${styles.container}`}>
      <AssignRole isOpen={isOpen} closeModal={closeModal} />
      <LoadFile isOpen={isOpenBulk} closeModal={closeModalBulk} />
      <ArrowGoBack />
      <div className={styles.headerContainer}>
        {/* <button onClick={() => { clearUserInfo(); navigate("CreateUser") }} className="bas-button CR">CREATE USER</button>
                <button onClick={() => { buttonclick() }} className="bas-button CR">ROLES AND PERMISSIONS</button>
                <button onClick={(e) => openBulkModal(e)} className="bas-button CR">BULK LOAD</button>
                <button onClick={(e) => handleAssignRole(e)} className="bas-button CR">ASSIGN ROLE</button>
                <button onClick={(e) => handleDesactivate(e)} className="bas-button CR">DISABLE</button>
                <div className="con-in-SU">
                    <input type="text" className="in-FLD bar-sch-u" onChange={handleSearch} placeholder="Search"/>
                </div> */}
        <div className={styles.iconsContainer}>
          <img src={userIcon} alt="users" className="icon_head" />
          <div className="total_circle_1">
            <div className="total_circle_1_number">{totalUsers.length}</div>
            <span className="total_circle_1_title">
              TOTAL <br /> USERS
            </span>
          </div>
        </div>
        <div className={styles.headCon2}>
          <div className={styles.actionContainer}>
            <Button
              label="CREATE USER"
              size="medium"
              styleButton="main"
              onClick={() => {
                clearUserInfo();
                navigate("create-user");
              }}
              image={
                <img
                  alt="iconBtnCreateUsers"
                  src={createIcon}
                  className={`icon_btn_1 ${styles.buttonIcon}`}
                />
              }
            />
            <Button
              label="BULK UPLOAD"
              size="medium"
              styleButton="main"
              onClick={(e) => openBulkModal(e)}
              image={
                <img
                  alt="iconBtnUploadUsers"
                  src={bulkIcon}
                  className={`icon_btn_1 ${styles.buttonIcon}`}
                />
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <UsersTable users={userList} activeUser={activeUser}/>
      </div>
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userList: state.usersReducer.usersList,
    totalUsers: state.usersReducer.usersList,
  };
};

export default connect(mapStateToProps, {
  desactivateUsers,
  clearUserInfo,
  clearUsersSelected,
  getUsersByClient,
})(TemplateUsers);
