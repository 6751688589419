import { connect } from "react-redux";
import styles from "./upcoming_table_mobile.module.css";
import { deleteSessions, getSessions } from "../../../../actions/manageSessionsAction";
import { closeLoading, openLoading } from "../../../../components/loadingModal/loading";
import TableCardMobile from "./table_card/table_card_mobile";
import { useEffect } from "react";

const UpcomingTableMobile = (props) => {
    const {
        user,
        sessions,
        getSessions,
        deleteSessions,
    } = props;
    const initialData = async () => {
        openLoading();
        try {
            await getSessions();
        } catch (error) {
            alert("ERROR", error.message);
        } finally {
            closeLoading();
        };
    };
    useEffect(() => {
        initialData();
    }, []);
    const role = user.role;
    return (
        <div className={styles.table}>
            {
                sessions.map((x, i) => {
                    return (
                        <TableCardMobile
                            key={x.idMultiplayer}
                            content={x}
                            color={i % 2 === 0 && "dark"}
                            deleteSessions={deleteSessions}
                            getSessions={getSessions}
                            role={role}
                        />
                    );
                })
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sessions: state.manageSessionReducer.sessions.upcomingSessions,
        user: state.usersReducer.userLogged,
    };
};

export default connect(mapStateToProps, { getSessions, deleteSessions })(UpcomingTableMobile);