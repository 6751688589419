import { Link, useLocation, useParams } from 'react-router-dom';
import LICENSES from '../../assets/icons/navbar/Licenses_2@2x-8.png';
import LICENSESBLUE from '../../assets/icons/navbar/Licenses_3@2x-8.png';
import SETTINGS from '../../assets/icons/navbar//settings_clear.png';
import SETTINGSBLUE from '../../assets/icons/navbar/settings_blue.png';
import SESSIONS from '../../assets/icons/navbar/Sessions_1@2x-8.png';
import SESSIONSBLUE from '../../assets/icons/navbar/Sessions_2@2x-8.png';
import GRO from '../../assets/icons/navbar/Group_1@2x-8.png';
import GROUPSBLUE from '../../assets/icons/navbar/Group_2@2x-8.png';
import COURSE from '../../assets/icons/navbar/Course_1@2x-8.png';
import COURSESBLUE from '../../assets/icons/navbar/Course_2@2x-8.png';
import US from '../../assets/icons/navbar/Users_1@2x-8.png';
import USERSBLUE from '../../assets/icons/navbar/Users_2@2x-8.png';
import Mt from '../../assets/icons/navbar/Analytics_1@2x-8.png';
import Mtb from '../../assets/icons/navbar/Analytics_2@2x-8.png';
import HOME from '../../assets/icons/navbar/Home_1@2x-8.png';
import HOMEBLUE from '../../assets/icons/navbar/Home_2@2x-8.png';
import REPORTS from '../../assets/icons/navbar/Reports_Icon_navbar.png';
import REPORTSBLUE from '../../assets/icons/navbar/Reports_Icon_Blue_navbar.png';
import ASSETS from '../../assets/icons/navbar/Icon_Normal@2x-8.png';
import ASSETSBLUE from '../../assets/icons/navbar/Icon_Light@2x-8.png';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import styles from './Navbar.module.css';

const Navbar = ({ clientLogo, user }) => {
    const [act, setAct] = useState({});
    const url = useLocation().pathname.split('/');
    const path = useParams();
    const active = (e) => {
        setAct({
            [e]: true,
        });
    };
    useEffect(() => {
        if (url.includes('home')) {
            active('HOMEBLUE');
        } else if (url.includes('licenses')) {
            active('LICENSESBLUE');
        } else if (url.includes('Devices')) {
            active('DEVICESBLUE');
        } else if (url.includes('manage-sessions')) {
            active('SESSIONSBLUE');
        } else if (url.includes('manage-groups')) {
            active('GROUPSBLUE');
        } else if (url.includes('manage-courses')) {
            active('COURSESBLUE');
        } else if (url.includes('manage-users')) {
            active('USERSBLUE');
        } else if (url.includes('Analytics')) {
            active('MtA');
        } else if (url.includes('reports')) {
            active('REPORTSBLUE');
        } else if (url.includes('assets')) {
            active('ASSETSBLUE');
        } else if (url.includes('settings')) {
            active('SETTINGSBLUE')
        } else {
            active('none');
        }
    }, [path]);
    const permissions = user.permissions;
    return (
        <div className={styles.navbar}>
            <div className={styles.navLinks}>
                <div className={styles.linksContainer}>
                    <Link to="../home" className={styles.boxLinks} onClick={() => active('HOMEBLUE')}>
                        <div className={styles.iconContainer}>
                            <img className={styles.icon} src={act.HOMEBLUE ? HOMEBLUE : HOME} alt='icon' />
                        </div>
                        <span className={act.HOMEBLUE ? styles.active : styles.inactive}>HOME</span>
                    </Link>
                    {
                        (permissions.includes(11) || permissions.includes(13)) &&
                        <Link to="manage-users" className={styles.boxLinks} onClick={() => active('USERSBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.USERSBLUE ? USERSBLUE : US} alt='icon' />
                            </div>
                            <span className={act.USERSBLUE ? styles.active : styles.inactive}>USERS</span>
                        </Link>
                    }
                    {/* {
                        permissions.includes(2) &&
                        <Link to="Devices" className={styles.boxLinks} onClick={() => active('DEVICESBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.iconDev} src={act.DEVICESBLUE ? DEVICESBLUE : DEVICES} alt='icon' />
                            </div>
                            <span className={act.DEVICESBLUE ? styles.active : styles.inactive}>Devices</span>
                        </Link>
                    } */}
                    {
                        (permissions.includes(7) || permissions.includes(8)) &&
                        <Link to="manage-groups" className={styles.boxLinks} onClick={() => active('GROUPSBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.GROUPSBLUE ? GROUPSBLUE : GRO} alt='icon' />
                            </div>
                            <span className={act.GROUPSBLUE ? styles.active : styles.inactive}>GROUPS</span>
                        </Link>
                    }
                    {
                        (permissions.includes(22) || permissions.includes(23)) && // it is pending asign the correct permission
                        <Link to="manage-courses" className={styles.boxLinks} onClick={() => active('COURSESBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.COURSESBLUE ? COURSESBLUE : COURSE} alt='icon' />
                            </div>
                            <span className={act.COURSESBLUE ? styles.active : styles.inactive}>COURSES</span>
                        </Link>
                    }
                    {
                        (permissions.includes(4) || permissions.includes(3)) &&
                        <Link to="manage-sessions" className={styles.boxLinks} onClick={() => active('SESSIONSBLUE')}>
                            <div className={styles.iconContainer} >
                                <img className={styles.icon} src={act.SESSIONSBLUE ? SESSIONSBLUE : SESSIONS} alt='icon' />
                            </div>
                            <span className={act.SESSIONSBLUE ? styles.active : styles.inactive}>SESSIONS</span>
                        </Link>
                    }
                    {/* {
                        permissions.includes(7) &&
                        <Link to="Analytics" className={styles.boxLinks} onClick={() => active('MtA')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.MtA ? Mtb : Mt} alt='icon' />
                            </div>
                            <span className={act.MtA ? styles.active : styles.inactive}>ANALYTICS</span>
                        </Link>
                    } */}
                    {
                        (permissions.includes(14) || permissions.includes(15) )&&
                        <Link to="reports" className={styles.boxLinks} onClick={() => active('REPORTSBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.REPORTSBLUE ? REPORTSBLUE : REPORTS} alt='icon' />
                            </div>
                            <span className={act.REPORTSBLUE ? styles.active : styles.inactive}>REPORTS</span>
                        </Link>
                    }
                     {
                        permissions.includes(1) && 
                        <Link to="licenses" className={styles.boxLinks} onClick={() => active('LICENSESBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.LICENSESBLUE ? LICENSESBLUE : LICENSES} alt='icon' />
                            </div>
                            <span className={act.LICENSESBLUE ? styles.active : styles.inactive}>LICENSES</span>
                        </Link>
                    }
                    {
                        (permissions.includes(18) && user.has_storage) &&
                        <Link to="assets" className={styles.boxLinks} onClick={() => active('ASSETSBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.ASSETSBLUE ? ASSETSBLUE : ASSETS} alt='icon' />
                            </div>
                            <span className={act.ASSETSBLUE ? styles.active : styles.inactive}>ASSETS</span>
                        </Link>
                    }
                    {
                        (permissions.includes(21)) &&
                        <Link to="settings" className={styles.boxLinks} onClick={() => active('SETTINGSBLUE')}>
                            <div className={styles.iconContainer}>
                                <img className={styles.icon} src={act.SETTINGSBLUE ? SETTINGSBLUE : SETTINGS} alt='icon' />
                            </div>
                            <span className={act.SETTINGSBLUE ? styles.active : styles.inactive}>SETTINGS</span>
                        </Link>
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        clientLogo: state.client.logo,
        user: state.usersReducer.userLogged,
    };
};

export default connect(mapStateToProps)(Navbar);