import { ADD_DEVICE, FILTER_DEVICE, FILTER_DEVICES, GET_DEVICE, GET_DEVICES, GET_DEVICE_DETAILS, GET_PLATFORMS, GET_USER_DEVICES, SORT_DEVICE, SORT_DEVICES } from "../actions/devicesActions";

var initialState = {
    devicesRegister: {},
    userDevices: {},
    deviceDetails: {
        licenses: []
    },
    devices: [],
    devicesCopy: [],
    platforms: [],
    device: {
        platform : {},
        licenses: []
    },
    deviceCopy: {
        platform : {},
        licenses: []
    }
};

const devicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case (ADD_DEVICE):
            return state = {
                ...state,
                devicesRegister: action.payload
            };
        case (GET_USER_DEVICES):
            return state = {
                ...state,
                userDevices: action.payload
            };
        case (GET_DEVICE_DETAILS):
            return state = {
                ...state,
                deviceDetails: action.payload
            };
        case (GET_DEVICES):
            return state = {
                ...state,
                devices: action.payload,
                devicesCopy: action.payload
            };
        case (GET_PLATFORMS):
            return state = {
                ...state,
                platforms: action.payload
            };
        case (GET_DEVICE):
            return state = {
                ...state,
                device: action.payload,
                deviceCopy: action.payload
            };
        case (SORT_DEVICES):
            let array = [];
            let devices = [];
            if (action.payload.field === "type") {
                devices = state.devices.sort((a, b) => {
                    if (a.platform.name.toLowerCase() < b.platform.name.toLowerCase()) return -1;
                    if (a.platform.name.toLowerCase() > b.platform.name.toLowerCase()) return 1;
                    return 0;
                });
            } else {
                devices = state.devices.sort((a, b) => {
                    if (a[action.payload.field].toLowerCase() < b[action.payload.field].toLowerCase()) return -1;
                    if (a[action.payload.field].toLowerCase() > b[action.payload.field].toLowerCase()) return 1;
                    return 0;
                });
            }
            if (action.payload.order === "desc") {
                array = devices.reverse();
            } else {
                array = devices;
            };
            return state = {
                ...state,
                devices: array
            };
        case (FILTER_DEVICES):
            return state = {
                ...state,
                devices: state.devicesCopy.filter(device => {
                    return device.name.toLowerCase().includes(action.payload.toLowerCase()) ||
                        device.platform.name.toLowerCase().includes(action.payload.toLowerCase()) ||
                        device.hardwareId.toLowerCase().includes(action.payload.toLowerCase())
                })
            };
        case (FILTER_DEVICE):
            return state = {
                ...state,
                device: {
                    ...state.device,
                    licenses : state.deviceCopy.licenses.filter(license => {
                        // license.product.executables.filter(executable => {
                        //     return executable.bundleName.toLowerCase().includes(action.payload.toLowerCase())
                        // })
                            return license.product.name.toLowerCase().includes(action.payload.toLowerCase()) ||
                            license.product.executables.map(executable => {
                                return executable.bundleName.toLowerCase().includes(action.payload.toLowerCase())
                            }).includes(true)
                    })
                }
            };
        default: return state;
    }
};

export default devicesReducer;