import './ModalPage.css';


const ModalPage = ({ isOpen, children, importance, id }) => {
    return (
        <div className={`ModalPage-B ${isOpen && 'MP-B-open'} ${importance}`} id={id ? id : 'modal'}>
            <div className={`MP-B-box`}>
                {children}
            </div>
        </div>
    );
};

export default ModalPage;