import styles from "./BoardHeaderMobile.module.css";
import navbarIcon from "../../assets/icons/navbar/navbarIcon.png";
import whiteLine from '../../assets/icons/navbar/White_Line-8.png';
import accountIcon from "../../assets/icons/boardHeader/Profile@2x-8.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import logo from '../../assets/Xennial_Logo_White@2x-8.png';
import home from '../../assets/icons/navbar/Home_1@2x-8.png';
import homeBlue from '../../assets/icons/navbar/Home_2@2x-8.png';
import sessions from '../../assets/icons/navbar/Sessions_1@2x-8.png';
import sessionsBlue from '../../assets/icons/navbar/Sessions_2@2x-8.png';
import assets from '../../assets/icons/navbar/Icon_Normal@2x-8.png';
import assetsBlue from '../../assets/icons/navbar/Icon_Light@2x-8.png';
import licenses from '../../assets/icons/navbar/Licenses_2@2x-8.png';
import licensesBlue from '../../assets/icons/navbar/Licenses_3@2x-8.png';

const  BoardHeaderMobile = (props) => {
    const {
        firstname,
        lastname,
        role,
        clientLogo,
        permissions,
        has_storage
    } = props;
    const url = useLocation().pathname.split('/');
    const path = useParams();
    const [state, setState] = useState({
        open: false,
        navbar: false,
        icon: 'homeBlue'
    });
    const handleClickedOutside = (e) => {
        const box = document.getElementById('box_options_mobileHeader');
        if (box && !box.contains(e.target)) {
            setState({ ...state, open: false });
        };
    };
    useEffect(() => {
        if (url.includes('home')) {
            setState({ ...state, icon: 'homeBlue' });
        } else if (url.includes('manage-sessions')) {
            setState({ ...state, icon: 'sessionsBlue' });
        } else if (url.includes('Assets')) {
            setState({ ...state, icon: 'assetsBlue' });
        } else {
            setState({ ...state, icon: 'none' });
        }
        document.addEventListener('mousedown', handleClickedOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickedOutside);
        };
    }, [path]);
    const logOut = (e) => {
        localStorage.removeItem("token");
        window.location.reload();
    };
    const navigate = useNavigate();

    const handleOpenClose = () => {
        setState({ ...state, open: !state.open });
    };
    const handleOpenCloseNavbar = () => {
        setState({ ...state, navbar: !state.navbar });
    };
    const active = (e) => {
        setState({ ...state, icon: e, navbar: false });
    };
    if (state.navbar) {
        return (
            <div className={styles.container}>
                <div className={styles.navContainer}>
                    <div className={styles.logosCon} onClick={handleOpenCloseNavbar}>
                        <img src={logo} alt="xennial icon" className={styles.logo}/>
                        <img src={whiteLine} alt="line" className={styles.lineLogos}/>
                        <img src={clientLogo} alt="client logo" className={styles.logo}/>
                    </div>
                    <div className={styles.linksContainer}>
                        <Link to="../home" className={styles.link} onClick={() => active('homeBlue')}>
                            <div className={styles.iconLinkCon}>
                                <img className={styles.iconLink} src={state.icon === 'homeBlue' ? homeBlue : home} alt='icon' />
                            </div>
                            <span className={state.icon === 'homeBlue' ? styles.linkTextBlue : styles.linkText}>HOME</span>
                        </Link>
                        {
                            (permissions.includes(1)) &&
                            <Link to="Licenses" className={styles.link} onClick={() => active('licensesBlue')}>
                                <div className={styles.iconLinkCon}>
                                    <img className={styles.iconLink} src={state.icon === 'licensesBlue' ? licensesBlue : licenses} alt='icon' />
                                </div>
                                <span className={state.icon === 'licensesBlue' ? styles.linkTextBlue : styles.linkText}>LICENSES</span>
                            </Link>
                        }
                        {
                            (permissions.includes(4) || permissions.includes(3)) &&
                            <Link to="manage-sessions" className={styles.link} onClick={() => active('sessionsBlue')}>
                                <div className={styles.iconLinkCon}>
                                    <img className={styles.iconLink} src={state.icon === 'sessionsBlue' ? sessionsBlue : sessions} alt='icon' />
                                </div>
                                <span className={state.icon === 'sessionsBlue' ? styles.linkTextBlue : styles.linkText}>SESSIONS</span>
                            </Link>
                        }
                        {
                            (permissions.includes(10) && has_storage) &&
                            <Link to="assets" className={styles.link} onClick={() => active('assetsBlue')}>
                                <div className={styles.iconLinkCon}>
                                    <img className={styles.iconLink} src={state.icon === 'assetsBlue' ? assetsBlue : assets} alt='icon' />
                                </div>
                                <span className={state.icon === 'assetsBlue' ? styles.linkTextBlue : styles.linkText}>ASSETS</span>
                            </Link>
                        }
                        {/* <Link to="help" className={styles.link} onClick={() => active('helpBlue')}>
                            <div className={styles.iconLinkCon}>
                                <img className={styles.iconLink} src={helpIcon} alt='icon' />
                            </div>
                            <span className={styles.linkText}>HELP</span>
                        </Link> */}
                    </div>
                    <div className={styles.terms}>Terms & Conditions | Privacy Policy</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <img src={navbarIcon} alt="open navbar" className={styles.navbarIcon} onClick={handleOpenCloseNavbar}/>
                <div className={styles.titleCon}>
                    <div className={styles.userInfoCon}>
                        <span className={styles.name}>{firstname} {lastname}</span>
                        <span className={styles.roleName}>{role}</span>
                    </div>
                    <img className={styles.line} src={whiteLine} alt="white line" />
                    <div className={`${styles.iconGroup} ${state.open && styles.open}`} onClick={handleOpenClose}>
                        <div className={styles.iconContainer}>
                            <img src={accountIcon} alt="icon" className={styles.icon} />
                            <div className={styles.iconText}>Profile</div>
                        </div>
                        <div className={styles.box} id="box_options_mobileHeader">
                            <div className={styles.miniBox} onClick={() => navigate(`./profile`)}>EDIT</div>
                            <div className={styles.line2}></div>
                            <div className={styles.miniBox2} onClick={logOut}>LOGOUT</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        firstname: state.usersReducer.userLogged.firstname,
        lastname: state.usersReducer.userLogged.lastname,
        role: state.usersReducer.userLogged.role,
        clientLogo: state.client.logo,
        permissions: state.usersReducer.userLogged.permissions,
        has_storage: state.usersReducer.userLogged.has_storage
    };
};

export default connect(mapStateToProps, null)(BoardHeaderMobile);