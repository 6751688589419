import { connect } from "react-redux";
import styles from "./SessionDetails.module.css";
import alert from "../../../components/AlertModal/alertModal";
import {
  closeLoading,
  openLoading,
} from "../../../components/loadingModal/loading";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSession } from "../../../actions/reportsActions";
import iconX from "../../../assets/box/close_dark.png";

const SessionDetails = (props) => {
  const [html, setHtml] = useState("");
  const { idSession } = useParams();
  const navigate = useNavigate();
  const getInitialData = async () => {
    openLoading();
    try {
      const response = await props.getSession(idSession);
      setHtml(response.data.html);
    } catch (error) {
      alert("ERROR", error);
    } finally {
      closeLoading();
    }
  };
  useEffect(() => {
    getInitialData();
    // console.log(document.querySelectorAll('.row-RDS')[0].children)
    // const firstRow = document.querySelectorAll('.row-RDS')[0];
    // console.log(firstRow)
    // if (firstRow) {
    //     console.log(firstRow.children)
    // };
    // document.querySelectorAll('.row-RDS')[0].children.map((e) => {
    //     console.log(e)
    // })
  }, []);
  return (
    <div className={`box-container-opaque ${styles.boxContainerOpaque}`}>
      <div className={`box-container entryPage box-entry ${styles.container}`}>
        <img
          src={iconX}
          alt="Close Details Page"
          className={styles.x}
          onClick={() => navigate("../")}
        />
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={styles.htmlCon}
        />
      </div>
    </div>
  );
};

export default connect(null, { getSession })(SessionDetails);
