import { useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';


const Table = (props) => {
    const { 
        columns, 
        data,
        height, 
        textColor, // color of text in body
        hiddenColumns, // hide specific column by default { [columnName]: true }
        rowId, // the name of id field for the elements in data if it is different to "id"
        enableRowSelection, // Display checkboxes
        setSelectedRows, // Allow to return the rows have been selected
    } = props;
    const [rowSelection, setRowSelection] = useState({});
    const getRowStyles = ({ row, table }) => {
        return {
            sx: {
                backgroundColor: row.index % 2 === 0 ? '#dae9fb !important' : '#fff !important',
                borderRadius: '10px',
                minHeight: '3vh',
            },
        };
    };
    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnActions: false,
        enableBottomToolbar: false,
        enablePagination: false,
        enableSorting: true,
        enableHiding: true,
        enableStickyHeader: true,
        enableTopToolbar: false,
        enableFilterMatchHighlighting: false,
        getRowId: (row) => rowId ? row[rowId] : row.id,
        enableRowSelection: enableRowSelection || false,
        onRowSelectionChange: (selection)=> {
            setSelectedRows(selection)
            setRowSelection(selection)
        },
        initialState: { 
            showColumnFilters: true,
            columnVisibility: hiddenColumns,
        },
        muiTableHeadCellProps: {
            sx: {
                fontFamily: 'ProximaNova-Extrabold',
                fontSize: '1.8vh',
                color: '#192d55'
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontFamily: 'ProximaNova-Regular',
                color: `${textColor ? textColor : '#192d55'}`,
                fontWeight: 'normal',
                borderBottom: 'none',
                overflowWrap: 'anywhere',
                padding: '0.8rem'
            },
        },
        muiTableBodyRowProps: (rowIndex) => getRowStyles(rowIndex),
        muiTableContainerProps: { sx: { height } },
        state: { rowSelection }, 
    });

    return <MaterialReactTable table={table}/>;
};

export default Table;