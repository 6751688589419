
import { connect } from 'react-redux';
import { getAssets, uploadAsset, deleteAssets, getStorage, filterAssets, searchAssets, saveLink } from '../../actions/assetsAction';
import ArrowGoBack from '../../components/ArrowGoBack/ArrowGoBack';
import uploadIcon from '../../assets/icons/Upload_Icon@2x-8.png';
import trashIcon from '../../assets/icons/Trash_Icon_white.png';
import styles from './index.module.css';
import { useEffect, useState } from 'react';
import { closeLoading, openLoading } from '../../components/loadingModal/loading';
import alert from '../../components/AlertModal/alertModal';
import FilesBox from './files_box/files_box';
import File from './file/file';
import moment from 'moment';
import SelectTwo from '../../components/SelectTwo/SelectTwo';
import InputTextModal from '../../components/InputTextModal/InputTextModal';

const Template = (props) => {
    const {
        getAssets,
        assets,
        uploadAsset,
        deleteAssets,
        storage,
        getStorage,
        filterAssets,
        searchAssets,
        saveLink
    } = props;
    const [state, setState] = useState({
        loading: false,
        dragOver: false,
        files: [{file_name: '', updatedAt: null, file_size: '', file_type: '', file_false: true}],
        filters: {
            videos: false,
            images: false,
            pdf: false,
            links: false
        },
        openModal: false
    });
    const getFiles = async () => {
        openLoading();
        try {
            await getAssets();
            await getStorage();
        } catch (error) {
            alert('ERROR', error)
        }
        closeLoading();
    };
    const mediaQuery = window.matchMedia('(max-width: 427px)');
    useEffect(() => {
        getFiles();
    }, []);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.target.classList.add(styles.dragOver);
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.target.classList.remove(styles.dragOver);
    };
    const handleDrop = async (e) => {
        e.preventDefault();
        setState({ ...state, loading: true });
        e.target.classList.remove(styles.dragOver);
        const files = e.dataTransfer.files;
        // upload files
        try {
            for (let i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append('file', files[i]);
                await uploadAsset(formData);
                await getFiles();
            };
            handleFilter();
        } catch (error) {
            alert('ERROR', error);
        };
        setState({ ...state, loading: false });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        mediaQuery.matches ? openLoading() : setState({ ...state, loading: true });
        const files = e.target.files;
        try {
            for (let i = 0; i < files.length; i++) {
                const formData = new FormData();
                formData.append('file', files[i]);
                await uploadAsset(formData);
                await getFiles();
            };
            getStorage();
            handleFilter();
        } catch (error) {
            alert('ERROR', error);
        } finally {
            mediaQuery.matches ? closeLoading() : setState({ ...state, loading: false });
        };
    };
    const handleSelectFiles = async (e) => {
        setState({ ...state, files: [...state.files, e] });
    }
    const handleUnselectFiles = (e) => {
        const files = state.files.filter((file) => file.id !== e.id);
        setState(prevState => ({ ...prevState, files: files }));
    };
    const handleDeleteFiles = async () => {
        openLoading();
        try {
            for (let i = 0; i < state.files.length; i++) {
                if (state.files[i].file_false) continue;
                await deleteAssets(state.files[i]);
                await getFiles();
            };
            getStorage();
            handleFilter();
            setState({ ...state, files: [state.files[0]] });
        } catch (error) {
            alert('ERROR', error);
        };
        closeLoading();
    };
    const handleFilter = async (e) => {
        if (e) {
            const { value } = e.target;
            await filterAssets({ ...state.filters, [value]: !state.filters[value] });
            const filters = { ...state.filters, [value]: !state.filters[value] };
            let currentFiles = [{file_name: '', updatedAt: null, file_size: '', file_type: '', file_false: true}];
            state.files.forEach(file => {
                for (const filter in filters) {
                    if (filters[filter]) {
                        const filtername = filter === 'images' ? 'image' : filter === 'videos' ? 'video' : filter;
                        file.file_type.includes([filtername]) && currentFiles.push(file);
                    };
                };
            });
            setState({
                ...state,
                filters: filters,
                files: currentFiles
            });
        } else {
            filterAssets({ ...state.filters });
        };
    };
    const handleSearch = (e) => {
        searchAssets(e.target.value);
    };
    const handleSelect = (e) => {
        if (e.name === 'Files') document.getElementById('input-hide-assets').click();
        if (e.name === 'Link') setState({ ...state, openModal: true });
    };
    const handleSaveLink = async (e) => {
        openLoading();
        const link = e.inputText;
        try {
            await saveLink(link);
            await getFiles();
            closeLoading();
            alert('SUCCESS', 'Link saved successfully');
        } catch (error) {
            alert('ERROR', error);
        };
        closeLoading();
        setState({ ...state, openModal: false });
    };
    // quota in mb with max 2 decimals
    const quota = Math.round(storage.storage_quota / (1020 * 1020) * 100) / 100;
    const current = Math.round(storage.current_storage / (1020 * 1020) * 100) / 100;
    const porcent = Math.round(current / quota * 100);
    const color = porcent > 80 ? '#F90000' : porcent > 50 ? 'orange' : '#289c74';
    const lastFile = state.files[state.files.length - 1];
    const date = lastFile.updatedAt ? moment(lastFile.updatedAt).format('dddd, MMM DD, YYYY, h:mm:ss A') : '';
    
    // const size = Math.round(lastFile.file_size / 1000000 * 100) / 100;
    const size = Math.round(lastFile.file_size / 1020 * 100) / 100;
    return (
        <div className={`box-container `}>
            <InputTextModal placeholder='URL' isOpen={state.openModal} closeModal={() => setState({...state, openModal: false})} onSave={handleSaveLink}/>
            <ArrowGoBack max={2}/>
            <div className={styles.container}>
                <div className={styles.principalCon}>
                    <div className={styles.uploadCon}>
                       <div className={styles.buttonBox}>
                            <div className={styles.inputCon}>
                                <input type="file" id="input-hide-assets" style={{display: 'none'}} onChange={handleSubmit} multiple/>
                                <SelectTwo options={[{name: 'Files'}, {name: 'Link'}]} icon={uploadIcon} title='UPLOAD' onChange={handleSelect}/>
                                {/* <button className={`button_dark_1 ${styles.button}`} onClick={() => {document.getElementById('input-hide-assets').click()}}>
                                    <img src={uploadIcon} alt="upload" className={`icon_btn_1 ${styles.img}`}/>
                                    <div className={`text_btn_dark_1`}>UPLOAD</div>
                                </button> */}
                            </div>
                            <div>
                                {
                                    state.files.length > 1 && (
                                        <button className={`button_blue_1 ${styles.button} `} onClick={handleDeleteFiles}>
                                            <img src={trashIcon} alt="delete" className={`icon_btn_1 ${styles.img}`}/>
                                            <div className={`text_btn_dark_1`}>DELETE</div>
                                        </button>
                                    )
                                }
                            </div>
                       </div>
                       <div className={styles.boxContainer}>
                            <div className={styles.uploadBox} onDragOver={handleDragOver} onDrop={handleDrop} onDragLeave={handleDragLeave} >
                                {
                                    state.loading ? (
                                        <div className={styles.loopCon}>
                                            <div className={styles.back}>
                                                <div className={styles.center}></div>
                                            </div>
                                            <p className={styles.textLoading}>Loading...</p>
                                        </div>
                                    ) : 'Drop Files Here To Upload, or Use The Upload Button'
                                }
                            </div>
                       </div>
                    </div>
                    <div className={styles.filters}>
                        <input type="text" className={`in-FLD ${styles.input2}`} placeholder='Search' onChange={handleSearch} />
                        <div>
                            <input type="checkbox" className={`check-AUM-3 ${styles.checkbox2}`} value={'videos'} checked={state.filters.videos} onChange={handleFilter}/>
                            <span>Videos</span>
                        </div>
                        <div>
                            <input type="checkbox" className={`check-AUM-3 ${styles.checkbox2}`} value={'images'} checked={state.filters.images} onChange={handleFilter}/>
                            <span>Images</span>
                        </div>
                        <div>
                            <input type="checkbox" className={`check-AUM-3 ${styles.checkbox2}`} value={'pdf'} checked={state.filters.pdf} onChange={handleFilter}/>
                            <span>PDF</span>
                        </div>
                        <div>
                            <input type="checkbox" className={`check-AUM-3 ${styles.checkbox2}`} value={'links'} checked={state.filters.links} onChange={handleFilter}/>
                            <span>Links</span>
                        </div>
                    </div>
                    <div className={styles.filesContainer}>
                        {
                            assets.map((asset) => <FilesBox key={asset.id} asset={asset} callbackIn={handleSelectFiles} callbackOut={handleUnselectFiles}/>)
                        }
                    </div>
                    <div className={styles.storageCon}>
                        <div>MY STORAGE</div>
                        <div className={styles.totalbar}>
                            <div className={styles.bar} style={{width: `${porcent}%`, background: color}}/>
                        </div>
                        <div>{`${current} MB / ${quota} MB`}</div>
                    </div>
                </div>
                <div className={styles.secondCon}>
                    <div className={styles.imagesContainer}>
                        <div className={styles.imagesBox}>
                            {
                                state.files.map((file, i) => <File key={file.file_name} file={file} initial={lastFile.file_false && true} index={i}/>)
                            }
                        </div>
                    </div>
                    <div className={styles.dataContainer}>
                        <div className={styles.boxData}>
                            <div className={styles.boxDataTitle}>NAME:</div>
                            <div className={styles.boxDataContent}>{lastFile.file_name}</div>
                        </div>
                        <div className={styles.boxData}>
                            <div className={styles.boxDataTitle}>DATE:</div>
                            <div className={styles.boxDataContent}>{date}</div>
                        </div>
                        <div className={styles.boxData}>
                            <div className={styles.boxDataTitle}>SIZE:</div>
                            <div className={styles.boxDataContent}>{lastFile.file_size ? `${size} KB (${lastFile.file_size} bytes)` : ''}</div>
                        </div>
                        <div className={styles.boxData}>
                            <div className={styles.boxDataTitle}>FILE TYPE:</div>
                            <div className={styles.boxDataContent}>{lastFile.file_type}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        assets: state.assets.assets,
        storage: state.assets.storage
    };
};

export default connect(mapStateToProps, { getAssets, uploadAsset, deleteAssets, getStorage, filterAssets, searchAssets, saveLink })(Template);