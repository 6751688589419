import { FILTER_ASSETS, GET_ASSETS, GET_STORAGE, GET_STORAGE_INFO, SEARCH_ASSETS, UPLOAD_ASSET } from "../actions/assetsAction";

var initialState = {
    assets: [],
    assetsCopy: [],
    asset: {},
    assetCopy: {},
    storage: {},
    filter: {
        images: false,
        videos: false,
        pdf: false,
        links: false
    },
    storageInfo: { total: 0, used: 0, free: 0}
};

const assetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_ASSETS):
            return state = {
                ...state,
                assets: action.payload,
                assetsCopy: action.payload
            };
        case (UPLOAD_ASSET):
            return state = {
                ...state,
                asset: action.payload,
                assetCopy: action.payload
            };
        case (GET_STORAGE):
            return state = {
                ...state,
                storage: action.payload
            };
        case (FILTER_ASSETS):
            let assets = [];
            if (action.payload.images) {
                assets.push(...state.assetsCopy.filter(asset => asset.file_type.includes('image')));
            } if (action.payload.videos) {
                assets.push(...state.assetsCopy.filter(asset => asset.file_type.includes('video')));
            } if (action.payload.pdf) {
                assets.push(...state.assetsCopy.filter(asset => asset.file_type.includes('pdf')));
            } if (action.payload.links) {
                assets.push(...state.assetsCopy.filter(asset => asset.file_type.includes('link')));
            } if (assets.length === 0) {
                let all;
                for (let key in action.payload) {
                    if (action.payload[key] === true) {
                        all = true;
                    };
                };
                if (!all) assets = [...state.assetsCopy];
            };
            return state = {
                ...state,
                assets,
                // filter: action.payload
            };
        case (SEARCH_ASSETS):
            return state = {
                ...state,
                assets: state.assetsCopy.filter(asset => asset.file_name.toLowerCase().includes(action.payload.toLowerCase()))
            };
        case (GET_STORAGE_INFO):
            return state = {
                ...state,
                storageInfo: action.payload
            };
        default:
            return state;
    }
};

export default assetsReducer;