import { FormHelperText, TextField } from "@mui/material";
import "./index.css";

const InputText = (props) => {
  const {
    inputClass,
    placeholder,
    name,
    type,
    id,
    value,
    onChange,
    search,
    errors,
  } = props;

  const hasErrors = errors && errors.length > 0;

  return (
    <div className="input_wrapper">
      <TextField
        id={id}
        label={placeholder}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        fullWidth
        variant="outlined"
        error={hasErrors}
        margin="none"
        InputLabelProps={{
          shrink: value !== "",
        }}
        sx={{
          height: "40px",
          "& .MuiOutlinedInput-input": {
            padding: "8px 14px",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, 8px) scale(1)",
            color: value ? "#3e9ae5" : "#a2c4e0",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#a2c4e0",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#3e9ae5",
              borderWidth: "0.25vh",
              borderRadius: "0.6vh",
              height: "40px",
            },
            "&:hover fieldset": {
              borderColor: "#3e9ae5",
              borderWidth: "0.25vh",
              borderRadius: "0.6vh",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#3e9ae5",
              borderWidth: "0.25vh",
              borderRadius: "0.6vh",
            },
            "& input": {
              color: "#3e9ae5",
              fontFamily: "ProximaNova-semibold",
              fontSize: "1.8vh",
            },
          },
        }}
      />
      {hasErrors && (
        <FormHelperText>
          {errors.map((option) => {
            return <div className="error">{option.error}</div>;
          })}
        </FormHelperText>
      )}
    </div>
  );
};

export default InputText;
