import { useEffect, useRef, useState } from 'react';
import assetIcon from '../../../assets/icons/Icon_Normal@2x-8.png';
import assetIconSelected from '../../../assets/icons/Icon_Dark@2x-8.png';
import pdfIcon from '../../../assets/icons/pdf_icon_clear.png';
import pdfIconSelected from '../../../assets/icons/pdf_icon_dark.png';
import styles from '../index.module.css';
import urlIcon from '../../../assets/icons/url.png';


const FilesBox = (props) => {
    const {
        asset,
        callbackIn,
        callbackOut
    } = props;
    var initialState = {
        select: false,
        src: assetIcon,
        style: styles.iconAsset,
        videosrc: asset.file_path,
    };
    const [state, setState] = useState(initialState);
    const videoRef = useRef(null);
    useEffect(() => {
        if (asset.file_type.includes('image')) {
            const realImage = new Image();
            // realImage.loading = 'lazy';
            realImage.src = asset.file_path;
            realImage.onload = () => {
                setState({ ...state, src: asset.file_path, style: styles.imageAsset });
            };
        } else if (asset.file_type === 'application/pdf') {
            setState({ ...state, src: pdfIcon });
        } else if (asset.file_type.includes('video')) {
            initialState.src = state.src;
            const video = videoRef.current;
            video.addEventListener('canplay', () => {
                handleLoadVideo();
            });
        } else if (asset.file_type === 'url/text') {
            setState({ ...state, src: urlIcon });
        }
        // setState({ ...state, select: false });
    }, [asset]);
    const handleSelect = (e) => {
        const img = document.getElementById(`img-${asset.id}`);
        if (state.select === false) {
            if (asset.file_type === 'application/pdf') {
                img.src = pdfIconSelected;
            } else if (asset.file_type === 'url/text') {
                img.src = urlIcon;
            } else if (asset.file_type.includes('image')) {
                img.src = asset.file_path;
            } else if (asset.file_type.includes('video')) {
            } else {
                img.src = assetIconSelected;
            }
            setState({ ...state, select: true });
            callbackIn(asset);
        } else {
            if (asset.file_type === 'application/pdf') {
                img.src = pdfIcon;
            } else if (asset.file_type === 'url/text') {
                img.src = urlIcon;
            } else if (asset.file_type.includes('image')) {
                img.src = asset.file_path;
            } else if (asset.file_type.includes('video')) {
            } else {
                img.src = assetIcon;
            }
            setState({ ...state, select: false });
            callbackOut(asset);
        };
    };
    const handleLoadVideo = (e) => {
        const canvas = document.createElement('canvas');
        // canvas.crossorigin = 'anonymous';
        // videoRef.currentTime = 5;
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        initialState.videosrc = canvas.toDataURL();
        setState({ ...state, src: canvas.toDataURL(), style: styles.imageAsset });
    };
    return (
        <div className={`${styles.fileContainer} ${state.select && styles.selected}`} onClick={handleSelect}>
            <input type="checkbox" className={`check-AUM-3 ${styles.checkbox} ${state.select && styles.selected} `} defaultChecked></input>
            <div className={styles.iconCon}>
                {
                    asset.file_type.includes('video') && (
                        <video crossOrigin="anonymous" ref={videoRef} className={styles.videoHide} controls >
                            <source  crossOrigin="anonymous" src={state.videosrc} type={state.type} />
                        </video>
                    )
                }
                <img src={state.src} id={`img-${asset.id}`} alt="file" className={state.style} loading='lazy'/>
            </div>
            <span className={styles.filename}>{asset.file_name}</span>
        </div>
    );
};

export default FilesBox;