import { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { closeLoading, openLoading } from "../../../../components/loadingModal/loading";
import { addGroupsToCourses, getCourse } from "../../../../actions/manageCoursesActions";
import { getAllGroups } from "../../../../actions/manageGroupsActions";
import addGroupsIcon from "../../../../assets/icons/btnIconGroups.png";
import alert from "../../../../components/AlertModal/alertModal";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import styles from "./assign_groups.module.css";

const AssignGroups = (props) => {
    const {
        closeModal,
        groups,
        courseData,
        getAllGroups,
        addGroupsToCourses
    } = props;
    const [groupsSelected, setGroupsSelected] = useState({})
    const [availableGroups, setAvailableGroups] = useState(groups)
    const { courseId } = useParams();

    const getInitialData = async () => {
        try {
            await getAllGroups({ column: "name", order: "asc" });
        } catch (error) {
            alert("Error", error.message);
        };
    };

    useEffect(() => {
        getInitialData();
    }, [courseId]);

    useEffect(() => {
        const currentGroups = courseData.name ? courseData.groups :  [];
        let filterGroups = [];
        if (currentGroups.length > 0) {
            groups.forEach(u => {
                const index = currentGroups.findIndex(cu => cu.idGroup === u.idGroup);
                if (index < 0) {
                    filterGroups.push(u)
                }
            });
        } else {
            filterGroups = groups
        }
        setAvailableGroups(filterGroups)
    }, [groups, courseId, courseData])

    const handleCancel = (e) => {
        e.preventDefault();
        setGroupsSelected([]);
        closeModal();
    };
    const onSave = async () => {
        openLoading();
        const ids = Object.keys(groupsSelected);
        const groupsFormatted = ids.map(id => groups.find(group => `${group.idGroup}` === id))

        addGroupsToCourses({groups: groupsFormatted});
        setGroupsSelected([]);
        closeModal();
        closeLoading();
    };

    const columns = useMemo(
        () => [
          {
            header: 'name',
            accessorKey: 'name',
            Header: () => <div className="table-title">GROUP NAME</div>, 
            filterVariant: 'text',
            size: 300,
          },
          {
            header: 'users',
            accessorKey: 'countUsers',
            Header: () => <div className="table-title">USERS</div>, 
            enableColumnFilter: false,
            enableSorting: false,
            size: 50,
          },
        ],
        [],
    );

    return (
        <div className={`box-container-opaque ${styles.opaqueContainer}`}>
            <div className={`box-container entryPage group-box-entry ${styles.container}`}>
                <div className={`page-wrapper ${styles.wrapper}`}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={handleCancel}/>
                    </div>
                    <div className={styles.subHeaderContainer}>
                        <div className={`button_dark_1 ${styles.subHeader}`} onClick={handleCancel}>
                            <img src={addGroupsIcon} alt="icon" className={styles.icon}/>
                            <div className="text_btn_dark_1">ADD GROUPS</div>
                        </div>
                    </div>
                    <div className='table-container'>
                        { availableGroups && availableGroups.length > 0 &&
                            <Table 
                                rowId="idGroup"
                                data={availableGroups} 
                                columns={columns}
                                height="55vh" 
                                enableRowSelection
                                setSelectedRows={setGroupsSelected}
                            />
                        }
                        {/* <GroupsTable save={onSave} showSave={groups.length > 0 ? true : false} courseId={courseId} isOpen={isOpen} courseData={courseData} /> */}
                    </div>
                    <div className={styles.footerActionsContainer}>
                        <div/>
                        <Button 
                            disabled={Object.keys(groupsSelected).length === 0}
                            onClick={onSave}
                            label="ADD GROUPS"
                            size="small"
                            styleButton="main"
                            image={<img src={addGroupsIcon} alt="Add Groups" className={styles.btnIcon}/>}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllGroups: async (params) => {
            await dispatch(getAllGroups(params));
        },
        addGroupsToCourses: async (params) => {
            await dispatch(addGroupsToCourses(params));
        },
        getCourse: async (params) => {
            await dispatch(getCourse(params))
        }
    };
};

const mapStateToProps = (state) => {
    return {
        groups : state.manageGroups.allGroups,
        courseData: state.manageCourses.courseData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignGroups);