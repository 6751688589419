
import { useState } from "react";
import ModalPage from "../modalPage-base/ModalPage";

import styles from "./inputModal.module.css";

const InputModal = (props) => {
    const {
        title,
        isOpen,
        closeModal,
        onSubmit,
        importance,
        textInput,
        buttonText = "SEND"
    } = props;
    const [input, setInput] = useState("");
    return (
        <ModalPage isOpen={isOpen} importance={importance} id="input-modal">
            <div className={styles.inputModalCon}>
                <span className={styles.titleInput} id='title-input'>{title}</span>
                <input className={styles.input} id='input' type="text" placeholder={textInput} value={input} onChange={(e) => setInput(e.target.value)}/>
                <div className={styles.buttonContainer}>
                    <button className='btn-modalPages' id='cancel-button-input' onClick={() => closeModal()}>CANCEL</button>
                    <button className='btn-modalPages' id='acept-button-input' onClick={() => onSubmit(input)}>{buttonText}</button>
                </div>
            </div>
        </ModalPage>
    );
};

export default InputModal;