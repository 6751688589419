import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCourses, deleteCourse } from "../../actions/manageCoursesActions";
import {
  closeLoading,
  openLoading,
} from "../../components/loadingModal/loading";

import Axios from "../../config/Axios";
import moment from "moment";
import confirm from "../../components/ConfirmModal/confirm";
import alert from "../../components/AlertModal/alertModal";
import trashIcon from "../../assets/icons/Trash_Icon.png";
import switchOff from "../../assets/box/Switch_Off.png";
import switchOn from "../../assets/box/Switch_On.png";
import Table from "../../components/Table";
import styles from "./index.module.css";
import infoIcon from "../../assets/icons/info-icon.png";
import AttendeesTableModal from "../ManageSessions/session_tables/attendees_table_modal/attendees_table_modal";

const { REACT_APP_API_URL } = process.env;

const ManageCourseTable = ({
  deleteCourse,
  getCourses,
  courses,
  permissions,
}) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    getCourses();
  }, []);
  const handleDelete = async (idCourse) => {
    const confirmFun = await confirm(
      "Confirm",
      "Are you sure you want to delete this course?"
    );
    if (!confirmFun) return;
    try {
      await deleteCourse(idCourse);
      alert("Successfully", "Course has been deleted");
      await getCourses();
    } catch (error) {
      alert("ERROR", error.message);
    }
  };

  const activeCourse = async (row) => {
    openLoading();
    try {
      await Axios.put(`${REACT_APP_API_URL}/manageCourses/setActiveCourse`, {
        active: !row.active,
        idCourse: row.idCourse,
      });
      await getCourses();
    } catch (error) {
      alert("ERROR", error.response.data.message);
    } finally {
      closeLoading();
    }
  };
  const handleOpen = (row) => {
    setSelectedCourse(row);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setSelectedCourse(null);
  };
  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: () => <div className="table-title">COURSE NAME</div>,
        header: "Name",
        grow: false,
        size: 50,
        accessorFn: (row) => (
          <Link className="li-t" to={`edit-course/${row.idCourse}`}>
            {row.name}
          </Link>
        ),
        filterFn: (row, id, filterValue) => {
          return row.original.name
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
      },
      {
        id: "product",
        header: "Product",
        Header: () => <div className="table-title">PRODUCT</div>,
        accessorFn: (row) => row.product.name,
        filterFn: (row, id, filterValue) => {
          return row.original.product.name
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        grow: false,
        size: 50,
      },
      {
        id: "faculty",
        header: "Faculty",
        Header: () => <div className="table-title">OWNER</div>,
        accessorFn: (row) => (
          <div className="actions-container">
            <div>{row.faculties ? row.faculties.length : 0}</div>
            <div className={styles.rowInfoContainer}>
              <img
                src={infoIcon}
                alt="Information about attenders"
                className={styles.infoIcon}
                onClick={() => handleOpen(row)}
              />
            </div>
          </div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
        grow: false,
        size: 50,
      },
      {
        id: "startDate",
        header: "Start Date",
        Header: () => <div className="table-title">START DATE</div>,
        accessorFn: (row) =>
          row.startDate
            ? moment(new Date(row.startDate)).format("MMM DD, YYYY")
            : "",
        enableSorting: false,
        grow: false,
        size: 50,
      },
      {
        id: "endDate",
        header: "End Date",
        Header: () => <div className="table-title">END DATE</div>,
        accessorFn: (row) =>
          row.endDate
            ? moment(new Date(row.endDate)).format("MMM DD, YYYY")
            : "",
        enableSorting: false,
        grow: false,
        size: 50,
      },
      {
        id: "users",
        header: "Users",
        Header: () => <div className="table-title">USERS</div>,
        accessorKey: "usersCount",
        enableColumnFilter: false,
        enableSorting: false,
        grow: false,
        size: 50,
      },
      {
        id: "active",
        header: "Active",
        Header: () => <div className="table-title">ACTIVE</div>,
        accessorFn: (row) => (
          <div className="actions-container">
            <img
              src={row.active ? switchOff : switchOn}
              alt="activate"
              className={styles.switch}
              onClick={() => activeCourse(row)}
            />
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: false,
        grow: false,
        size: 50,
      },
      {
        id: "action",
        header: "actions",
        Header: () => <div className="table-title">ACTIONS</div>,
        accessorFn: (row) => (
          <div className="actions-container">
            {(permissions.includes(24) || permissions.includes(25)) && (
              <img
                src={trashIcon}
                alt="Delete"
                className="trash_icon"
                onClick={() => handleDelete(row.idCourse)}
              />
            )}
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: false,
        grow: false,
        size: 50,
      },
    ],
    []
  );

  return (
    <div className={styles.tableWrapper}>
      <div className="table-container">
        <Table data={courses} height="63vh" columns={columns} />;
      </div>
      {isOpen && selectedCourse && (
        <AttendeesTableModal
          users={selectedCourse.faculties}
          close={handleClose}
          title="OWNERS"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    courses: state.manageCourses.coursesList,
    permissions: state.usersReducer.userLogged?.permissions,
  };
};

export default connect(mapStateToProps, { getCourses, deleteCourse })(
  ManageCourseTable
);
