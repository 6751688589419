import LoginForm from "./login_form/login_form.jsx";
import { Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { isAuth, recoverPassword } from "../../actions/usersActions.js";
import Footer from "../../components/Footer/Footer.jsx";
import AlertModal from "../../components/AlertModal/AlertModal.jsx";
import { useModalBase } from "../../hooks/useModalBase.js";
import styles from "./index.module.css";
import InputModal from "../../components/inputModal/inputModal.jsx";
import Loading from "../../components/loadingModal/LoadingModal.jsx";
import {
  openLoading,
  closeLoading,
} from "../../components/loadingModal/loading.js";

const Login = (props) => {
  const { user, isAuth, recoverPassword } = props;
  const [isOpen, openModal, closeModal] = useModalBase();
  const [modalCont, setModalCont] = useState({
    title: "",
    text: "",
  });
  const [isOpenRP, openModalRP, closeModalRP] = useModalBase();
  const mediaQuery = window.matchMedia("(max-width: 427px)");
  const sessionExpired = localStorage.getItem("sessionExpired");
  const navigate = useNavigate();
  const getInitialData = async () => {
    openLoading();
    try {
      await isAuth();
      navigate("/home");
    } catch (error) {
      // get session expired from local storage
      // if session expired is true, alert the user that the session expired
      if (error.message === "503") navigate("/404");
      if (sessionExpired === "true") {
        setModalCont({
          title: "SESSION EXPIRED",
          text: "Your session has expired, please log in again",
        });
        openModal();
      }
      closeLoading();
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);
  const handleRecoverPassword = async (e) => {
    openLoading();
    const email = e;
    const response = await recoverPassword(email);
    if (response.status === "Successfully") {
      setModalCont({
        title: "SUCCESS",
        text: "Your username and password have been sent to your email",
      });
      openModal();
      closeModalRP();
    } else {
      setModalCont({
        title: "ERROR",
        text: response.message,
      });
      openModal();
      closeModalRP();
    }
    closeLoading();
  };
  if (mediaQuery.matches) {
    return (
      <div className={styles.container}>
        <Loading />
        <AlertModal
          isOpen={isOpen}
          closeModal={() => {
            localStorage.removeItem("sessionExpired");
            closeModal();
          }}
          title={modalCont.title}
          text={modalCont.text}
        />
        <InputModal
          isOpen={isOpenRP}
          onSubmit={handleRecoverPassword}
          closeModal={closeModalRP}
          title="PLEASE PROVIDE YOUR EMAIL TO RECOVER YOUR USERNAME/PASSWORD"
        />
        <div className={styles.countentMovile}>
          <div className={styles.singin}>
            <div className={styles.headerTitle}>CUSTOMER PORTAL</div>
            <LoginForm
              openModal={openModal}
              openModalRP={openModalRP}
              setModalCont={setModalCont}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <Loading />
        <AlertModal
          isOpen={isOpen}
          closeModal={() => {
            localStorage.removeItem("sessionExpired");
            closeModal();
          }}
          title={modalCont.title}
          text={modalCont.text}
        />
        <InputModal
          isOpen={isOpenRP}
          onSubmit={handleRecoverPassword}
          closeModal={closeModalRP}
          title="PLEASE PROVIDE YOUR EMAIL TO RECOVER YOUR USERNAME/PASSWORD"
        />
        {user.outOfService ? (
          <Navigate to="/404" />
        ) : (
          user.isLogged && <Navigate to="/home" />
        )}
        <div className={styles.countent}>
          <div className={styles.singin}>
            <LoginForm
              openModal={openModal}
              openModalRP={openModalRP}
              setModalCont={setModalCont}
            />
          </div>
          <div className={styles["dC-assets"]}></div>
        </div>
        <Footer />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.usersReducer.userLogged,
  };
};

export default connect(mapStateToProps, { isAuth, recoverPassword })(Login);
