import {
    COUNT_GROUPS,
    DELETE_GROUP,
    FILTER_GROUPS,
    FILTER_USERS_BY_GROUP,
    FORGET_USERS,
    GET_ALL_GROUPS,
    GET_GROUP,
    GET_USERS_BY_GROUP,
    REMOVE_USERS_FROM_GROUP,
    SELECT_GROUP,
    SET_GROUP_DATA,
    UN_SELECT_GROUP,
    ADD_USERS_TO_GROUP,
    CLEAR_GROUPS_SELECTED
} from "../actions/manageGroupsActions";

var initialState = {
    allGroups: [],
    allGroupsCopy: [],
    groupsSelected: [],
    groupDeleted: [],
    users: [],
    usersCopy: [],
    usersDeleted: [],
    groupData: {},
    countGroups: 0,
};

const manageGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_ALL_GROUPS):
            return state = {
                ...state,
                allGroups: action.payload,
                allGroupsCopy: action.payload
            };
        case (SELECT_GROUP):
            return state = {
                ...state,
                groupsSelected: [...state.groupsSelected, action.payload]
            };
        case (UN_SELECT_GROUP):
            return state = {
                ...state,
                groupsSelected: state.groupsSelected.filter(groupId => groupId !== action.payload)
            };
        case (CLEAR_GROUPS_SELECTED):
            return state = {
                ...state,
                groupsSelected: []
            };
        case (DELETE_GROUP):
            return state = {
                ...state,
                groupDeleted: [...state.groupDeleted, action.payload]
            };
        case (GET_USERS_BY_GROUP):
            return state = {
                ...state,
                users: action.payload.users,
                usersCopy: action.payload.users,
                // groupData: action.payload
            };
        case (FORGET_USERS):
            return state = {
                ...state,
                users: [],
                groupData: {}
            };
        case (REMOVE_USERS_FROM_GROUP):
            return state = {
                ...state,
                usersDeleted: [...state.groupDeleted, action.payload],
                users: state.users.filter(user => user.idUser !== action.payload.idUser)
            };
        case (GET_GROUP):
            return state = {
                ...state,
                groupData: action.payload,
                users: action.payload.users,
                usersCopy: action.payload.users,
            };
        case (SET_GROUP_DATA):
            return state = {
                ...state,
                groupData: {...state.groupData, [action.payload.name]: action.payload.value}
            };
        case (FILTER_GROUPS):
            return state = {
                ...state,
                allGroups: state.allGroupsCopy.filter(group => {
                    let products = group.products ? group.products.map(product => product.name.toLowerCase().includes(action.payload.toLowerCase())) : [];
                    return group.name.toLowerCase().includes(action.payload.toLowerCase()) ||
                    products.includes(true)
                })
            };
        case (FILTER_USERS_BY_GROUP):
            return state = {
                ...state,
                users: state.usersCopy.filter(user => {
                    return (
                        user.firstname.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.lastname.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.username.toLowerCase().includes(action.payload.toLowerCase())
                    );
                })
            };
        case (COUNT_GROUPS):
            return state = {
                ...state,
                countGroups: action.payload
            };
        case (ADD_USERS_TO_GROUP):
            return state = {
                ...state,
                users: [...state.users, ...action.payload]
            };
        default: return state;
    }
};

export default manageGroupsReducer;