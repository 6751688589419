import { 
    ADD_USER, 
    GET_ALL_USERS_INFO, 
    IS_USER_AUTH, 
    SELECT_USER, 
    UN_SELECT_USER, 
    GET_USER, 
    SET_USER_INFO, 
    UPDATE_USER, 
    CLEAR_USER_INFO, 
    GET_USERS_BY_CLIENT,
    GET_TA_BY_CLIENT,
    CLEAR_USERS_SELECTED, 
    BULK_LOAD, 
    FILTER_USERS, 
    RECOVERPASSWORD, 
    GET_USERS 
} from "../actions/usersActions";

var initialState = {
    path: "",
    userLogged: {
        permissions: [],
        has_storage: false,
    },
    permissions: [],
    userData: {
        firstname: '',
        lastname: '',
        birthday: '',
        gender: '',
        username: '',
        password: '',
        repeatPassword: '',
        roleIdRole: '',
        profilePicture: '',
        urlAvatar: '',
        email: '',
    },
    userUpdated: [],
    userRegister: {},
    allUsers: [],
    usersSelected: [],
    usersList: [],
    usersListCopy: [],
    bulkLoad: []
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case (ADD_USER):
            return state = {
                ...state,
                userRegister: action.payload
            };
        case (GET_USER):
        case (SET_USER_INFO):
            return state = {
                ...state,
                userData: action.payload
            };
        case (CLEAR_USER_INFO):
            return state = {
                ...state,
                userData: {
                    firstname: '',
                    lastname: '',
                    birthday: '',
                    gender: '',
                    username: '',
                    password: '',
                    repeatPassword: '',
                    roleIdRole: '',
                    profilePicture: '',
                    urlAvatar: '',
                    email: '',
                }
            };
        case (UPDATE_USER):
            return state = {
                ...state,
                userUpdated: action.payload
            };
        case (IS_USER_AUTH):
            return state = {
                ...state,
                userLogged: action.payload,
                permissions: action.payload.permissions
            };
        case (GET_ALL_USERS_INFO):
            return state = {
                ...state,
                allUsers: action.payload
            };
        case (SELECT_USER):
            return state = {
                ...state,
                usersSelected: [...state.usersSelected, action.payload]
            };
        case (UN_SELECT_USER):
            return state = {
                ...state,
                usersSelected: state.usersSelected.filter(userId => userId !== action.payload)
            };
        case (CLEAR_USERS_SELECTED):
            return state = {
                ...state,
                usersSelected: []
            };
        case (GET_USERS_BY_CLIENT):
            return state = {
                ...state,
                usersList: action.payload,
                usersListCopy: action.payload
            };
        case (GET_TA_BY_CLIENT):
            return state = {
                ...state,
                teacherAssistantList: action.payload,
                teacherAssistantListCopy: action.payload
            };
        case (GET_USERS):
            return state = {
                ...state,
                usersList: action.payload,
                usersListCopy: action.payload
            };
        case (FILTER_USERS):
            return state = {
                ...state,
                usersList: state.usersListCopy.filter(user => {
                    return user.username.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.firstname.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.lastname.toLowerCase().includes(action.payload.toLowerCase()) ||
                        user.role.name.toLowerCase().includes(action.payload.toLowerCase());
                })
            };
        case (BULK_LOAD):
            return state = {
                ...state,
                bulkLoad: action.payload
            }
        case (RECOVERPASSWORD):
            return state = {
                ...state
            }
        default: return state;
    };
};

export default usersReducer;