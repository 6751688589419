import Axios from "../config/Axios";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const GET_USER_PRODUCTS = "GET_USER_PRODUCTS";
export const GET_PRODUCT_LICENSES = "GET_PRODUCT_LICENSES";
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const GET_PRODUCTS_BY_USER = "GET_PRODUCTS_BY_USER";

// API URL
const { REACT_APP_API_URL } = process.env;

const addLicense = (productData) => {
  return (dispatch) => {
    return Axios.post(`${REACT_APP_API_URL}/products/addProduct`, productData)
      .then((response) => {
        if (response.data.newProduct) {
          dispatch({
            type: ADD_PRODUCT,
            payload: response.data.newProduct,
          });
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const getUserProducts = (userId) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/products/getUserProducts/${userId}`)
      .then((response) => {
        if (response.data.userProducts) {
          dispatch({
            type: GET_USER_PRODUCTS,
            payload: response.data.userProducts,
          });
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const getProductIdsByUser = (userId) => {
  return (dispatch) => {
    return Axios.get(
      `${REACT_APP_API_URL}/productLicenses/getProductsIds/${userId}`
    )
      .then((response) => {
        if (response.data.response) {
          dispatch({
            type: GET_PRODUCTS_BY_USER,
            payload: response.data.response,
          });
        }
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const getProductLicenses = ({ order, column }) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/productLicenses/licenseDevices`, {
      params: {
        order,
        column,
      },
    })
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_LICENSES,
          payload: response.data.deviceLicense,
        });
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const listProducts = (props) => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/products/listProducts`, {
      params: props,
    })
      .then((response) => {
        dispatch({
          type: LIST_PRODUCTS,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

export {
  addLicense,
  getUserProducts,
  getProductIdsByUser,
  getProductLicenses,
  listProducts,
};
