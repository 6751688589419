import Axios from "../config/Axios";

export const GET_LOGO = 'GET_LOGO';
export const GET_LOGIN_DEVICE_LIFETIME = 'GET_LOGIN_LIFETIME';
export const UPDATE_LOGIN_DEVICE_LIFETIME = 'UPDATE_LOGIN_LIFETIME';

const {  REACT_APP_API_URL } = process.env;


const getClientLogo = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/clients/getClientLogo`)
            .then(response => {
                dispatch({
                    type: GET_LOGO,
                    payload: response.data
                });
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const getLoginDeviceLifetime = () => {
    return (dispatch) => {
        return Axios.get(`${REACT_APP_API_URL}/clients/getDeviceTokenDuration`)
            .then(response => {
                dispatch({
                    type: GET_LOGIN_DEVICE_LIFETIME,
                    payload: response.data.time
                });
                return response.data.time;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

const updateLoginDeviceLifetime = (time) => {
    return (dispatch) => {
        return Axios.put(`${REACT_APP_API_URL}/clients/updateDeviceTokenDuration`, { time })
            .then(response => {
                dispatch({
                    type: UPDATE_LOGIN_DEVICE_LIFETIME,
                    payload: response.data.time
                });
                return response.data.time;
            })
            .catch(error => {
                throw new Error(error.response.data.message);
            })
    };
};

export {
    getClientLogo,
    getLoginDeviceLifetime,
    updateLoginDeviceLifetime
};