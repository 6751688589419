import ManageGroupTable from "./group_table";
import { connect } from "react-redux";
import { deleteGroup, getAllGroups } from "../../actions/manageGroupsActions";
import { useEffect } from "react";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import iconBtnGroups from "../../assets/icons/btnIconGroups.png";
import iconGroups from "../../assets/box/groupsIcon.png";
import styles from "./index.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const ManageGroups = ({ groups, permissions }) => {
  useEffect(() => {}, []);

  const navigate = useNavigate();
  return (
    <div className="box-container">
      <ArrowGoBack />
      <div className="page-wrapper">
        <div className={styles.headerContainer}>
          <div className={styles.iconsContainer}>
            <img src={iconGroups} alt="Groups Icon" className="icon_head" />
            <div className="total_circle_1">
              <div className="total_circle_1_number">{groups.length}</div>
              <span className="total_circle_1_title">
                TOTAL <br /> GROUPS
              </span>
            </div>
          </div>
          <div className={styles.actionsContainer}>
            {(permissions.includes(9) || permissions.includes(10)) && (
              <Button
                label="CREATE GROUP"
                size="medium"
                styleButton="main"
                onClick={() => navigate("create-group")}
                image={
                  <img
                    src={iconBtnGroups}
                    alt="iconBtnGroups"
                    className={`icon_btn_1 ${styles.iconButton}`}
                  />
                }
              />
            )}
          </div>
        </div>
        <div className="table-container">
          <ManageGroupTable />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    groupsSelected: state.manageGroups.groupsSelected,
    groups: state.manageGroups.allGroups,
    permissions: state.usersReducer.userLogged.permissions,
  };
};

export default connect(mapStateToProps, { deleteGroup, getAllGroups })(
  ManageGroups
);
