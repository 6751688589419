import { useEffect, useRef, useState } from 'react';
import arrow from '../../assets/icons/downArrow.png';
import arrowBlue from '../../assets/icons/arrow_blue_icon.png';
import styles from './Select.module.css';


const Select = (props) => {
    const {
        options,
        onChange,
        showValue = 'name',//take the name of the property to show in the select in default
        value,
        valueName,
        title,
        large,
        name,
        type,
        block,
        style,
        errors
    } = props;
    const [optionsState, setOptionsState] = useState([]);
    const [selected, setSelected] = useState({
        name: '',
        value
    });
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);
    const handleClickedOutside = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
        };
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickedOutside);
        let currentOptions = optionsState.length > 0 ? optionsState : options;
        if (value) {
            if (value === 1 && valueName === undefined && options.length > 0) {
                setSelected({name: name, value: options[0]});
                onChange && onChange({name: name, value: options[0]});
            } else if (valueName && currentOptions.length > 0) {
                let opSel = currentOptions.find(op => op[valueName] === value );
                setSelected({name: name, value: opSel});
            } else {
                let opSel = currentOptions.find(op => op[value] || op.value === value);
                setSelected({name: name, value: opSel});
            };
        };
        return () => {
            document.removeEventListener('mousedown', handleClickedOutside);
        };
    } , [value]);
    useEffect(() => {
        // reset the value selected only if the options are different to the previous ones
        if ((optionsState.length !== options.length) && (value === '' || value === undefined)) {
            setSelected({name: name, value: ''});
            setOptionsState(options);
        } else if (value === '' || value === undefined) {
            const stringOptions = JSON.stringify(optionsState);
            const stringOptionsNew = JSON.stringify(options);
            if (stringOptions !== stringOptionsNew) {
                setSelected({name: name, value: ''});
                setOptionsState(options);
            };
        };
    }, [options]);
    const handleOpen = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const handleChange = (e) => {
        setSelected({...selected, value: e, valueName: valueName});
        onChange && onChange({name: name, value: e, valueName: valueName});
        if (type === 'multiSelect') setIsOpen(true);
        else setIsOpen(false);
    };
    return (
        <div className={styles.container} ref={selectRef}>
            <div className={`${styles.selectBox} ${isOpen && styles.active} ${errors && styles.error} ${block && styles.block}`} >
                {/* caja principal */}
                <div className={`${styles.select} ${isOpen && styles.active}`} id='select' onClick={handleOpen}>
                    <div className={styles.contentSelect}>
                        <div className={styles.selectText}>
                            {
                                isOpen ?
                                <p className={`${styles.title}`}>{title}</p> :
                                <p className={`${styles.title}`}>{selected.value ? (showValue ? selected.value[showValue] : selected.value.name) : title}</p>
                            }
                        </div>
                    </div>
                    <img src={arrowBlue} alt='arow' className={`${styles.arrow} ${isOpen && styles.active}`}/>
                </div>
                {/* opciones */}
                <div className={`${styles.options} ${isOpen && styles.active} ${styles[large]}`}>
                    {
                        options && options.map((option, index) => {
                            return (
                                <div key={index} className={styles.contentOption} onClick={()=>handleChange(option)} value={option}>
                                    {
                                        index % 2 === 0 ?
                                        <div className={styles.option}>
                                            <p className={`${styles.optionText}`}>{option[showValue]}</p>
                                        </div>
                                        :
                                        <div className={`${styles.option} ${styles.dark}`}>
                                            <p className={`${styles.optionText}`}>{option[showValue]}</p>
                                        </div>
                                    }
                                    
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Select;