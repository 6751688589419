import { logUser, stepTwo } from '../../../helpers/logUser'; //loguser function in called from helpers
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { isAuth } from '../../../actions/usersActions';
import styles from '../index.module.css';
import { closeLoading, openLoading } from '../../../components/loadingModal/loading';
import InputText from '../../../components/InputText';
import Button from '../../../components/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const LoginForm = ({ openModal, setModalCont, openModalRP }) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        username: '',
        password: '',
        code: '',
        hidePass: ''
    });
    const [firstStep, setFirstStep] = useState(false);
    const handleChange = (e) => {
        e.preventDefault();
        setState({
            ...state,
            [e.target.name]: e.target.value.trim()
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        openLoading();
        try {
            let logging = await logUser(state);
            if (logging.twoFactorAuth) {
                setFirstStep(true);
                closeLoading();
            } else {
                navigate('/home');
            };
        } catch (error) {
            setModalCont({
                title: 'ERROR',
                text: error.message.toUpperCase()
            });
            openModal();
            closeLoading();
        };
    };
    const handleSubmitTwo = async (e) => {
        e.preventDefault();
        openLoading();
        try {
            await stepTwo(state);
            navigate('/home');
        } catch (error) {
            setModalCont({
                title: 'ERROR',
                text: error.message
            });
            openModal();
            closeLoading();
        };
    };
    const hidePassword = () => {
        const inputPass = document.getElementById('password');
        if (inputPass.type === 'password') inputPass.type = 'text';
        else inputPass.type = 'password';
        setState({ ...state, hidePass: !state.hidePass })
    };
    const form = (
        <div>
            <form className={styles.form}>
                <div className={styles.title}>LOGIN</div>
                <div>
                    <div className={styles.formGroup}>
                        <InputText
                            type="text"
                            id="username"
                            name="username"
                            onChange={handleChange}
                            placeholder="Username"
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <div className={styles.inputGroup}>
                            <InputText
                                id='password'
                                name="password"
                                type="password"
                                onChange={handleChange}
                                placeholder="Password"
                            />
                            <div className={styles.eyeCon}>
                                { !state.hidePass ? 
                                    <RemoveRedEyeIcon onClick={hidePassword} className={styles.iconHide}/>
                                    :
                                    <VisibilityOffIcon onClick={hidePassword} className={styles.iconHide}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.formGroup}>
                        <Link className={styles.helper} to="#" onClick={openModalRP}>Forgot username or password?</Link>
                    </div>
                </div>
                <div className={styles.formButtonContainer}>
                    <Button 
                        type="submit" 
                        label="SIGN IN"
                        size="big"
                        styleButton="main"
                        onClick={handleSubmit}
                    />
                </div>
            </form>
        </div>
    );
    const formTwo = (
        <div>
            <form className={styles.formTwo}>
                <div className={styles.title}>LOGIN</div>
                <div>
                    <div className={styles.formGroup}>
                        <InputText 
                            type="text"
                            name="code"
                            id="code"
                            value={state.code}
                            onChange={handleChange}
                            placeholder="Enter code" 
                        />
                    </div>
                    <div className={styles.formButtonContainer}>
                        <Link className={styles.helper} to="#" onClick={handleSubmit}>Re-send code</Link>
                    </div>
                </div>
                <div className={styles.formButtonContainer}>
                    <Button
                        type="submit"
                        label="SIGN IN"
                        size="big"
                        styleButton="main"
                        onClick={handleSubmitTwo}
                    />
                </div>
            </form>
        </div>
    );
    return (
        <div className={styles.con}>
            {firstStep ? formTwo : form}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        user: state.usersReducer.userData
    };
};
export default connect(mapStateToProps, { isAuth })(LoginForm);