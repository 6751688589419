
import ModalPage from '../modalPage-base/ModalPage';
import styles from './AlertModal.module.css';

const AlertModal = (props) => {
    const {
        title,
        text,
        isOpen,
        importance,
        closeModal
    } = props;
    return (
        <ModalPage isOpen={isOpen} importance={importance} id="alert-modal">
            <div className={styles.alertModalCon}>
                <span className='title-alert' id='title-alert'>{title}</span>
                <span className={styles.textalert} id='text-alert'>{text}</span>
                <button className='btn-modalPages' id='acept-button-alert' onClick={closeModal}>OK</button>
            </div>
        </ModalPage>
    );
};

export default AlertModal;