import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { isAuth } from "../../actions/usersActions";

const IsAuth = (props) => {
    const { Component, isAuth, user } = props;
    const location = useLocation();
    const navigate = useNavigate();
    
    const verifyAuth = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('login');
        } else {
            try {
                await isAuth();
            } catch (error) {
                if (error.message === '503') navigate('/404');
                localStorage.removeItem('token');
                navigate('login');
            };
        }
    };

    useEffect(() => {
        verifyAuth();
    }, [location.pathname]);

    return (
        <>
            { user.isLogged && location.pathname !== '/' ? 
                <Component />
                : <Navigate to="/login" />
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.usersReducer.userLogged
    };
};

export default connect(mapStateToProps, { isAuth })(IsAuth);