import Axios from "../config/Axios";

export const GET_COURSES = "GET_COURSES";
export const CREATE_COURSE = "CREATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const REMOVE_GROUP_FROM_COURSE = "REMOVE_GROUP_FROM_COURSE";
export const GET_COURSE = "GET_COURSE";
export const SET_COURSE_DATA = "SET_COURSE_DATA";
export const ADD_GROUPS_TO_COURSE = "ADD_GROUPS_TO_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const FILTER_COURSES = "FILTER_COURSES";
export const COUNT_COURSES = "COUNT_COURSES";
export const FILTER_GROUPS_BY_COURSE = "FILTER_GROUPS_BY_COURSE";
export const GET_GROUPS_BY_COURSE = "GET_GROUPS_BY_COURSE";
export const CLEAR_COURSE = "CLEAR_COURSE";
export const CLEAR_COURSE_INFO = "CLEAR_COURSE_INFO";

const { REACT_APP_API_URL } = process.env;

const getCourses = (arg = {}) => {
  const { productIdProduct, active } = arg;
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/manageCourses/getCourses`, {
      params: { productIdProduct, active },
    })
      .then((response) => {
        dispatch({
          type: GET_COURSES,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response ? error.response.data.message : error);
      });
  };
};

const createCourse = (data) => {
  return (dispatch) => {
    return Axios.post(`${REACT_APP_API_URL}/manageCourses/createCourse`, data)
      .then((response) => {
        dispatch({
          type: CREATE_COURSE,
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const deleteCourse = (idGroup) => {
  return (dispatch) => {
    return Axios.delete(
      `${REACT_APP_API_URL}/manageCourses/deleteCourse/${idGroup}`
    )
      .then((response) => {
        dispatch({
          type: DELETE_COURSE,
          payload: response.data,
        });
        return true;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const getCourse = (arg) => {
  const { idCourse } = arg;
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/manageCourses/getCourse/${idCourse}`)
      .then((response) => {
        dispatch({
          type: GET_COURSE,
          payload: response.data.response,
        });
        return response.data.response;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const updateCourse = (data) => {
  return (dispatch) => {
    return Axios.put(`${REACT_APP_API_URL}/manageCourses/updateCourse`, data)
      .then((response) => {
        dispatch({
          type: UPDATE_COURSE,
          payload: response.data,
        });
        return true;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const filterCourses = (str) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_COURSES,
      payload: str,
    });
  };
};

const clearCourseInfo = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_COURSE_INFO,
      payload: "",
    });
  };
};

const countCourses = () => {
  return (dispatch) => {
    return Axios.get(`${REACT_APP_API_URL}/manageCourses/countCourses`)
      .then((response) => {
        dispatch({
          type: COUNT_COURSES,
          payload: response.data.response,
        });
        return true;
      })
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  };
};

const addGroupsToCourses = ({ groups }) => {
  return (dispatch) => {
    dispatch({
      type: ADD_GROUPS_TO_COURSE,
      payload: groups,
    });
  };
};

const removeGroupFromCourse = ({ group }) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_GROUP_FROM_COURSE,
      payload: group,
    });
  };
};

export {
  getCourses,
  deleteCourse,
  getCourse,
  updateCourse,
  createCourse,
  filterCourses,
  countCourses,
  clearCourseInfo,
  addGroupsToCourses,
  removeGroupFromCourse,
};
