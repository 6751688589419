import { connect } from "react-redux";
import AdminView from "./adminView";
import LearnerView from "./learnerView";

const Reports = (props) => {
  const { userPermissions } = props;

  if (userPermissions.includes(15)) {
    return <AdminView />;
  } else {
    return <LearnerView />;
  }
};

const mapStateToProps = (state) => {
  return {
    userPermissions: state.usersReducer.userLogged.permissions,
  };
};

export default connect(mapStateToProps, {})(Reports);
