import { useEffect, useState } from "react";

import initialImg from '../../../assets/icons/Icon_Dark@2x-8.png';
import styles from '../index.module.css';

const File = (props) => {
    const {
        file,
        initial,
        index
    } = props;
    const [state, setState] = useState({
        rotation: 0,
    });
    useEffect(() => {
        if (index !== 1) {
            setState({ ...state, rotation: Math.floor(Math.random() * 20) - 10 });
        }
    }, []);
    const rotate = `rotate(${state.rotation}deg)`;
    if (initial) {
        return (
            <img src={initialImg} alt="preview"  className={styles.images}/>
        );
    } else if (file.file_type === 'image/png' || file.file_type === 'image/jpeg' || file.file_type === 'image/jpg' || file.file_type === 'image/svg+xml') {
        return (
            <img src={file.file_path} alt="preview"  className={styles.images} style={{transform: rotate}}/>
        );
    } else if (file.file_type === 'application/pdf') {
        return (
            <embed src={file.file_path} width='200' height='250'  type="application/pdf" className={styles.images} style={{transform: rotate}}/>
        )
    }
};

export default File;