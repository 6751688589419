import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRole, updateRole } from "../../../../../actions/manageUsersActions";
import alert from "../../../../../components/AlertModal/alertModal";
import confirm from "../../../../../components/ConfirmModal/confirm";
import "./edit_role_button.css";

const EditRoleButton = ({ updateRole, createRole, rolePermissions, permissionsSelected }) => {
    const [roleName, setRoleName] = useState("");
    const navigate = useNavigate();
    const handleName = (e) => {
        setRoleName(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (rolePermissions.name && roleName === "") {
            const response = await confirm("CONFIRM", "Are you sure you want to update this role?");
            if (response) {
                try {
                    const role = {
                        idRole: rolePermissions.idRole,
                        permissionsIds: permissionsSelected,
                    };
                    await updateRole(role);
                    alert("SUCCESSFULLY", "The role has been updated");
                    navigate('../roles-permissions');
                } catch (error) {
                    alert("ERROR", error.message);
                }
            }
        } else {
            const response = await confirm("CONFIRM", `Are you sure you want to create ${roleName} role?`);
            if (response) {
                try {
                    const role = {
                        name: roleName,
                        permissionsIds: permissionsSelected,
                    };
                    await createRole(role);
                    alert("SUCCESSFULLY", "Role has been created");
                    navigate('../roles-permissions');
                } catch (error) {
                    alert("ERROR", error.message);
                }
            }
        }
    };
    return (
        <div className="IBcon">
            <input type="text" placeholder="ROLE NAME" value={roleName} className="input-cu input-name-rol" onChange={(e) => handleName(e)}/>
            <button className="bas-button" onClick={e => handleSubmit(e)}>Save</button>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        permissionsSelected: state.manageUsers.permissionsSelected,
        rolePermissions: state.manageUsers.rolePermissions,
    };
};

export default connect(mapStateToProps, { createRole, updateRole })(EditRoleButton);