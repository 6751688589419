var defaultValue = {
  promise: null,
};
export var confirmState = defaultValue;

const alert = (title, text, importance, callback) => {
  const titleAlert = document.getElementById("title-alert");
  titleAlert.innerHTML = title;
  const textAlert = document.getElementById("text-alert");
  textAlert.innerHTML = text;
  let buttonAcept = document.getElementById("alert-modal");
  buttonAcept.className = "ModalPage-B MP-B-open";
  importance && buttonAcept.classList.add(importance);
  document
    .getElementById("acept-button-alert")
    .addEventListener("click", () => {
      buttonAcept.className = "ModalPage-B";
      confirmState.promise.resolve(true);
      titleAlert.innerHTML = "";
      textAlert.innerHTML = "";
    });
  var promise = new Promise((resolve, reject) => {
    callback && callback();
    confirmState.promise = { resolve, reject };
  });
  return promise;
};

export default alert;
