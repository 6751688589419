
import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { getSessions, deleteSessions } from "../../../actions/manageSessionsAction";
import { closeLoading, openLoading } from "../../../components/loadingModal/loading";
import { useParams, useNavigate } from "react-router-dom";
import AttendeesTableModal from "./attendees_table_modal/attendees_table_modal";
import confirm from "../../../components/ConfirmModal/confirm";
import trashIcon from "../../../assets/icons/Trash_Icon.png";
import editIcon from "../../../assets/icons/edit-icon.png";
import infoIcon from "../../../assets/icons/info-icon.png";
import alert from "../../../components/AlertModal/alertModal";
import styles from "./session_tables.module.css";
import Table from "../../../components/Table";
import moment from "moment/moment";


const UpcomingSessionsTable = (props) => {
    const {
        user,
        sessions,
        getSessions,
        deleteSessions,
    } = props;
    const [selectedSession, setSelectedSession] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    const initialData = async () => {
        openLoading();
        try {
            await getSessions();
        } catch (error) {
            alert("ERROR", error.message);
        } finally {
            closeLoading();
        };
    };

    useEffect(() => {
        initialData();
    }, [params]);

    const handleOpen = (row) => {
        setSelectedSession(row)
        setIsOpen(true)
    };
    const handleClose = () => {
        setIsOpen(false);
        setSelectedSession(null);
    };
    const handleDelete = async (row) => {
        const response = await confirm("Confirm", "Are you sure you want to delete this session?")
        if (!response) return
        try {
            await deleteSessions(row.idMultiplayer)
            alert("Successfully", "Session has been deleted")
            await getSessions();
        } catch (error) {
            alert("ERROR", error.message);
        };
    };
    const role = user.role;

    const columns = useMemo(
        () => [
          {
            id: 'date',
            Header: () => <div className="table-title">DATE</div>, 
            accessorFn: (row) => moment(row.dateSession).format("MMM DD, YYYY"),
            header: 'Date',
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 80,
          },
          {
            id: 'time',
            Header: () => <div className="table-title">START TIME</div>, 
            accessorFn: (row) => moment(row.dateSession).format("hh:mm A"),
            header: 'Start Time',
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 20,
          },
          {
            id: 'product',
            header: 'Product',
            Header: () => <div className="table-title">PRODUCT</div>, 
            accessorFn: (row) => (row.product.name),
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 100,
          },
          {
            header: 'Title',
            accessorKey: 'title',
            Header: () => <div className="table-title">TITLE</div>, 
            enableColumnFilter: false,
            enableSorting: false,
            size: 180,
            grow: true,
          },
          {
            header: 'Session Code',
            accessorKey: 'sessionCode',
            Header: () => <div className="table-title">ROOM NUMBER</div>,
            enableColumnFilter: false,
            enableSorting: false, 
            grow: false,
            size: 20,
          },
          {
            id: 'attendees',
            header: 'Attendees',
            Header: () => <div className="table-title">ATTENDEES</div>, 
            accessorFn: (row) => (
                <div className="actions-container">
                    <div>{row.users.length}</div>
                    <div className={styles.rowInfoContainer}>
                        <img src={infoIcon} alt="Information about attenders" className={styles.infoIcon} onClick={() => handleOpen(row)}/>
                    </div>
                </div>
            ),
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 20,
          },
          {
            id: 'actions',
            header: 'Actions',
            Header: () => <div className="table-title">ACTIONS</div>, 
            accessorFn: (row) => (
                <div className="actions-container">
                    { (user.permissions.includes(6) || user.idUser === row.userIdUser) && <img src={editIcon} alt="Edit Session" className={styles.actionIcon} onClick={()=>navigate(`./edit-session/${row.idMultiplayer}`)}/>}
                    { (user.permissions.includes(6) || user.idUser === row.userIdUser) && <img src={trashIcon} alt="Delete Session" className={styles.actionIcon} onClick={() => handleDelete(row)}/>}
                </div>
            ),
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 20,
          },
        ],
        [],
    );
  
    return (
        <div className={styles.tableWrapper}>
            <div className='table-container'>
                <Table data={sessions} height="54vh" columns={columns} hiddenColumns={{actions: role !== 'Learner'}}/>
            </div>
            { isOpen && selectedSession && 
                <AttendeesTableModal users={selectedSession.users} close={handleClose} title="ATTENDEES"/>
            }
        </div>
    )

};

const mapStateToProps = (state) => {
    return {
        sessions: state.manageSessionReducer.sessions.upcomingSessions,
        user: state.usersReducer.userLogged,
    };
};

export default connect(mapStateToProps, { getSessions, deleteSessions })(UpcomingSessionsTable);