import { useNavigate, useParams } from "react-router-dom";
import {
  createSession,
  getRoomNumber,
  getSession,
} from "../../../actions/manageSessionsAction";
import { listProducts } from "../../../actions/productsActions";
import { useEffect, useState } from "react";
import styles from "./session_form.module.css";
import {
  closeLoading,
  openLoading,
} from "../../../components/loadingModal/loading";
import alert from "../../../components/AlertModal/alertModal";
import { connect } from "react-redux";
import icon from "../../../assets/icons/session_icon.png";
import { getCourses } from "../../../actions/manageCoursesActions";
import { getUsers } from "../../../actions/usersActions";
import { getGroups } from "../../../actions/manageGroupsActions";
import groupIcon from "../../../assets/icons/Group_Button_Session.png";
import userIcon from "../../../assets/icons/User_Button_Session.png";
import InputText from "../../../components/InputText/index.jsx";

// import DatePicker from "react-datepicker";
import DatePicker from "../../../components/DatePicker";
import verifyText from "../../../util/validations/text.js";
import "react-datepicker/dist/react-datepicker.css";
import MaterialSelect from "../../../components/MaterialSelect/index.jsx";

const modes = [
  { label: "Guided", id: "Guided" },
  { label: "Assessment", id: "Assessment" },
];

const SessionForm = (props) => {
  const {
    getSession,
    listProducts,
    getCourses,
    getUsers,
    getGroups,
    createSession,
    getRoomNumber,
  } = props;
  const navigate = useNavigate();
  const idMultiplayer = useParams().multiplayerId;
  const initialData = {
    session: {
      title: "",
      mode: "",
      productIdProduct: "",
      userIdUser: "",
      dateSession: new Date(),
      groups: [],
      attendees: [],
      courseIdCourse: "",
      sessionCode: "",
      totalAttendees: [],
    },
    products: [],
    faculties: [],
    groups: [],
    groupsCopy: [],
    attendees: [],
    attendeesCopy: [],
    courses: [],
    showGroups: true,
    showAttendees: false,
    search: "",
  };
  // state where the data will be stored
  const [state, setState] = useState(initialData);
  const [errors, setErrors] = useState([]);
  const getInitialData = async () => {
    openLoading();
    try {
      const products = await listProducts({ active: true });
      const faculties = await getUsers({ faculty: true });
      setState({ ...state, products: products, faculties: faculties });
      if (idMultiplayer) {
        const session = await getSession(idMultiplayer);
        const courses = await getCourses({
          productIdProduct: session.productIdProduct,
          active: true,
        });
        const attendees = await getUsers({
          productIdProduct: session.productIdProduct,
        });
        const groups = await getGroups({
          productIdProduct: session.productIdProduct,
        });

        setState({
          ...state,
          session: {
            title: session.title,
            mode: session.mode,
            productIdProduct: session.productIdProduct,
            userIdUser: session.userIdUser,
            dateSession: session.dateSession,
            attendees: session.users,
            courseIdCourse: session.course ? session.course.idCourse : "",
            sessionCode: session.sessionCode,
            groups: [],
            totalAttendees: session.users,
          },
          products: products,
          faculties: faculties,
          groups: groups,
          groupsCopy: groups,
          attendees: attendees.filter(
            (attendee) =>
              !session.users.some((x) => x.idUser === attendee.idUser)
          ),
          attendeesCopy: attendees.filter(
            (attendee) =>
              !session.users.some((x) => x.idUser === attendee.idUser)
          ),
          courses: courses,
        });
      }
      closeLoading();
    } catch (error) {
      alert("ERROR", error.message);
    }
  };
  useEffect(() => {
    getInitialData();
    return () => {
      setState(initialData);
    };
  }, []);
  const handleSelectProduct = async (e) => {
    setErrors([]);
    const {
      target: { value },
    } = e;

    openLoading();
    try {
      // api calls
      const courses = await getCourses({
        productIdProduct: value,
        active: true,
      });
      const attendees = await getUsers({ productIdProduct: value });
      const groups = await getGroups({ productIdProduct: value });
      const roomNumber = await getRoomNumber(value);
      // set state
      setState({
        ...state,
        courses: courses,
        attendees: attendees,
        attendeesCopy: attendees,
        groups: groups,
        groupsCopy: groups,
        session: {
          ...state.session,
          productIdProduct: value,
          groups: [],
          attendees: [],
          sessionCode: roomNumber,
        },
      });
    } catch (error) {
      alert("ERROR", error.message);
    } finally {
      closeLoading();
    }
  };
  const handleChangeSession = (e) => {
    setErrors([]);
    setState({
      ...state,
      session: { ...state.session, [e.target.name]: e.target.value },
    });
  };
  const handleSelect = (e, fieldName) => {
    const {
      target: { value },
    } = e;
    setState({ ...state, session: { ...state.session, [fieldName]: value } });
    setErrors([]);
  };
  const handleCheck = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const handleSelectAttendee = (name, id, value) => {
    const findUsers = () => {
      let users = [...state.session.attendees];
      state.session.groups.forEach((group) => {
        group.users.map((user) => {
          if (users.some((x) => x.idUser === user.idUser)) return;
          else users.push(user);
        });
      });
      if (name === "groups") {
        value.users.map((user) => {
          if (users.some((x) => x.idUser === user.idUser)) return;
          else users.push(user);
        });
      } else {
        if (users.some((x) => x.idUser === value.idUser)) return users;
        else users.push(value);
      }
      return users;
    };
    setState({
      ...state,
      [name]: state[name].filter((attendee) => attendee[id] !== value[id]),
      [name + "Copy"]: state[name + "Copy"].filter(
        (attendee) => attendee[id] !== value[id]
      ),
      session: {
        ...state.session,
        [name]: [...state.session[name], value],
        totalAttendees: findUsers(),
      },
    });
  };
  const handleRemoveAttendee = (attendee) => {
    const attendees = state.session.attendees.filter(
      (x) => x.idUser !== attendee.idUser
    );
    const totalAttendees = () => {
      const users = [...attendees];
      state.session.groups.forEach((group) => {
        group.users.map((user) => {
          if (users.some((x) => x.idUser === user.idUser)) return;
          else users.push(user);
        });
      });
      return users;
    };
    setState({
      ...state,
      session: {
        ...state.session,
        attendees: attendees,
        totalAttendees: totalAttendees(),
      },
      attendees: [...state.attendees, attendee],
      attendeesCopy: [...state.attendeesCopy, attendee],
    });
  };
  const handleRemoveGroup = (group) => {
    let groups = state.session.groups.filter(
      (x) => x.idGroup !== group.idGroup
    );
    const getTotalAttendees = () => {
      const users = [...state.session.attendees];
      groups.forEach((group) => {
        group.users.map((user) => {
          if (users.some((x) => x.idUser === user.idUser)) return;
          else users.push(user);
        });
      });
      return users;
    };
    setState({
      ...state,
      session: {
        ...state.session,
        groups: groups,
        totalAttendees: getTotalAttendees(),
      },
      groups: [...state.groups, group],
      groupsCopy: [...state.groupsCopy, group],
    });
  };
  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase();
    setState({
      ...state,
      groups: state.groupsCopy.filter((group) =>
        group.name.toLowerCase().includes(search)
      ),
      attendees: state.attendeesCopy.filter(
        (attendee) =>
          attendee.firstname.toLowerCase().includes(search) ||
          attendee.lastname.toLowerCase().includes(search)
      ),
    });
  };
  const handleSave = async () => {
    const formErrors = verifyText(state.session);
    if (formErrors.length > 0) {
      setErrors(formErrors);
      return;
    }

    openLoading();
    try {
      await createSession(state.session);
      navigate("/manage-sessions");
    } catch (error) {
      alert("ERROR", error.message);
    } finally {
      closeLoading();
    }
  };

  return (
    <div className="box-container-opaque">
      <div className={`entryPage ${styles.container}`}>
        <div className={styles.header}>
          <div
            className={styles.x}
            onClick={() => navigate("/manage-sessions")}
          ></div>
          <img
            src={icon}
            alt="Create Group Session icon"
            className="icon_head"
          />
          <div className={styles.title}>{`${
            idMultiplayer ? "EDIT" : "CREATE"
          } GROUP SESSION`}</div>
          <div className={styles.roomNumber}>
            <div>ROOM NUMBER</div>
            <div>{state.session.sessionCode}</div>
          </div>
        </div>
        <div className={styles.content}>
          <div>
            <div className={styles.optionContainer}>
              <InputText
                type="text"
                placeholder="Session Title"
                name="title"
                value={state.session.title}
                onChange={handleChangeSession}
                errors={errors.filter((error) => error.label === "title")}
              />
            </div>
            <div className={styles.selectContainer}>
              <MaterialSelect
                label="Course"
                formField="courseIdCourse"
                value={state.session.courseIdCourse}
                onChange={(e) => handleSelect(e, "courseIdCourse")}
                options={
                  state.courses
                    ? state.courses.map((course) => ({
                        id: course.idCourse,
                        label: course.name,
                      }))
                    : []
                }
                errors={
                  errors &&
                  errors.filter((error) => error.label === "courseIdCourse")
                    .length > 0
                }
              />
            </div>
            <div className={styles.selectContainer}>
              <MaterialSelect
                label="Mode"
                formField="mode"
                value={state.session.mode}
                onChange={(e) => handleSelect(e, "mode")}
                options={modes || []}
                errors={
                  errors &&
                  errors.filter((error) => error.label === "mode").length > 0
                }
              />
            </div>
            <div className={styles.box}>
              <div>
                <div>
                  <span>Attendees</span>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    name="showAttendees"
                    checked={state.showAttendees}
                    onChange={handleCheck}
                  />
                </div>
                <div>
                  <span>Groups</span>
                  <input
                    type="checkbox"
                    className={styles.checkbox}
                    name="showGroups"
                    checked={state.showGroups}
                    onChange={handleCheck}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className={`in-FLD`}
                    name="search"
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div>
                {state.showGroups &&
                  state.groups.map((group) => {
                    return (
                      <div
                        key={group.idGroup}
                        onClick={() =>
                          handleSelectAttendee("groups", "idGroup", group)
                        }
                      >
                        <img src={groupIcon} alt="group" />
                        <span>{group.name}</span>
                        <span>({group.users.length})</span>
                      </div>
                    );
                  })}
                {state.showAttendees &&
                  state.attendees.map((user) => {
                    return (
                      <div
                        key={user.idUser}
                        onClick={() =>
                          handleSelectAttendee("attendees", "idUser", user)
                        }
                      >
                        <img src={userIcon} alt="user" />
                        <span>
                          {user.firstname} {user.lastname}
                        </span>
                        <div></div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div>
            <div className={styles.selectContainer}>
              <MaterialSelect
                label="Product"
                formField="productIdProduct"
                value={state.session.productIdProduct}
                onChange={handleSelectProduct}
                options={
                  state.products
                    ? state.products.map((product) => ({
                        id: product.idProduct,
                        label: product.name,
                      }))
                    : []
                }
                errors={
                  errors &&
                  errors.filter((error) => error.label === "productIdProduct")
                    .length > 0
                }
              />
            </div>
            <div className={styles.selectContainer}>
              <MaterialSelect
                label="Assign Host (Leave blank if you are the host)"
                formField="userIdUser"
                value={state.session.userIdUser}
                onChange={(e) => handleSelect(e, "userIdUser")}
                options={
                  state.faculties
                    ? state.faculties.map((faculty) => ({
                        id: faculty.idUser,
                        label: faculty.name,
                      }))
                    : []
                }
                errors={
                  errors &&
                  errors.filter((error) => error.label === "userIdUser")
                    .length > 0
                }
              />
            </div>
            <div className={styles.optionContainer}>
              <DatePicker
                selected={
                  state.session.dateSession
                    ? new Date(state.session.dateSession)
                    : new Date()
                }
                onChange={(date) => {
                  setState({
                    ...state,
                    session: { ...state.session, dateSession: date },
                  });
                }}
                showTimeSelect={true}
                // timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="MMM d, yyyy h:mm aa"
                customInput={
                  <input
                    type="text"
                    className={`input_two ${styles.date}`}
                    placeholder="Select Date and Time (optional)"
                  />
                }
                placeholder="Select Date and Time"
                errors={
                  errors &&
                  errors.filter((error) => error.label === "dateSession")
                    .length > 0
                }
              />
              {errors &&
                errors.filter((error) => error.label === "dateSession").length >
                  0 && (
                  <div className={styles.error_wrapper_date_picker}>
                    {errors
                      .filter((error) => error.label === "dateSession")
                      .map((option, index) => {
                        return (
                          <div key={index} className={styles.error}>
                            {option.error}
                          </div>
                        );
                      })}
                  </div>
                )}
            </div>
            <div className={styles.box}>
              <div className={styles.secondHeader}>
                <span>Attendees</span>
                <span>{state.session.totalAttendees.length}</span>
              </div>
              <div className={styles.attendees}>
                {state.session.attendees &&
                  state.session.attendees.map((attendee) => {
                    return (
                      <div key={attendee.idUser}>
                        <img src={userIcon} alt="user" />
                        <span>
                          {attendee.firstname} {attendee.lastname}
                        </span>
                        <div></div>
                        <div onClick={() => handleRemoveAttendee(attendee)}>
                          x
                        </div>
                      </div>
                    );
                  })}
                {state.session.groups &&
                  state.session.groups.map((group) => {
                    return (
                      <div key={group.idGroup}>
                        <img src={groupIcon} alt="Group" />
                        <span>{group.name}</span>
                        <span>({group.users.length})</span>
                        <div onClick={() => handleRemoveGroup(group)}>x</div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.saveContainer}>
          <button className="bigButton_clear" onClick={handleSave}>
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  getSession,
  listProducts,
  getCourses,
  getUsers,
  getGroups,
  createSession,
  getRoomNumber,
})(SessionForm);
