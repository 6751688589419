import {
  createUser,
  getUser,
  setUserInfo,
  updateUser,
} from "../../../actions/usersActions";
import { getAllRoles, deleteUser } from "../../../actions/manageUsersActions";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import defaultPicture from "../../../assets/icons/Profile_Default.png";
import confirm from "../../../components/ConfirmModal/confirm";
import alert from "../../../components/AlertModal/alertModal";
import {
  openLoading,
  closeLoading,
} from "../../../components/loadingModal/loading";
import styles from "./user_form.module.css";
import verifyText from "../../../util/validations/text";
import InputText from "../../../components/InputText";
import Button from "../../../components/Button";
import DatePicker from "../../../components/DatePicker";
import ResetPasswordModal from "./reset_password_modal/reset_password_modal";
import MaterialSelect from "../../../components/MaterialSelect";

const genders = [
  { label: "Male", id: "male" },
  { label: "Female", id: "female" },
  { label: "Do not specify", id: "not_specified" },
];

const CreateEditUser = (props) => {
  const { permissions, userData, deleteUser } = props;

  let initialData = {
    firstname: "",
    lastname: "",
    birthday: null,
    gender: "",
    username: "",
    roleIdRole: "",
    profilePicture: "",
    email: "",
  };

  const [formData, setFormData] = useState(userData || initialData);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.firstname) {
      setFormData(userData);
    }
  }, [userData]);

  useEffect(() => {
    userId && props.getUser(userId);
    props.getAllRoles({ column: "name", order: "asc" });
  }, [userId]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = verifyText(formData);
    if (formErrors.length > 0) {
      setErrors(formErrors);
      return;
    }

    let confirmation = userId
      ? await confirm("CONFIRM", "Are you sure you want to update this user?")
      : await confirm("CONFIRM", "Are you sure you want to create this user?");
    if (!confirmation) return;
    openLoading();
    try {
      if (userId) {
        await props.updateUser(formData);
        alert("SUCCESS", "User updated successfully", "highImportance");
        navigate("/manage-users");
        props.setUserInfo({});
      } else {
        await props.createUser(formData);
        alert("SUCCESS", "User created successfully", "highImportance");
        navigate("/manage-users");
        props.setUserInfo({});
      }
    } catch (error) {
      alert("ERROR", error.message, "highImportance");
    }
    closeLoading();
  };

  const handleChangeRole = (e) => {
    const {
      target: { value },
    } = e;
    setFormData({
      ...formData,
      roleIdRole: value,
    });
  };

  const handleChangeGender = (e) => {
    const {
      target: { value },
    } = e;
    setFormData({
      ...formData,
      gender: value,
    });
  };

  const handleDelete = async (e) => {
    let con = await confirm(
      "CONFIRM",
      "Are you sure you want to delete this user?"
    );
    if (!con) return;
    openLoading();
    try {
      await deleteUser(userId);
      closeLoading();
      navigate("/manage-users");
      // } = userData;
      alert(
        "SUCCESSFULLY",
        `User ${formData.firstname} ${formData.lastname} has been deleted`,
        "highImportance"
      );
    } catch (error) {
      alert("ERROR", error, "highImportance");
    }
  };

  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);
  };

  const formattedRoles = props.roles.map((role) => {
    return {
      id: role.idRole,
      label: role.name,
    };
  });

  return (
    <div className={`box-container-opaque`}>
      <ResetPasswordModal
        show={openPasswordModal}
        handleClose={() => setOpenPasswordModal(false)}
        user={formData}
      />
      <div className={`box-container entryPage box-entry ${styles.container}`}>
        <div className={styles.header}>
          <div
            className={styles.x}
            onClick={() => {
              props.setUserInfo({});
              navigate("/manage-users");
            }}
          />
          <div className={styles.pictureContainer}>
            <img
              src={
                formData.profilePicture
                  ? formData.profilePicture
                  : defaultPicture
              }
              alt="User Profile"
              className={styles.picture}
              id="profile_picture_ManageUsers"
            />
          </div>
        </div>
        <form className={styles.form}>
          <InputText
            type="text"
            name="firstname"
            placeholder="First Name"
            value={formData.firstname}
            onChange={handleChange}
            errors={errors.filter((error) => error.label === "firstname")}
          />
          <InputText
            type="text"
            name="lastname"
            placeholder="Last Name"
            value={formData.lastname}
            onChange={handleChange}
            errors={errors.filter((error) => error.label === "lastname")}
          />
          <InputText
            type="text"
            lassName="input_two"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleChange}
            errors={errors.filter((error) => error.label === "username")}
          />
          <MaterialSelect
            label="Role"
            formField="roleIdRole"
            value={formData.roleIdRole}
            onChange={handleChangeRole}
            options={formattedRoles}
            errors={errors.filter((error) => error.label === "roleIdRole")}
          />
          <DatePicker
            name="birthday"
            placeholder="Select Date of Birth"
            format="MMMM D, yyyy"
            selected={formData.birthday}
            hideTime={true}
            showTimeSelect={false}
            onChange={handleChange}
          />
          {errors &&
            errors.filter((error) => error.label === "birthday").length > 0 && (
              <div className={styles.error_wrapper}>
                {errors
                  .filter((error) => error.label === "birthday")
                  .map((option, index) => {
                    return (
                      <div key={index} className={styles.error}>
                        {option.error}
                      </div>
                    );
                  })}
              </div>
            )}
          <MaterialSelect
            label="Gender"
            formField="gender"
            value={formData.gender}
            onChange={handleChangeGender}
            options={genders}
          />
          <InputText
            type="text"
            name="email"
            placeholder="Email"
            className="input_two"
            value={formData.email}
            onChange={handleChange}
            errors={errors.filter((error) => error.label === "email")}
          />
          <div />
        </form>
        <div className={styles.buttonContainer}>
          {userId && permissions.includes(10) && (
            <Button
              label="DELETE USER"
              styleButton="normal"
              size="small"
              className={`basic_button_blue ${styles.button}`}
              onClick={handleDelete}
            />
          )}
          {userId && permissions.includes(13) && (
            <Button
              label="UPDATE USER PASSWORD"
              styleButton="normal"
              size="small"
              className={`basic_button_blue ${styles.button}`}
              onClick={handleOpenPasswordModal}
            />
          )}
          <div className={styles.actionButton}>
            <Button
              label="SAVE"
              styleButton="light"
              size="medium"
              className={`basic_button_blue ${styles.button} ${styles.saveButton}`}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.usersReducer.userData,
    roles: state.manageUsers.roles,
    userUpdated: state.usersReducer.userUpdated,
    permissions: state.usersReducer.userLogged.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(createUser(user)),
    getUser: (userId) => dispatch(getUser(userId)),
    getAllRoles: (order) => dispatch(getAllRoles(order)),
    setUserInfo: async (userData) => await dispatch(setUserInfo(userData)),
    updateUser: (user) => dispatch(updateUser(user)),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateEditUser);
