

var defaultValue = {
    promise: null
}
export var confirmState = defaultValue;

const confirm = (title, text, importance) => {
    var promise = new Promise((resolve, reject) => {
        var response = [];
        const titleConfirm = document.getElementById('title-confirm');
        titleConfirm.innerHTML = title;
        const textConfirm = document.getElementById('text-confirm');
        textConfirm.innerHTML = text;
        let buttonAcept = document.getElementById('modal');
        buttonAcept.className = 'ModalPage-B MP-B-open';
        importance && buttonAcept.classList.add(importance);
        document.getElementById('acp-btn').addEventListener('click', () => {
            buttonAcept.className = 'ModalPage-B'
            resolve(true);
            titleConfirm.innerHTML = '';
            textConfirm.innerHTML = '';
        })
        document.getElementById('cnl-btn').addEventListener('click', () => {
            buttonAcept.className = 'ModalPage-B'
            resolve(false);
            titleConfirm.innerHTML = '';
            textConfirm.innerHTML = '';
        })
    })
    return promise;
};

export default confirm;