
import xIcon from "../../../../assets/box/close_clear.png";
import styles from "../session_tables.module.css";

const AttendeesTableModal = (props) => {
    const {
        users,
        close,
        title
    } = props;
    return (
        <div className={styles.modalContainer}>
            <img src={xIcon} alt="Close" className={styles.xIcon} onClick={close}/>
            <div className={styles.headerModal}>{title}</div>
            <div className={`table-rows ${styles.rowPop}`}>
                {
                    users.map((user, i) => {
                        const dark = i % 2 === 0;
                        return (
                                <div key={user.idUser} className={`content-box gray ultimo ${styles.rowModal} ${dark && styles.dark}`}>{user.firstname + ' ' + user.lastname}</div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default AttendeesTableModal;