
import { useMemo } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/Table";
import moment from "moment/moment";

const roleList = [
    'Admin',
    'Faculty',
    'Learner',
];

const ReportsTable = (props) => {
    const { reports } = props;
    const columns = useMemo(
        () => [
          {
            id: 'username',
            header: 'Username',
            Header: () => <div className="table-title">USERNAME</div>, 
            accessorFn: (row) => <Link className="li-t" to={`Details/${row.username}/${row.idProduct}`}>{row.username}</Link>,
            filterFn: (row, id, filterValue) => {
                return row.original.username.toLowerCase().includes(filterValue.toLowerCase())
            },
            size: 50,
          },
          {
            accessorKey: 'firstname',
            header: 'First Name',
            Header: () => <div className="table-title">FIRST NAME</div>, 
            filterVariant: 'text',
            size: 20,
          },
          {
            accessorKey: 'lastname',
            header: 'Last Name',
            Header: () => <div className="table-title">LAST NAME</div>, 
            filterVariant: 'text',
            size: 20,
          },
          {
            id: 'role',
            header: 'Role',
            Header: () => <div className="table-title">ROLE</div>, 
            accessorFn: (row) => (row.role),
            filterVariant: 'select',
            filterSelectOptions: roleList, //custom options list 
            size: 10,
          },
          {
            id: 'progress',
            header: 'Progress',
            Header: () => <div className="table-title">PROGRESS</div>, 
            accessorFn: (row) => `${((row.modulesCompleted * 100) / row.modules).toFixed(2)}%`,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            },
            size: 10,
          },
          {
            id: 'lastAccess',
            header: 'Last Access',
            Header: () => <div className="table-title">LAST ACCESS</div>, 
            accessorFn: (row) => row.lastAccess ? moment(row.lastAccess).format("MMM DD, YYYY") : '',
            filterVariant: 'text',
            size: 20,
          },
        ],
        [],
    );

    return <Table data={reports} height="45vh" columns={columns}/>;
};

export default ReportsTable;