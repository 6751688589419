import { useEffect } from "react";
import { connect } from "react-redux";
import { getReportByUser } from "../../actions/reportsActions";
import moment from "moment";
import Select from "../../components/Select/Select";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import icon from "../../assets/icons/Reports_Icons.png";
import csvIcon from "../../assets/icons/ExportCSV.png";
import styles from "./index.module.css";
import ReportsTableLearner from "./reports_table_learner/reports_table_learner";
import { listProducts } from "../../actions/productsActions";

const LearnerView = (props) => {
  const { getReportByUser, reportUser, userData, listProducts, products } =
    props;

  useEffect(() => {
    listProducts({ active: true, userProducts: true });
  }, []);

  const name = userData.firstname + " " + userData.lastname;
  const timeSpent = Math.abs(reportUser.timeSpent / 60).toFixed(2);
  const averageTime =
    timeSpent > 0 ? (timeSpent / reportUser.reportUser.length).toFixed(2) : 0;
  const lastAccess = moment(reportUser.lastAccess).format("MMM DD, YYYY");

  const handleSelect = (e) => {
    getReportByUser({
      username: userData.username,
      idProduct: e.value.idProduct,
    });
  };

  return (
    <div className={`box-container ${styles.container2}`}>
      <ArrowGoBack />
      <div className={styles.header2}>
        <img src={icon} alt="Icon Details" className="icon_head" />
        <div className={styles.nameCon}>
          <div className={styles.name}>{name}</div>
          <div>{reportUser.user.username}</div>
        </div>
        <div className={styles.textGroup}>
          <div className={styles.title}>TOTAL SESSIONS</div>{" "}
          <div className={styles.subtitle}>{reportUser.reportUser.length}</div>
          <div className={styles.title}>TOTAL TIME SPENT (MINS)</div>{" "}
          <div className={styles.subtitle}>{timeSpent}</div>
          <div className={styles.title}>
            AVERAGE TIME PER SESSION (MINS)
          </div>{" "}
          <div className={styles.subtitle}>{averageTime}</div>
        </div>
        <div className={styles.textGroup2}>
          <div className={styles.title}>LAST ACCESS DATE</div>{" "}
          <div className={styles.subtitle}>
            {lastAccess === "Invalid date" ? "not date" : lastAccess}
          </div>
          <div className={styles.title}>GUIDED ATTEMPTS</div>{" "}
          <div className={styles.subtitle}>100%</div>
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.selectCon}>
          <Select
            name="product"
            options={products}
            value={1}
            title="Select Product"
            onChange={handleSelect}
          />
        </div>
        <button className={`button_dark_1 ${styles.exportBtn}`}>
          <img
            src={csvIcon}
            alt="Export csv"
            className={`icon_btn_1 ${styles.imgButton}`}
          />
          <div className="text_btn_dark_1">EXPORT CSV</div>
        </button>
      </div>
      <ReportsTableLearner />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reportUser: state.metricIndividualReducer.reportUser,
    userData: state.usersReducer.userLogged,
    products: state.productsReducer.allProducts,
  };
};

export default connect(mapStateToProps, { getReportByUser, listProducts })(
  LearnerView
);
