import { useMemo } from "react";
import Table from "../../../components/Table";


const UsersTable = ({users}) => {

    const columns = useMemo(
        () => [
          {
            id: 'name',
            Header: () => <div className="table-title">INVITED (NAME)</div>, 
            accessorFn: (row) => `${row.firstname} ${row.lastname}`,
            header: 'Name',
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            id: 'attendance',
            header: 'Attendance',
            Header: () => <div className="table-title">ATTENDANCE</div>, 
            accessorFn: (row) => (row.multiplayer_details.attendance || 'error registry'),
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            id: 'startTime',
            header: 'Start Time',
            Header: () => <div className="table-title">START TIME</div>, 
            accessorFn: (row) => (row.startTime || '12:00 PM'),
            enableColumnFilter: false,
            enableSorting: false,
          },
          {
            id: 'timeSpend',
            header: 'Time Spend',
            Header: () => <div className="table-title">TIME SPEND (MM:SS)</div>, 
            accessorFn: (row) => (row.timeSpend || '00:00'),
            enableColumnFilter: false,
            enableSorting: false,
          },
        ],
        [],
    );

    return <Table data={users} height="54vh" columns={columns} textColor="#409ce4"/>;
};

export default UsersTable;