import { getUser, setUserInfo, updateUser, clearUserInfo } from "../../actions/usersActions";
import { getAllRoles, resetPassword } from "../../actions/manageUsersActions";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import confirm from "../../components/ConfirmModal/confirm";
import defaultImg from "../../assets/icons/ProfileImg_default.png";
import miniProfile from "../../assets/icons/boardHeader/Profile@2x-8.png";
import styles from "./index.module.css";
import alert from "../../components/AlertModal/alertModal";
import InputText from "../../components/InputText";
import Button from "../../components/Button";

const RegisterForm = (props) => {
    const {
        getUser,
        getAllRoles,
        resetPassword,
    } = props;
    const {
        firstname,
        lastname,
        // birthday,
        email,
        password,
        repeatPassword,
        // roleIdRole,
        profilePicture,
        // urlAvatar,
        username,
        role
    } = props.userData;
    const [hasErrors, setHasErrors] = useState(false)
    const mediaQuery = window.matchMedia('(max-width: 427px)');
    const roleName = role ? role.name.charAt(0).toUpperCase() + role.name.slice(1) : "";
    const navigate = useNavigate();
    useEffect(() => {
        getUser();
        getAllRoles({ column: "name", order: "asc" });
    }, []);
    // const handleChange = (e) => {
    //     props.setUserInfo({
    //         ...props.userData,
    //         [e.target.name]: e.target.value,
    //     })
    // };
    useEffect(() => {
        return () => {
            props.setUserInfo({});
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!password || !repeatPassword || (repeatPassword && repeatPassword !== password) || (password && password.length < 8)) {
            setHasErrors(true)
            return
        }
        setHasErrors(false)
        try {
            const confirmFun = await confirm('CONFIRM', 'Are you sure you want to update your password?')
            if (!confirmFun) return;
            await resetPassword(props.userData.idUser, password);
            // await props.updateUser(props.userData);
            alert('SUCCESS', 'User updated successfully');
            props.setUserInfo({
                ...props.userData,
                password: "",
                repeatPassword: "",
            });
        } catch (error) {
            alert('ERROR', error);
        };
    };
    const handleChangePass = async (e) => {
        await props.setUserInfo({
            ...props.userData,
            [e.target.name]: e.target.value,
        })
    };
    const validateForm = () => {
        return (
            <div>
                { props.userData.repeatPassword !== props.userData.password && 
                    <div className={styles.errorMessage}>The password and confirm password do not match.</div>
                }
    
                { props.userData && props.userData.password && props.userData.password.length < 8 &&
                    <div className={styles.errorMessage}>Password must be at least 8 characters long.</div>
                }
            </div>
        )
    };
    const customizeAvatar = (e) => {
        e.preventDefault();
        navigate(`../customize-avatar`);
    };
    return (
        <div className="table">
            <div className={`box-container ${styles.principalDis}`}>
                <ArrowGoBack/>
                <div className={styles.chartBlue}>
                    {
                        !mediaQuery.matches &&
                        (
                            <>
                            <div className={styles.name}>{firstname} {lastname}</div>
                            <div className={styles.rolename}>{roleName}</div>
                            </>
                        )
                    }
                </div>
                <div className={styles.pictureChart}>
                    <div className={styles.profilePicture}>
                        <div className={styles.profileCircle}>
                            <img src={ profilePicture ? profilePicture : defaultImg } alt="profile" className={styles.img}/>
                        </div>
                    </div>
                </div>
                <div className={styles.boxDown}>
                    <form className={styles.form}>
                    {
                        mediaQuery.matches &&
                        (
                            <div className={styles.nameCon}>
                                <div className={styles.name}>{firstname} {lastname}</div>
                                <div className={styles.rolename}>{roleName}</div>
                            </div>
                        )
                    }
                        <div className={styles.firstspace}>
                            <div className={styles.keyVal}>
                                <div className={styles.subtitle}>USERNAME: </div>
                                <div className={styles.definition}> {username} </div>
                            </div>
                            <div className={styles.keyVal}>
                                <div className={styles.subtitle}>MAIL: </div>
                                <div className={styles.definition}> {email} </div>
                            </div>
                        </div>
                        <div className={styles.passwordSpace}>
                            <div className={styles.inputGroup}>
                                <InputText
                                    type="password"
                                    name="password"
                                    placeholder={'Password'}
                                    value={password}
                                    onChange={handleChangePass}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <InputText
                                    type="password"
                                    name="repeatPassword"
                                    value={repeatPassword}
                                    placeholder={'Confirm password'}
                                    onChange={handleChangePass}
                                />
                            </div>
                        </div>
                        { hasErrors && validateForm()}
                    </form>
                    <div className={styles.downBox}>
                        <div>
                            <Button 
                                size="medium"
                                styleButton="light"
                                onClick={customizeAvatar}
                                image={<img src={miniProfile} alt="customize avatar" className={styles.imgButton}/>}
                                label="CUSTOMIZE AVATAR"
                            />
                        </div>
                        <div>
                            <Button 
                                size="medium"
                                label="SAVE"
                                styleButton="main"
                                onClick={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        userData: state.usersReducer.userData,
        roles: state.manageUsers.roles,
        userUpdated: state.usersReducer.userUpdated,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(getUser()),
        getAllRoles: (order) => dispatch(getAllRoles(order)),
        setUserInfo: async (userData) => await dispatch(setUserInfo(userData)),
        updateUser: (user) => dispatch(updateUser(user)),
        clearUserInfo: () => dispatch(clearUserInfo()),
        resetPassword: (idUser, password) => dispatch(resetPassword(idUser, password)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);