import { combineReducers } from "redux";
import usersReducer from "./usersReducer"
import devicesReducer from "./devicesReducer";
import productsReducer from "./productsReducer";
import licenseReducer from "./licenseReducer";
import manageUsers from "./manageUsersReducer";
import manageGroups from "./manageGroupsReducer";
import manageCourses from "./manageCoursesReducer";
import client from "./clientReducer";
import metricIndividualReducer from "./reportsReducer";
import manageSessionReducer from "./manageSessionsReducer";
import assets from "./assetsReducer";

const rootReducers = combineReducers({
    usersReducer,
    devicesReducer,
    productsReducer,
    licenseReducer,
    manageUsers,
    manageGroups,
    manageCourses,
    client,
    metricIndividualReducer,
    manageSessionReducer,
    assets
});

export default rootReducers;