import { useMemo } from 'react';
import { Link } from "react-router-dom";
import Table from '../../components/Table';
import switchOff from "../../assets/box/Switch_Off.png";
import switchOn from "../../assets/box/Switch_On.png";
import styles from "./index.module.css";

const roleList = [
    'Admin',
    'Faculty',
    'Learner',
    'Scheduler',
    'Teacher Assistant'
];

const UsersTable = ({ users, activeUser }) => {
    const columns = useMemo(
        () => [
          {
            id: 'username',
            header: 'Username',
            Header: () => <div className="table-title">USERNAME</div>, 
            accessorFn: (row) => <Link className="li-t" to={`edit-user/${row.idUser}`}>{row.username}</Link>,
            filterFn: (row, id, filterValue) => {
                return row.original.username.toLowerCase().includes(filterValue.toLowerCase())
            },
          },
          {
            accessorKey: 'firstname',
            header: 'First Name',
            Header: () => <div className="table-title">FIRST NAME</div>, 
            filterVariant: 'text',
            size: 150,
          },
          {
            accessorKey: 'lastname',
            header: 'Last Name',
            Header: () => <div className="table-title">LAST NAME</div>, 
            filterVariant: 'text',
            size: 150,
          },
          {
            id: 'role',
            header: 'Role',
            Header: () => <div className="table-title">ROLE</div>, 
            accessorFn: (row) => (row.role.name),
            size: 50,
            filterVariant: 'select',
            filterSelectOptions: roleList, //custom options list 
          },
          {
            id: "active",
            header: 'Active',
            Header: () => <div className="table-title">ACTIVE</div>, 
            accessorFn: (row) => (
              <div className="actions-container">
                <img src={row.active ? switchOff : switchOn} alt="activate" className={styles.switch} onClick={() => activeUser(row)} />
              </div>
            ),
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 50,
          },
        ],
        [],
    );
  
    return <Table data={users} height="63vh" columns={columns}/>;
};

export default UsersTable;