import styles from './index.module.css';
import dateIcon from '../../assets/icons/Date-icon.png';
import timeIcon from '../../assets/icons/Hour@2x-8.png';
import "react-datepicker/dist/react-datepicker.css";
import DatePickerInput from "react-datepicker";
import { useEffect, useState } from 'react';
import moment from 'moment';
import Select from '../Select/Select';



const DatePicker = (props) => {
    const {
        selected,
        onChange,
        timeInterval,
        placeholder,
        name,
        showTimeSelect,
        errors,
        format,
        hideTime
    } = props;
    const [date, setDate] = useState(null);
    useEffect(() => {
        if (selected) {
            const dateFormated = moment(selected).format(`${format ?? 'MMMM D, yyyy h:mm a'}`);
            setDate(dateFormated);
        }
    }, [selected]);
    function range(start, end) {
        let result = [];
        for (let i = start; i >= end; i--) {
          result.push(i);
        }
        return result;
    };
    function getYear(date) {
        return new Date(date).getFullYear();
    };
    function getMonth(date) {
        return new Date(date).getMonth();
    };
    const years = range(getYear(new Date()), 1950);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const handleChange = (datePicker) => {
        if (name) {
            onChange({target: {name, value: datePicker}});
        } else {
            onChange(datePicker);
        }
        const dateFormated = moment(datePicker).format(`${format ?? 'MMMM D, yyyy h:mm a'}`);
        setDate(dateFormated);
    };
    return (
        <div>
            <DatePickerInput
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                //   decreaseMonth,
                //   increaseMonth,
                //   prevMonthButtonDisabled,
                //   nextMonthButtonDisabled,
                }) => (
                  <div className={styles.headerContainer}>
                    {/* <button onClick={(e) => {e.preventDefault(); decreaseMonth()}} disabled={prevMonthButtonDisabled}>
                      {"<"}
                    </button> */}
                    <div>
                        <Select
                          value={getYear(date)}
                        //   onChange={({ target: { value } }) => changeYear(value)}
                            onChange={(e) => changeYear(e.value.value)}
                          options={years.map((option) => {
                            return { value: option, name: option };
                          })}
                        />
                    </div>
                    <div>
                        <Select
                          value={months[getMonth(date)]}
                          onChange={({ value: { value } }) => changeMonth(months.indexOf(value))}
                          options={months.map((option) => {
                            return { value: option, name: option };
                          })}
                        />
                    </div>
                  
                    {/* <button onClick={(e) => {e.preventDefault();increaseMonth()}} disabled={nextMonthButtonDisabled}>
                      {">"}
                    </button> */}
                  </div>
                )}
                selected={selected}
                onChange={handleChange}
                showTimeSelect={showTimeSelect}
                timeIntervals={timeInterval}
                timeCaption="Time"
                dateFormat="MMMM D, yyyy h:mm aa"
                customInput={
                    <div className={`${styles.input} ${errors && styles.error}`}>
                        <span className={date ? styles.span : styles.placeholder}>{date??placeholder}</span>
                        <div className={styles.iconsContainer}>
                            <img src={dateIcon} alt="date" className={styles.dateIcon}/>
                            {
                              !hideTime && <img src={timeIcon} alt="time" className={styles.timeIcon}/>
                            }
                            
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default DatePicker;