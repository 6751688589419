import { connect } from "react-redux";
import styles from "./login_lifetime_edit.module.css";
import { useEffect, useState } from "react";
import { getLoginDeviceLifetime, updateLoginDeviceLifetime } from "../../../actions/clientAction";
import { closeLoading, openLoading } from "../../../components/loadingModal/loading";
import alert from "../../../components/AlertModal/alertModal";
import confirm from "../../../components/ConfirmModal/confirm";
import closeIcon from "../../../assets/box/close_dark.png";
import { useNavigate } from "react-router-dom";


const EditDuration = (props) => {
    const { time, getLoginDeviceLifetime, updateLoginDeviceLifetime } = props;
    const [state, setState] = useState({
        hour: 0,
        minute: 0,
        second: 0,
    });
    const getTime = async () => {
        openLoading();
        try {
            const response = await getLoginDeviceLifetime();
            if (response) {
                setState({
                    hour: Math.floor(time / 3600),
                    minute: Math.floor((time % 3600) / 60),
                    second: Math.floor((time % 3600) % 60),
                });
            };
        } catch (error) {
            alert('ERROR', error);
        } finally {
            closeLoading();
        };
    };
    useEffect(() => {
        getTime();
    }, []);
    const handleChange = (e) => {
        if (e.target.value < 0) return;
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const seconds = (state.hour * 3600) + (state.minute * 60) + (state.second * 1);
        const confirmation = await confirm('CONFIRM', 'Do you want to change current login duration?');
        if (!confirmation) return;
        try {
            openLoading();
            await updateLoginDeviceLifetime(seconds);
            alert('SUCCESS', 'Login duration updated successfully');
            getTime();
            navigate('../');
        } catch (error) {
            alert('ERROR', error);
        } finally {
            closeLoading();
        };
    };
    return (
        <div className={`box-container-opaque`}>
            <div className={`box-container entryPage box-entry ${styles.container}`}>
                <div>
                    <img src={closeIcon} alt="close" className={styles.close} onClick={() => navigate('../')}/>
                    <div>EDIT LOGIN LIFETIME FOR DEVICES</div>
                </div>
                <div className={styles.content}>
                    <div className="input-container">
                        <label className={styles.label}>Hours</label>
                        <input type="number" min="0" className={styles.inputcontainer} placeholder={state.hour} name="hour" onChange={handleChange}/>
                    </div>
                    <div className="input-container">
                        <label className={styles.label}>Minutes</label>
                        <input type="number" min="0" className={styles.inputcontainer} placeholder={state.minute} name="minute" onChange={handleChange}/>
                    </div>
                </div>
                <div className={styles.savecon}>
                    <button className={`bigButton_dark ${styles.save}`} onClick={handleSubmit}>SAVE</button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        time: state.client.deviceLoginDuration
    };
};


export default connect(mapStateToProps, { getLoginDeviceLifetime, updateLoginDeviceLifetime })(EditDuration);