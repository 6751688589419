import ManageCourseTable from "./course_table";
import { connect } from "react-redux";
import { useEffect } from "react";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import iconBtnCourse from "../../assets/icons/AddCourseIconWhite.png";
import iconCourse from "../../assets/box/coursesIcon.png";
import styles from "./index.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const ManageCourses = ({ courses, permissions }) => {
    useEffect(() => {
    }, []);
    const navigate = useNavigate();
    return (
      <div className={`box-container ${styles.container}`}>
        <ArrowGoBack />
        <div className='page-wrapper'>
          <div className={styles.headerContainer}>
              <div className={styles.iconsContainer}>
                  <img src={iconCourse} alt="Course Icon" className="icon_head"/>
                  <div className="total_circle_1">
                      <div className="total_circle_1_number">
                          {courses.length}
                      </div>
                      <span className="total_circle_1_title">TOTAL <br/> COURSES</span>
                  </div>
              </div>
              <div className={styles.actionsContainer}>
                {(permissions.includes(24) || permissions.includes(25)) && (
                  <Button
                      label="CREATE COURSE"
                      size="medium"
                      styleButton="main"
                      onClick={() => navigate("create-course")}
                      image={<img src={iconBtnCourse} alt="iconBtnCourse" className={`icon_btn_1 ${styles.iconButton}`}/>}
                  />
                )}
              </div>
          </div>
          <div className='table-container'>
              <ManageCourseTable />
          </div>
        </div>
        <Outlet />
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    courses: state.manageCourses.coursesList,
    permissions: state.usersReducer.userLogged.permissions,
  };
};

export default connect(mapStateToProps)(ManageCourses);
