import ArrowGoBack from "../../../components/ArrowGoBack/ArrowGoBack";
import styles from "../index.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { updateAvatar } from "../../../actions/usersActions";
import { openLoading, closeLoading } from "../../../components/loadingModal/loading";
import alert from "../../../components/AlertModal/alertModal";
import { AvatarCreator } from "@readyplayerme/react-avatar-creator";


const IframeAvatar = (props) => {
    const { updateAvatar } = props;
    const navigate = useNavigate();
    useEffect(() => {
        window.addEventListener("message", async (event) => {
            if (event.origin !== "https://xennial-zz4vmm.readyplayer.me") return;
            try {
                openLoading();
                await updateAvatar(event.data);
                alert("SUCCESSFULLY", "Avatar updated successfully");
            } catch (error) {
                alert("Error", error);
            } finally {
                closeLoading();
                navigate('../Profile');
            };
        });
    }, []);
    const EditorConfig  = {
        // clearCache: true,
        bodyType: 'halfbody',
        // quickStart: 'false',
        language: 'en',
    };
    return (
        <div className="table" id="avatar-creator">
            <div className="box-container">
                <ArrowGoBack/>
                <div className={styles.backAvatar} onClick={() => navigate('../Profile')}></div>
                <AvatarCreator
                    subdomain="xennial-zz4vmm.readyplayer.me/avatar?frameApi"
                    config={EditorConfig}
                    onAvatarExported={(data) => {console.log(data)}}
                    style={{width: '100%', height: '100%'}}
                />
            </div>
        </div>
    );
};

export default connect(
    (state) => ({
        // userData: state.users.userData,
    }),
    {
        updateAvatar,
    }
)(IframeAvatar);