import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import confirm from "../../../../../components/ConfirmModal/confirm";
import { deleteRole, getAllRoles } from "../../../../../actions/manageUsersActions";
import alert from '../../../../../components/AlertModal/alertModal';

import "./role_table_card.css";

const RoleTableCard = ({ role, deleteRole, getAllRoles }) => {
    const navigate = useNavigate();
    const handleDelete = async () => {
        const sure = await confirm("CONFIRM", "Are you sure you want to delete this role?");
        if(sure) {
            try {
                await deleteRole(role.idRole)
                await getAllRoles({ column: "name", order: "desc" });
            } catch (error) {
                alert("ERROR", error.message);
            }
        }
    };
    return (
        <div className="row card-row-role">
            <div className="content-box ">{role.name}</div>
            <div className="content-box ">
                <Link className="li-t" to={`../edit-role/${role.idRole}`}>EDIT</Link>
            </div>
            <div className="content-box ultimo">
                <span className="li-t" onClick={handleDelete}>DELETE</span>
            </div>
        </div>
    );
};
export default connect(null, { deleteRole, getAllRoles })(RoleTableCard);