import { connect } from "react-redux";
import ModalPage from "../../../components/modalPage-base/ModalPage";
import { useState } from "react";
import { bulkLoad, getUsersByClient } from "../../../actions/usersActions";
import styles from "./load_file.module.css";
import alert from "../../../components/AlertModal/alertModal";
import { openLoading, closeLoading } from "../../../components/loadingModal/loading";
const LoadFile = (props) => {
    const [file, setFile] = useState();
    const [open, setOpen] = useState(false);
    const [override, setOverride] = useState(false)
    const handleInputChange = (e) => {
        e.preventDefault();
        setFile(e.target.files[0]);
    };
    const handleChangeOverride = () => {
        setOverride(!override);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        openLoading();
        if (!file) return alert("FILE ERROR", "Please select a file", "important") && closeLoading();
        const formData = new FormData();
        formData.append("bulkFile", file);
        formData.append("override", override);
        try {
            const response = await props.bulkLoad(formData);
            console.log(Object.keys(response.notCreated).length)
            let recordsFailedList = "";
            for (const userfailed in response.notCreated) {
                console.log(userfailed)
                recordsFailedList += `<div>${userfailed}: ${response.notCreated[userfailed].message}</div>`;
            };
            alert("SUCCESSFULLY", `
            
                <div>
                    <p>FILE UPLOADED SUCCESFULLY</p>
                    <p>Records added: ${response.usersCreated.length}</p>
                    <p>Records updated: ${response.updatedUsers.length}</p>
                    <p>Records failed: ${Object.keys(response.notCreated).length}</p>
                    <div>
                        <p>RECORDS FAILED RESPONSES</p>
                            ${recordsFailedList}
                    </div>
                </div>

            `, "important");
            props.closeModal();
            setOpen(false);
            setFile();
            props.getUsersByClient({ column: "firstname", order: "asc" });
        } catch (error) {
            alert("ERROR", error.message, 'highImportance');
        } finally {
            closeLoading();
        };
    };
    const intro = () => {
        return (
            <ModalPage isOpen={props.isOpen}>
                <div className={styles.modalbulk}>
                    <div className={styles.header}>
                        <span className={styles.title}>TEMPLATE EXCEL</span>
                        <p className={styles.txt}>Use this template excel to import users</p>
                    </div>
                    <div>
                        <a className="li-t" href="https://xdcustomerportalprod.blob.core.windows.net/profilespictures/templateUsers_aprox.xlsx" download>Download</a>
                    </div>
                    <div className={styles.buttonsCon}>
                        <button className="btn-modalPages rigth" onClick={(e) => { e.preventDefault(); setOpen(true) }}>NEXT</button>
                        <button className="btn-modalPages left" onClick={() => { props.closeModal(); setOpen(false); setFile() }}>CANCEL</button>
                    </div>
                </div>
            </ModalPage>
        );
    };
    const uploadFile = () => {
        return (
            <ModalPage isOpen={props.isOpen}>
                <div className={styles.modalbulksel}>
                    <div className={styles.headerSel}>
                        <div className={styles.input_file_container}>
                            <label htmlFor="bulkFile" className={styles.label_file} >SELECT FILE</label>
                            <input type="file" name="bulkFile" id="bulkFile" className={styles.input_file} onChange={handleInputChange} />
                        </div>
                        {
                            file &&
                            <div className={styles.fileCon}>
                                <label htmlFor={styles.bulkFile}>{file.name}</label>
                            </div>
                        }
                    </div>
                    <div className={styles.checkContainer}>
                        <input type="checkbox" className={styles.checkbox}  checked={override} onChange={handleChangeOverride}/>
                        <span className={styles.span} >Override existing records</span>
                    </div>
                    <div className={styles.buttonsCon}>
                        <button className="btn-modalPages rigth" type="submit" value="Upload" onClick={handleSubmit} >UPLOAD</button>
                        <button className="btn-modalPages left" onClick={() => { props.closeModal(); setOpen(false); setFile() }}>CANCEL</button>
                    </div>
                </div>
            </ModalPage >
        );
    };
    if (open) {
        return uploadFile();
    } else {
        return intro();
    }
};

export default connect(null, { bulkLoad, getUsersByClient })(LoadFile);