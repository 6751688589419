import { connect } from "react-redux";
import ModalPage from "../../../components/modalPage-base/ModalPage";
import { getAllRoles } from "../../../actions/manageUsersActions";
import { useEffect } from "react";
import alert from '../../../components/AlertModal/alertModal';
import { assignRole, getUsersByClient } from "../../../actions/usersActions";
import "./assign_role.css"

const AssignRole = (props) => {
    const {
        isOpen, closeModal,
        getAllRoles, roles,
        users, assignRole, getAllUsers,
    } = props;
    useEffect(() => {
        async function getRoles() {
            try {
                getAllRoles({ order: "asc", column: "name" });
            } catch (error) {
                alert("ERROR", error.message);
            }
        }
        getRoles();
    },[])
    const handleChange = async (e) => {
        await assignRole(e.target.value, users) ?
            getAllUsers({ order: "asc", column: "firstname" }) :
            alert('Have ocurrred an error');
        closeModal();
    };
    return (
        <ModalPage isOpen={isOpen}>
            <div className="selRol-con">
                <div className="Sel-role-opC">
                    <select name="roleIdRole" className="select-fpu selRolIn" onChange={handleChange} placeholder="SELECT ROLE" >
                        <option value="" className="desapa">SELECT ROLE</option>
                        {
                            roles.map(role => {
                                return <option key={role.idRole} className="select-box" value={role.idRole}>{role.name}</option>
                            })
                        }
                    </select>
                </div>
                <button className="btn-modalPages" onClick={closeModal}>CANCEL</button>
            </div>
        </ModalPage>
    );
};

const mapStateToProps = state => {
    return {
        roles: state.manageUsers.roles,
        users: state.usersReducer.usersSelected,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAllRoles: (order) => dispatch(getAllRoles(order)),
        assignRole: async (roleIdRole, users) => await dispatch(assignRole(roleIdRole, users)),
        getAllUsers: (order) => dispatch(getUsersByClient(order)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignRole);