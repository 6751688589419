import { connect } from "react-redux";
import PermissionCardTable from "./permission_card_table/permission_card_table";
import {  getAllPermissions, getRolePermissions, createRole, updateRole, clearSelectedPermissions, clearRolePermissions } from "../../../../actions/manageUsersActions";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "./role_form.css";
import EditRoleButton from "./edit_role_button/edit_role_button";
import ArrowGoBack from "../../../../components/ArrowGoBack/ArrowGoBack";
import alert from '../../../../components/AlertModal/alertModal';


const AddEditRole = (props) => {
    const {
        getAllPermissions,
        permissions,
        roles,
        getRolePermissions,
        rolePermissions,
        clearSelectedPermissions,
        clearPermissions,
    } = props;
    const { idRole } = useParams();
    const [order, setOrder] = useState({
        column: "description",
        order: "asc",
        atribute: "permissions"
    });
    useEffect(() => {
        async function getPermissions() {
            try {
                getAllPermissions(order);
                if (!idRole) {
                    clearPermissions();
                    // const array = document.getElementsByClassName('check-per');
                    const select = document.getElementById('select-role');
                    select.value = 'init';
                } else {
                    getRolePermissions(idRole, order);
                }
                clearSelectedPermissions()
                const defaultVal2 = document.getElementById('select-role');
                if(defaultVal2) {
                    defaultVal2.value = idRole;
                };
            } catch (error) {
                alert("ERROR", error.message);
            }
        }
        return getPermissions();
    }, []);
    const handleChange = async (e) => {
        try {
            if (e.target.value === "init") {
                const roles = document.getElementsByClassName("check-per");
                for (let i = 0; i < roles.length; i++) {
                    roles[i].checked = false;
                }
            } else {
                await getRolePermissions(e.target.value, order);
            }
        } catch (error) {
            alert("ERROR", error.message);
        }
    };
    const showPermissionsRows = () => {
        return permissions.map((permission, index) => {
            let coincidence = false;
            const rowCheck = document.getElementsByClassName(`check-per ${permission.idPermission}`)[0];
            if (rowCheck) {
                for(var i = 0; i < rolePermissions.permissions.length; i++) {
                    if(permission.idPermission === rolePermissions.permissions[i].idPermission) {
                        coincidence = true;
                        rowCheck.checked === false && rowCheck.click();
                    }
                };
                !coincidence && rowCheck.checked && rowCheck.click();
            }
            return (
                <PermissionCardTable
                    key={index}
                    permission={permission}
                />
            );
        });
    };
    const allRoles = () => {
        return roles.map((role, index) => {
            return (
                <option className={`op-rol ${role.idRole} opt-S-EU`} key={index} value={role.idRole}>{role.name}</option>
            );
        });
    };
    const handleSort = (column) => {
        if (order.order === "asc") {
            getAllPermissions({ column, order: "desc", atribute: "permissions" });
            setOrder({ column, order: "desc", atribute: "permissions" });
        } else {
            getAllPermissions(idRole, { column, order: "asc", atribute: "permissions" });
            setOrder({ column, order: "asc", atribute: "permissions" });
        }
    };
    return (
        <div className="box-container roleEd-con">
            <ArrowGoBack/>
            <div className="btn-table-per">
                <span className="span-rolename">Role Name</span>
                <select name="role" id="select-role" className="input-cu" selected={idRole} onChange={x => handleChange(x)}>
                    {allRoles()}
                </select>
            </div>
            <div className="table-card roleEd-TC">
                <div className="table-header header-per">
                    <div></div>
                    <div className="header-box" onClick={() => handleSort('description')}>Permission</div>
                </div>
                <div className="table-rows roleEd-rowC">
                    {
                        rolePermissions.permissions && showPermissionsRows()
                    }
                </div>
            </div>
            <div className="IBcc">
                <EditRoleButton/>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        permissions: state.manageUsers.permissions,
        roles: state.manageUsers.roles,
        rolePermissions: state.manageUsers.rolePermissions,
    };
};

export default connect(mapStateToProps, {
    getAllPermissions,
    getRolePermissions,
    createRole,
    updateRole,
    clearSelectedPermissions,
    clearPermissions: clearRolePermissions
})(AddEditRole);