import TableCard from "./table_card/table_card";
import styles from "../devices_details.module.css";

const DetailsTable = (props) => {
    const {
        data,
        getDevice
    } = props;
    const licenses = data.licenses;
    return (
        <div className={styles.tableContainer}>
            <div className={`table-header table-header-clear ${styles.tableHeader}`}>
                <div className="header-box">PRODUCT</div>
                <div className="header-box">DETAIL</div>
                <div className="header-box ultimo">ACTIVATE / DESACTIVATE</div>
            </div>
            <div className={`table-rows ${styles.rows}`}>
                {
                    licenses.map((license, i) => {
                        return (
                            <TableCard key={i} data={license} color={i % 2 === 0 && 'dark'}  getDevice={getDevice}/>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DetailsTable;