import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllGroups, deleteGroup } from "../../actions/manageGroupsActions";
import { closeLoading, openLoading } from "../../components/loadingModal/loading";
import confirm from "../../components/ConfirmModal/confirm";
import alert from "../../components/AlertModal/alertModal";
import trashIcon from "../../assets/icons/Trash_Icon.png";
import Table from '../../components/Table';

const ManageGroupTable = ({ getAllGroups, deleteGroup, groups, permissions }) => {
    useEffect(() => {
        async function getGroups() {
            try {
                openLoading();
                await getAllGroups('asc');
                closeLoading();
            } catch (error) {
                alert("ERROR", error.message);
            }
        }
        getGroups();
    }, []);

    const handleDelete = async (idGroup) => {
        try {
            const confirmFun = await confirm('Confirm', 'Are you sure you want to delete this group?');
            if (!confirmFun) return;
            await deleteGroup(idGroup) 
            alert('Successfully', 'Group has been deleted');
            await getAllGroups('asc');
        } catch (error) {
            alert('ERROR', error.message);
        }
    };

    const columns = useMemo(
        () => [
          {
            id: 'name',
            Header: () => <div className="table-title">GROUP</div>, 
            header: 'Group',
            grow: true,
            size: 450,
            accessorFn: (row) => <Link className="li-t" to={`edit-group/${row.idGroup}`}>{row.name}</Link>,
            filterFn: (row, id, filterValue) => {
                return row.original.name.toLowerCase().includes(filterValue.toLowerCase())
            }
          },
          {
            id: 'users',
            header: 'Users',
            Header: () => <div className="table-title">USERS</div>, 
            accessorKey: 'countUsers',
            filterVariant: 'text',
            grow: false,
            size: 100,
          },
          {
            header: 'actions',
            Header: () => (
                <div className="actions-container">
                    <div className="table-title">ACTIONS</div>
                </div>
            ), 
            accessorFn: (row) => (
                <div className="actions-container">
                    { (permissions.includes(9) || permissions.includes(10)) && <img src={trashIcon} alt="Delete"  className="trash_icon" onClick={() => handleDelete(row.idGroup)}/> }
                </div>
            ),
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 50,
          },
        ],
        [],
    );
  
    return <Table data={groups} height="61vh" columns={columns}/>;
};

const mapStateToProps = state => {
    return {
        groups: state.manageGroups.allGroups,
        permissions: state.usersReducer.userLogged?.permissions
    };
};

export default connect(mapStateToProps, { getAllGroups, deleteGroup })(ManageGroupTable);