import { useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { removeUserFromGroup } from "../../../actions/manageGroupsActions";
import removeIcon from "../../../assets/box/close_dark.png";
import alert from "../../../components/AlertModal/alertModal";
import Table from "../../../components/Table";

const roleList = [
    'Admin',
    'Faculty',
    'Learner',
];

const UsersByGroupTable = ({ users, removeUserFromGroup }) => {
    const { groupId } = useParams();

    const handleRemoveUser = async (user) => {
        try {
            if (groupId) {
                await removeUserFromGroup({idGroup: groupId, user})
            } else {
                await removeUserFromGroup({user});
            }
        } catch (error) {
            alert("Error", error.message);
        };
    };

    const columns = useMemo(
        () => [
          {
            accessorKey: 'username',
            header: 'username',
            Header: () => <div className="table-title">USERNAME</div>, 
            filterVariant: 'text',
            size: 200,
          },
          {
            accessorKey: 'firstname',
            header: 'firstname',
            Header: () => <div className="table-title">FIRSTNAME</div>, 
            filterVariant: 'text',
            size: 150,
          },
          {
            accessorKey: 'lastname',
            header: 'lastname',
            Header: () => <div className="table-title">LASTNAME</div>, 
            filterVariant: 'text',
            size: 150,
          },
          {
            id: 'role',
            header: 'role',
            Header: () => <div className="table-title">ROLE</div>, 
            accessorFn: (originalRow) => (originalRow.role.name),
            filterVariant: 'select',
            filterSelectOptions: roleList, //custom options list 
            grow: false,
            size: 50,
          },
          {
            header: 'actions',
            Header: () => <div className="table-title">ACTIONS</div>, 
            accessorFn: (row) => <img src={removeIcon} alt="Delete"  className="remove_icon" onClick={() => handleRemoveUser(row)}/>,
            enableColumnFilter: false,
            enableSorting: false,
            grow: false,
            size: 50,
          },
        ],
        [],
    );
  
    return <Table data={users} height="53vh" columns={columns}/>;
};

const mapDispatchToProps = (dispatch) => {
  return {
      removeUserFromGroup: (params) => {
          dispatch(removeUserFromGroup(params));
      }
  };
};
export default connect(null, mapDispatchToProps)(UsersByGroupTable);