import moment from "moment";
import styles from "../device_details_table.module.css";

const TableCard = (props) => {
    const { data, color } = props;
    const endDate = moment(data.dateEnd).format('MMM DD, YYYY');
    const name = data.product.name
    return(
        <div className={`row ${styles.row} ${color && styles.dark}`}>
            <div className="content-box gray">{name}</div>
            <div className="content-box gray ultimo">{endDate}</div>
        </div>
    );
};

export default TableCard;