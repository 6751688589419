import "./index.css";
const downloadDistool = () => {
    return (
        <div className="CC-t">
            <div className="table center-button ">
                <a className="bas-button buttonDownload" href="https://xdcustomerportalprod.blob.core.windows.net/apps/DistributionTools.zip">Download Distribution Tool</a>
            </div>
        </div>
    );
};

export default downloadDistool;