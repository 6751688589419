import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = ({classN}) => {
    let currentYear = new Date().getFullYear();

    return (
        <footer className={`footer-c ${classN}`}>
            <div className='cpr-fot'>
                {`Copyright © ${currentYear} Xennial Digital`}
            </div>
            {/* <div className='terCon-foot'>
                <a href="#" className='link-te-foo' >{'Terms & conditions | Privacy Policy'}</a>
            </div> */}
        </footer>
    );
};

export default Footer;