import { Route, Routes } from 'react-router-dom';

import IsAuth from './components/Middlewares/IsAuth';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';

// Licenses
import Licenses from "./pages/Licenses";
import LicenseDetailsTable from "./pages/Licenses/license_details_table/table";

// Manage Sessions
import Sessions from "./pages/ManageSessions";
import PastSessionPage from './pages/ManageSessions/past_session_page';
import SessionForm from "./pages/ManageSessions/session_form/session_form";

// Manage Groups 
import Groups from "./pages/ManageGroups";
import GroupForm from "./pages/ManageGroups/group_form/group_form";

// Manage Courses 
import Courses from "./pages/ManageCourses";
import CourseForm from "./pages/ManageCourses/course_form/course_form";

// Manage Users
import Users from "./pages/ManageUsers";
import RolesAndPermissions from "./pages/ManageUsers/roles_permissions/roles_permissions";
import RoleForm from "./pages/ManageUsers/roles_permissions/role_form/role_form";
import UserForm from "./pages/ManageUsers/user_form/user_form";

//Reports
import Reports from "./pages/Reports";
import Details from "./pages/Reports/details/details";
import SessionDetails from './pages/Reports/session_details/SessionDetails';

// Devices
import DevicesDetails from "./pages/Devices/devices_details/devices_details";
import Devices from "./pages/Devices";

// Settings
import Settings from "./pages/Settings"
import LoginLifetime from "./pages/Settings/login_lifetime/login_lifetime_edit";

// Profile
import Profile from './pages/Profile';
import IframeAvatar from './pages/Profile/iframe_avatar/iframe_avatar';

import DownloadDistool from './pages/DownloadDisTool';
import Assets from './pages/Assets';
import './index.css';
import MaintenanceView from './pages/MaintenanceView';

function App() {
  return (
    <Routes>
      <Route path="login/*" element={<Login />} />
      <Route path="/*" element={<IsAuth Component={Dashboard} />} >
        <Route path='home' element={<Home />} />
        <Route path="licenses/*" element={<Licenses />}>
          <Route path=":licenseId" element={<LicenseDetailsTable />}/>
        </Route>
        <Route path="manage-sessions/*" element={<Sessions />}>
          <Route path="previous/:idSession" element={<PastSessionPage />} />
          <Route path="create-session" element={<SessionForm />}/>
          <Route path="edit-session/:multiplayerId" element={<SessionForm />}/>
        </Route>
        <Route path="manage-groups/*" element={<Groups />} >
          <Route path="create-group" element={<GroupForm />} />
          <Route path="edit-group/:groupId" element={<GroupForm />} />
        </Route>
        <Route path="manage-courses/*" element={<Courses />} >
          <Route path="create-course" element={<CourseForm />} />
          <Route path="edit-course/:courseId" element={<CourseForm />} />
        </Route>
        <Route path="manage-users/*" element={<Users />} >
          <Route path="roles-permissions" element={<RolesAndPermissions />} />
          <Route path="create-role" element={<RoleForm />} />
          <Route path="edit-role/:roleId" element={<RoleForm />} />
          <Route path="create-user" element={<UserForm />} />
          <Route path="edit-user/:userId" element={<UserForm />} />
        </Route>
        <Route path="reports/*" element={<Reports />} >
          <Route path="details/:username/:idProduct" element={<Details />} >
            <Route path=":idSession" element={<SessionDetails />} />
          </Route>
        </Route>
        <Route path='settings/*' element={<Settings />} >
          <Route path="login-lifetime" element={<LoginLifetime />} />
        </Route>
        <Route path="devices/*" element={<Devices />} >
          <Route path="details/:devicesId" element={<DevicesDetails />} />
        </Route>
        <Route path="profile/*" element={<Profile />} />
        <Route path="customize-avatar/*" element={<IframeAvatar />} />

        <Route path="download-distool/*" element={<DownloadDistool />} />
        <Route path="assets/*" element={<Assets />} />
      </Route>
      <Route path='/404' element={<MaintenanceView />} />
    </Routes>
  );
};

export default App;
