import { connect } from "react-redux";
import { getProductLicenses } from "../../actions/productsActions";
import { useEffect, useState } from "react";
import ArrowGoBack from "../../components/ArrowGoBack/ArrowGoBack";
import licensesImg from "../../assets/icons/licensesIcon.png";
import totalImg from "../../assets/icons/nextExpirationIcon.png";
import styles from "./index.module.css"
import { Outlet } from "react-router-dom";
import Table from "./license_table/license_table";
import TableMobile from "./license_table_mobile/license_table_mobile";

const Template = (props) => {
    const {
        products, 
        getProductLicenses,
        nextExpirations
    } = props;
    const mediaQuery = window.matchMedia('(max-width: 427px)');
    const [order, setOrder] = useState({
        order: 'asc',
        column: 'name'
    });
    const remainingDays = (date) => {
        const today = new Date();
        const expDate = new Date(date);
        const diff = expDate.getTime() - today.getTime();
        const days = Math.ceil(diff / (1000 * 3600 * 24));
        return days;
    };
    useEffect(() => {
        getProductLicenses(order);
    }, []);
    const handleSort = (e) => {
        if (order.order === 'asc') {
            getProductLicenses({ order: 'desc', column: 'name' });
            setOrder({ order: 'desc', column: 'name' });
        } else {
            getProductLicenses({ order: 'asc', column: 'name' });
            setOrder({ order: 'asc', column: 'name' });
        }
    };
    return (
        <div className="box-container">
            <ArrowGoBack/>
            <div className={`page-wrapper ${styles.container}`}>
                <div className={styles.infoContainer}>
                    <img src={licensesImg} alt="Licenses logo" className={styles.logoLicenses}/>
                    <div className={styles.nextExpCon}>
                        <img src={totalImg} alt="Next expiration logo" className={styles.nextExpLogo}/>
                        <div className={styles.nextExpText}>
                            <span className={styles.nextExpTitle}>UPCOMING EXPIRATION</span>
                            <div className={styles.nextExpGroup}>
                                {
                                    nextExpirations[0] && <>
                                    <div className={styles.nextExpSub}>{nextExpirations[0].product.name}:</div>
                                    <div className={styles.nextExpDate}>{remainingDays(nextExpirations[0].dateEnd)} Days</div></>
                                }
                            </div>
                            <div className={styles.nextExpGroup}>
                                {
                                    nextExpirations[1] && <>
                                    <div className={styles.nextExpSub}>{nextExpirations[1].product.name}:</div>
                                    <div className={styles.nextExpDate}>{remainingDays(nextExpirations[1].dateEnd)} Days</div></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.ContTablePL}>
                    {
                        mediaQuery.matches &&
                        <div className={styles.searchCon}>
                            <input type="text" placeholder="Search" className={`in-FLD ${styles.search}`}/>
                        </div>
                    }
                    {
                        mediaQuery.matches ? <TableMobile/> : 
                        <div className='table-container'>
                            <Table/>
                        </div>
                    }
                </div>
            </div>
            <Outlet/>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        products: state.productsReducer.productsLicenses,
        nextExpirations: state.productsReducer.nextExpirations
    }
};

export default connect(mapStateToProps, { getProductLicenses })(Template);