import styles from './SelectTwo.module.css';
import { useEffect, useRef, useState } from 'react';
import arrowIcon from '../../assets/icons/arrow-white.png';


const SelectTwo = (props) => {
    const {
        title,
        icon,
        options,
        onChange,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({
        name: '',
        value: ''
    });
    const selectRef = useRef(null);
    const handleClickedOutside = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
        };
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickedOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickedOutside);
        };
    } , []);
    const handleOpen = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const handleChange = (e) => {
        setSelected({name: e.name, value: e.value});
        onChange && onChange({name: e.name, value: e.value});
        setIsOpen(false);
    };
    return (
        <div className={styles.container} ref={selectRef}>
            <div className={styles.selectBox}>
                <div className={styles.box} onClick={handleOpen}>
                    <div className={styles.title}>
                        <img src={icon} alt="upload icon" className={styles.icon}/>
                        <span>{title}</span>
                    </div>
                    <div className={styles.arrowContainer}>
                        <img src={arrowIcon} alt="show uptions" className={`${styles.arrow} ${isOpen && styles.active}`}/>
                    </div>
                </div>
                <div className={`${styles.boxDown} ${isOpen && styles.active}`}>
                    {
                        options && options.map((op, i) => {
                            return (
                                <div key={i} className={styles.option} onClick={() => handleChange(op)}>
                                    <p>{op.name}</p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default SelectTwo;