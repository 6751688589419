import switchOn from "../../../../../assets/box/Switch@2x-8.png";
import switchOof from "../../../../../assets/box/Switch2@2x-8.png";
import Axios from "../../../../../config/Axios";
import styles from "../../devices_details.module.css";

const { REACT_APP_API_URL } = process.env;

const TableCard = (props) => {
    const {
        color,
        data,
        getDevice
    } = props;
    const executables = data.product.executables;
    const activeProduct = async (e) => {
        if (data.licenses_has_device.active) {
            const response = await Axios.put(`${REACT_APP_API_URL}/licenses/activeLicenseDevice`, {
                active: false,
                idLicense: data.licenses_has_device.licenseIdLicense,
                idDevice: data.licenses_has_device.deviceIdDevice
            });
            await getDevice();
        } else {
            const response = await Axios.put(`${REACT_APP_API_URL}/licenses/activeLicenseDevice`, {
                active: true,
                idLicense: data.licenses_has_device.licenseIdLicense,
                idDevice: data.licenses_has_device.deviceIdDevice
            });
            await getDevice();
        };
    };
    return (
        <div className={`row ${styles.row} ${color}`}>
            <div className="content-box clear">{data.product.name}</div>
            <div className="content-box clear">
                {
                    executables.map(e => {
                        return (
                            <div className={styles.executable}>{e.bundleName}</div>
                        )
                    })
                }    
            </div>
            <div className={styles.switchCon}>
                <img src={data.licenses_has_device.active ? switchOn : switchOof} alt="active" className={styles.switch} onClick={activeProduct}/>
            </div>
        </div>
    );
};

export default TableCard;