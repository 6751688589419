import { useState } from "react";
import styles from "../upcoming_table_mobile.module.css";
import arrow from "../../../../../assets/icons/downArrow.png";
import infoIcon from "../../../../../assets/icons/info-icon.png";
import editIcon from "../../../../../assets/icons/lapiz_edit.png";
import deleteIcon from "../../../../../assets/icons/Trash_Icon_clear.png";
import moment from "moment";
import AttendeesTableModal from "../../../session_tables/attendees_table_modal/attendees_table_modal";
import { useNavigate } from "react-router-dom";
import confirm from "../../../../../components/ConfirmModal/confirm";
import alert from "../../../../../components/AlertModal/alertModal";

const TableCardMobile = (props) => {
    const {
        role,
        color,
        deleteSessions,
        getSessions,
        content
    } = props;
    const {
        dateSession,
        product,
        title,
        users,
        sessionCode,
        idMultiplayer,
    } = content;
    const [state, setState] = useState({
        open: false,
        openAttendees: false
    });
    const navigate = useNavigate();
    const handleOpenAttendees = (e) => {
        e.stopPropagation();
        document.getElementById('btn_previousSessions').style.zIndex = "0";
        if (document.getElementById(`iconOpenSS${sessionCode}`).classList.contains('iconOpenSS')) return handleCloseAttendees();
        let opens = document.getElementsByClassName('iconOpenSS');
        for (let i = 0; i < opens.length; i++) {
            opens[i].click();
        };
        document.getElementById(`iconOpenSS${sessionCode}`).className += " iconOpenSS";
        state.openAttendees ? setState({ ...state, openAttendees: false }) : setState({ ...state, openAttendees: true });
    };
    const handleCloseAttendees = (e) => {
        document.getElementById('btn_previousSessions').style.zIndex = "5";
        setState({ ...state, openAttendees: false });
        document.getElementById(`iconOpenSS${sessionCode}`).classList.remove('iconOpenSS');
    };
    const handleOpenData = (e) => {
        setState({ ...state, open: !state.open, openAttendees: false });
    };
    const handleDelete = async () => {
        const response = await confirm("Confirm", "Are you sure you want to delete this session?")
        if (!response) return
        try {
            await deleteSessions(idMultiplayer)
            alert("Successfully", "Session has been deleted")
            return getSessions();
        } catch (error) {
            alert("ERROR", "Session could not be deleted");
        };
    };
    const date = moment(dateSession);
    return (
        <div className={`${styles.box} ${styles[color]}`} >
            <div className={styles.header} onClick={handleOpenData}>
                <div className={styles.date}>{date.format("MMM YYYY")}</div>
                <img src={arrow} alt="show" className={`${styles.arrow} ${state.open && styles.open}`}/>
            </div>
            <div className={styles.row} onClick={handleOpenData}>
                <div className={styles.chartContainer} >
                    <div className={styles.chartDate}>
                        <div className={styles.day}>{date.format('ddd').toUpperCase()}</div>
                        <div className={styles.dayNumber}>{date.format('DD')}</div>
                    </div>
                </div>
                <div className={styles.chartInfo}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.title2}>{product.name}</div>
                    <div className={styles.date2}>{date.format('hh:mm A')}</div>
                </div>
            </div>
            <div className={`${styles.boxHidden} ${state.open && styles.open}`}>
                <div className={styles.groupText}>
                    <div className={styles.key}>Title:</div>
                    <div className={styles.value}>{title}</div>
                </div>
                <div className={styles.groupText}>
                    <div className={styles.key}>Room Number:</div>
                    <div className={styles.value}>{sessionCode}</div>
                </div>
                <div className={styles.optionsContainer}>
                    <div className={styles.groupText}>
                        <div className={styles.key}>Attendees:</div>
                        <div className={styles.value}>{users.length}</div>
                        <div className={styles.infoCon}>
                            <img src={infoIcon} alt="all atendees" id={`iconOpenSS${sessionCode}`} className={styles.infoIcon} onClick={handleOpenAttendees}/>
                            <AttendeesTableModal users={users} isOpen={state.openAttendees} close={handleCloseAttendees}/>
                        </div>
                    </div>
                    { role !== 'Learner' &&
                        <div className={styles.options}>
                            <img src={editIcon} alt="edit session" className={styles.infoIcon} onClick={() => navigate(`./edit-session/${idMultiplayer}`)}/>
                            <img src={deleteIcon} alt="delete session" className={styles.infoIcon} onClick={handleDelete}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default TableCardMobile;
