import {
    GET_LOGIN_DEVICE_LIFETIME,
    GET_LOGO
} from "../actions/clientAction";

var initialState = {
    logo: '',
    deviceLoginDuration: { time: 0, updateAt: null },
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GET_LOGO):
            return state = {
                ...state,
                logo: action.payload
            };
        case (GET_LOGIN_DEVICE_LIFETIME):
            return state = {
                ...state,
                deviceLoginDuration: action.payload
            };
        default:
            return state;
    }
};

export default clientReducer;