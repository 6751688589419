import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import "./index.css";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";

const MaterialSelect = (props) => {
  const {
    formField,
    value,
    label,
    onChange,
    options, // [{ id, label }]
    multiple,
    errors,
    disabled, // Añade la prop disabled
  } = props;

  const hasErrors = errors && errors.length > 0;

  return (
    <FormControl sx={{ width: "100%" }} error={hasErrors} disabled={disabled}>
      <InputLabel
        id="select-label"
        sx={{
          transform: Array.isArray(value)
            ? value.length > 0
              ? "translate(14px, -9px) scale(0.75)"
              : "translate(14px, 8px) scale(1)"
            : value
            ? "translate(14px, -9px) scale(0.75)"
            : "translate(14px, 8px) scale(1)",
          color: Array.isArray(value)
            ? value.length > 0
              ? "#3e9ae5" // Color when there are selected values
              : "#a2c4e0" // Color when no values are selected (empty)
            : value // If not an array (single select), check for the value
            ? "#3e9ae5"
            : "#a2c4e0",
          "&.Mui-focused": {
            color: "#3e9ae5", // Focused color
            transform: "translate(14px, -9px) scale(0.75)",
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        name={formField}
        value={value || ""}
        multiple={multiple}
        autoWidth
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        disabled={disabled} // Aplica el atributo disabled aquí
        sx={{
          height: "40px",
          ".MuiOutlinedInput-input": {
            padding: "8px 14px",
          },
          "& .MuiSelect-select": {
            color: "#3e9ae5",
            fontFamily: "ProximaNova-semibold",
            fontSize: "1.8vh",
          },
          "& .MuiSelect-icon": {
            color: "#3e9ae5",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3e9ae5",
            borderWidth: "0.25vh",
            borderRadius: "0.6vh",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3e9ae5",
            borderWidth: "0.25vh",
            borderRadius: "0.6vh",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3e9ae5",
            borderWidth: "0.25vh",
            borderRadius: "0.6vh",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              color: "#3e9ae5",
              fontSize: "2vh",
              fontFamily: "ProximaNova-semibold",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {hasErrors && (
        <FormHelperText>
          {errors.map((option) => {
            return <div className="error">{option.error}</div>;
          })}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MaterialSelect;
