import {
    ADD_PRODUCT,
    LIST_PRODUCTS,
    GET_PRODUCT_LICENSES,
    GET_USER_PRODUCTS,
    GET_PRODUCTS_BY_USER
} from "../actions/productsActions";


var initialState = {
    allProducts: [],
    newProduct: {},
    userProducts: [],
    productsLicenses: [],
    nextExpirations: [],
};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case (ADD_PRODUCT):
            return state = {
                ...state,
                newProduct: action.payload
            };
        case (GET_USER_PRODUCTS):
            return state = {
                ...state,
                userProducts: action.payload
            };
        case (GET_PRODUCTS_BY_USER):
            return state = {
                ...state,
                productsIds: action.payload
            };
        case (GET_PRODUCT_LICENSES):
            const expirations = action.payload.sort((a, b) => {
                if (new Date(a.dateEnd) > new Date(b.dateEnd)) {
                    return 1;
                } else if (new Date(a.dateEnd) < new Date(b.dateEnd)) {
                    return -1;
                } else {
                    return new Date(a.dateEnd) - new Date(b.dateEnd);
                }
            });
            const expirationsFiltered = expirations.filter(license => {
                return new Date(license.dateEnd) > new Date();
            });
            let num = 0;
            for (let i = 0; i < expirationsFiltered; i++) {
               expirationsFiltered[i] && num++;
            };
            return state = {
                ...state,
                ProductsAvailables: num,
                productsLicenses: action.payload,
                nextExpirations: [expirationsFiltered[0], expirationsFiltered[1]]
            };
        case (LIST_PRODUCTS):
            return state = {
                ...state,
                allProducts: action.payload
            };
        default: return state;
    }
};

export default productsReducer;